import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import { Tabs, TabsList, TabsTrigger } from "../../../components/ui/tabs";
import { useAuth } from "../../../containers/hooks";
import { ORGANIZATION_TABS } from "../../organization/organization-routes";

export default function OrganizationTabs() {
  const { search, pathname } = useLocation();
  const { parsedToken } = useAuth();

  if (!parsedToken?.isOrganizationMember) {
    return null;
  }

  return (
    <Tabs className="rounded-lg bg-white">
      <TabsList
        aria-label="Manage your account"
        className="text-bg-background justify-center"
      >
        {ORGANIZATION_TABS.map((tab) => {
          const isSameTab = tab.path === pathname;
          const newUrl = isSameTab ? `${tab.path}${search}` : tab.path;
          return (
            <TabsTrigger
              asChild
              key={tab.id}
              value={tab.path.split("/").pop() ?? ""}
            >
              <Link to={newUrl}>{tab.link}</Link>
            </TabsTrigger>
          );
        })}
      </TabsList>
    </Tabs>
  );
}
