import { zodResolver } from "@hookform/resolvers/zod";
import type { ColumnDef, Row } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { LucideArrowDownCircle } from "lucide-react";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

import { SelectColumn } from "../../../../../components/logic/select-column";
import { TagsDisplay } from "../../../../../components/logic/tags";
import { Badge } from "../../../../../components/ui/badge";
import { Button } from "../../../../../components/ui/button";
import { DataTable } from "../../../../../components/ui/data-table/data-table";
import { DataTableColumnHeader } from "../../../../../components/ui/data-table/data-table-column-header";
import { arrIncludesSomeWithEmptyFn } from "../../../../../components/ui/data-table/filters";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import { Form } from "../../../../../components/ui/form";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import EllipsedText from "../../../../../components/ui/tooltipText";
import { useToast } from "../../../../../components/ui/use-toast";
import { ConstructType } from "../../../../../config/enums";
import { trpc, type AppRouter } from "../../../../../config/trpc";
import { CONSTRUCT_TYPE_TO_LABEL } from "../../construct/components/constants";
import useGetAssay from "../useGetAssay";

type ConstructTable = inferProcedureOutput<AppRouter["construct"]["list"]>[0];

const SelectionComponent = ({ row }: { row: Row<ConstructTable> }) => (
  <Badge aria-label={`selection-${row?.original?.name}`} variant={"secondary"}>
    {row?.original?.name}
  </Badge>
);

const SelectionColumn = SelectColumn(SelectionComponent);

const columns: ColumnDef<ConstructTable>[] = [
  SelectionColumn,
  {
    accessorKey: "name",
    cell: ({ row }) => (
      <EllipsedText maxWidth="200px">{row.original.name}</EllipsedText>
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "name",
  },
  {
    accessorKey: "constructType",
    cell: (info) => CONSTRUCT_TYPE_TO_LABEL[info.getValue() as ConstructType],
    filterFn: arrIncludesSomeWithEmptyFn,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Type",
      uniqueFilter: true,
    },
  },
  {
    accessorFn: (row) => row.tags,
    cell: ({ row }) => <TagsDisplay tags={row.original.tags} />,
    filterFn: "arrIncludesSome",
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "tags",
    meta: {
      title: "Tags",
    },
  },
  {
    accessorKey: "details",
    cell: ({ row: { original } }) => {
      if (original.constructType === ConstructType.OligoSet) {
        return (
          <div>
            <div className="flex flex-row space-x-1">
              <p>Oligos:</p>
              <p>{original.oligoCount}</p>
            </div>
            <div className="flex flex-row space-x-1">
              <p>Size range:</p>
              <p>{`${original.minSize}-${original.maxSize}`}</p>
            </div>
          </div>
        );
      }
      return null;
    },
    header: "Details",
    id: "details",
  },
];

export const AddToAssayTable = ({
  onSelectionChange,
}: {
  onSelectionChange: (p: { id: string }[]) => void;
}) => {
  const { data, isPending } = trpc.construct.list.useQuery({
    withArchived: false,
  });

  const constructs = data ?? [];

  return (
    <DataTable
      columns={columns}
      data={constructs ?? []}
      loading={isPending}
      onRowClick={(row) => row.getToggleSelectedHandler()({})}
      onSelectionChange={(selected) => {
        const selectedIds = selected.map((selected) => ({
          id: constructs[parseInt(selected.id)].id,
        }));
        onSelectionChange(selectedIds);
      }}
      tableContainerClassName="max-h-80 overflow-scroll"
    />
  );
};

const addConstructsToAssayForm = z.object({
  constructs: z.array(
    z.object({
      id: z.string(),
    }),
  ),
});

type AddConstructsToTeam = z.infer<typeof addConstructsToAssayForm>;

export default function AssayLoadConstruct({ assayId }: { assayId: string }) {
  const [open, setOpen] = useState(false);
  const { toast } = useToast();
  const { data: assay } = useGetAssay();
  const form = useForm<AddConstructsToTeam>({
    defaultValues: {
      constructs: [],
    },
    resolver: zodResolver(addConstructsToAssayForm),
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: "constructs",
  });

  const { mutate: addToAssay } = trpc.assay.addConstructs.useMutation({
    onError(err) {
      toast({
        description: err.message,
        title: "Error",
        variant: "destructive",
      });
      setOpen(false);
    },
    onSuccess() {
      setOpen(false);
    },
  });

  function onSubmit(values: AddConstructsToTeam) {
    addToAssay({
      assayId,
      constructIds: values.constructs.map((x) => x.id),
    });
  }

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            aria-label="Load construct from library"
            className="h-11 w-11 p-0"
            disabled={assay?.archived}
            variant={"outline"}
          >
            <DialogTrigger asChild>
              <span className="flex h-full w-full items-center justify-center">
                <LucideArrowDownCircle />
              </span>
            </DialogTrigger>
          </Button>
        </TooltipTrigger>
        <TooltipContent>Load construct from library</TooltipContent>
      </Tooltip>
      <DialogContent className="min-w-[700px]">
        <DialogHeader className="space-y-4">
          <DialogTitle>Load construct from library</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-2" onSubmit={form.handleSubmit(onSubmit)}>
            <AddToAssayTable
              onSelectionChange={(constructs) => {
                form.setValue("constructs", constructs);
              }}
            />
            <div className="flex justify-end">
              <Button disabled={fields.length === 0} type="submit">
                Submit
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
