import type { inferProcedureInput } from "@trpc/server";
import { useNavigate } from "react-router-dom";

import { useToast } from "../../../../../components/ui/use-toast";
import { ConstructType } from "../../../../../config/enums";
import { trpc, type AppRouter } from "../../../../../config/trpc";
import { OrganizationRoutes } from "../../../organization-routes";
import type { EditConstructForm } from "../edit-construct";

type CreateConstructInput = inferProcedureInput<
  AppRouter["construct"]["create"]
>;

export const useCreateConstruct = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { mutate: createConstruct } = trpc.construct.create.useMutation({
    onSuccess(constructId, input) {
      toast({
        description: "Your construct has been created",
        title: `${input.oligos ? "Oligo set" : "Gene"} created`,
      });
      const routeToNavigateTo = input.assayId
        ? OrganizationRoutes.ASSAY.replace(":assayId", input.assayId ?? "")
        : OrganizationRoutes.CONSTRUCT.replace(":constructId", constructId);
      navigate(routeToNavigateTo);
    },
  });

  return (construct: EditConstructForm) => {
    if (!construct.name || !construct.details) {
      toast({
        description:
          "You need to provide a name and details for your construct",
        title: "Error saving oligo set",
        variant: "default",
      });
    }

    const { name, description, tags, assayId, details } = construct;

    const input: CreateConstructInput = {
      description: description,
      name: name,
      ...(details.type === ConstructType.OligoSet
        ? {
            oligos: details.oligos.map((o) => ({
              id: o.id,
              name: o.name,
              placement: o.wellHint,
              sequence: o.sequence,
            })),
          }
        : {}),
      ...(details.type === ConstructType.Gene
        ? {
            gene: {
              sequence: details.sequence,
              vector: details.vector
                ? {
                    insertPosition: details.vector?.insertPosition,
                    sequence: details.vector?.sequence,
                  }
                : undefined,
            },
          }
        : {}),
      assayId,
      tags: tags,
    };
    createConstruct(input);
  };
};
