import { graphql } from "../__generated__/gql";

export const BASE_INSTRUMENT_FIELDS = graphql(/* GraphQL */ `
  fragment BaseInstrumentFields on Instrument {
    id
    name
    serialNumber
    canBeTriggeredExternally
    createdAt
    createdBy {
      name
    }
    softwareVersion
    hardwareVersion
    status
    consumables {
      bottleGroups {
        remainingVolume
        capacity
        group
      }
      kitId
      remainingCycles
    }
    canEdit
  }

  fragment InstrumentStatusFields on InstrumentStatusDetails {
    chiller {
      tray
      sippers
      door
      temperature
    }
    waste {
      bottle {
        level
        needsToBeEmptied
        underPressure
        connected
      }
      vacuumPressure
    }
    door
    enclosure {
      temperature
    }
    stations {
      name
      temperature
    }
  }

  fragment BaseHamiltonInstrumentFields on HamiltonInstrument {
    id
    name
    ipAddress
    usbPort
    createdAt
    canEdit
  }
`);

export const GET_INSTRUMENTS = graphql(/* GraphQL */ `
  query OrganizationInstruments($organizationId: String!) {
    organizationInstruments(organizationId: $organizationId) {
      ...BaseInstrumentFields
    }
  }
`);

export const GET_HAMILTON_INSTRUMENTS = graphql(/* GraphQL */ `
  query HamiltonInstruments {
    hamiltonInstruments {
      ...BaseHamiltonInstrumentFields
    }
  }
`);

export const GET_AVAILABLE_INSTRUMENTS = graphql(/* GraphQL */ `
  query AvailableInstruments {
    availableInstruments {
      ...BaseInstrumentFields
      statusDetails {
        ...InstrumentStatusFields
      }
    }
  }
`);

export const GET_INSTRUMENT = graphql(/* GraphQL */ `
  query GetInstrument($id: ID!) {
    getInstrument(id: $id) {
      ...BaseInstrumentFields
      statusDetails {
        ...InstrumentStatusFields
      }
      mqttPassword
    }
  }
`);

export const GET_HAMILTON_INSTRUMENT = graphql(/* GraphQL */ `
  query GetHamiltonInstrument($id: ID!) {
    getHamiltonInstrument(id: $id) {
      ...BaseHamiltonInstrumentFields
    }
  }
`);

export const GET_ENVIRONMENT_INSTRUMENTS = graphql(/* GraphQL */ `
  query EnvironmentInstruments {
    environmentInstruments {
      id
      name
      organization {
        id
        name
      }
      createdAt
      softwareVersion
      hardwareVersion
      serialNumber
    }
  }
`);

export const CREATE_INSTRUMENT = graphql(/* GraphQL */ `
  mutation CreateInstrument($payload: CreateInstrumentInput!) {
    createInstrument(payload: $payload) {
      id
    }
  }
`);

export const UPDATE_INSTRUMENT = graphql(/* GraphQL */ `
  mutation UpdateInstrument($id: ID!, $payload: UpdateInstrumentInput!) {
    updateInstrument(id: $id, payload: $payload) {
      id
    }
  }
`);

export const CREATE_HAMILTON_INSTRUMENT = graphql(/* GraphQL */ `
  mutation CreateHamiltonInstrument($payload: EditHamiltonInstrumentPayload!) {
    createHamiltonInstrument(payload: $payload) {
      id
    }
  }
`);

export const DELETE_INSTRUMENT = graphql(/* GraphQL */ `
  mutation DeleteInstrument($id: String!) {
    deleteInstrument(id: $id)
  }
`);

export const DELETE_HAMILTON_INSTRUMENT = graphql(/* GraphQL */ `
  mutation DeleteHamiltonInstrument($id: ID!) {
    deleteHamiltonInstrument(id: $id)
  }
`);

export const DELETE_INSTRUMENT_BY_SN = graphql(/* GraphQL */ `
  mutation DeleteInstrumentBySerialNumber($serialNumber: String!) {
    deleteInstrumentBySerialNumber(serialNumber: $serialNumber)
  }
`);

export const DELETE_RUN = graphql(/* GraphQL */ `
  mutation DeleteRun($runId: Int!) {
    deleteRun(runId: $runId)
  }
`);

export const DELETE_RUNS = graphql(/* GraphQL */ `
  mutation DeleteRuns($runIds: [Int!]!) {
    deleteRuns(runIds: $runIds)
  }
`);

export const START_RUN = graphql(/* GraphQL */ `
  mutation StartRun($runId: Int!) {
    startRun(runId: $runId)
  }
`);

export const CHECK_RUN = graphql(/* GraphQL */ `
  mutation CheckRun($runId: Int!) {
    checkRun(runId: $runId)
  }
`);

export const UPDATE_INSTRUMENT_PASSWORD = graphql(/* GraphQL */ `
  mutation UpdateInstrumentPassword($id: ID!) {
    updateInstrumentPassword(id: $id)
  }
`);

export const UPDATE_HAMILTON_INSTRUMENT = graphql(/* GraphQL */ `
  mutation UpdateHamiltonInstrument(
    $id: ID!
    $payload: EditHamiltonInstrumentPayload!
  ) {
    updateHamiltonInstrument(id: $id, payload: $payload) {
      ...BaseHamiltonInstrumentFields
    }
  }
`);

export const DOWNLOADS_LOGS = graphql(/* GraphQL */ `
  mutation DownloadLogs(
    $id: ID!
    $startDate: DateTime
    $endDate: DateTime
    $requestId: String!
  ) {
    downloadLogs(
      id: $id
      startDate: $startDate
      endDate: $endDate
      requestId: $requestId
    )
  }
`);

export const GET_MEMBERS_WITH_NO_ACCESS = graphql(/* GraphQL */ `
  query MembersWithoutAccessTo($id: ID!, $subject: AuthorizationSubject!) {
    membersWithoutAccessTo(id: $id, subject: $subject) {
      id
      user {
        name
        email
      }
    }
  }
`);

export const SUBSCRIBE_INSTRUMENT = graphql(/* GraphQL */ `
  subscription InstrumentChanges($id: ID!) {
    instrumentChanges(id: $id) {
      ...BaseInstrumentFields
      statusDetails {
        ...InstrumentStatusFields
      }
    }
  }
`);

export const SUBSCRIBE_INSTRUMENT_LOGS = graphql(/* GraphQL */ `
  subscription InstrumentLogs($requestId: String!) {
    instrumentLogs(requestId: $requestId) {
      url
      requestId
    }
  }
`);
