import {
  Text,
  Html,
  Font,
  Section,
  Preview,
  Link,
  Heading,
  Img,
  Container,
} from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";

import Signature from "./signature";

export interface ForgotPasswordEmailProps {
  firstName: string;
  lastName: string;
  rootUrl: string;
  urlPath: string;
}

const ForgotPasswordEmail = ({
  firstName,
  lastName,
  rootUrl,
  urlPath,
}: ForgotPasswordEmailProps) => (
  <Html>
    <Tailwind
      config={{
        theme: {
          extend: {
            colors: {
              primary: "rgb(255 88 67)",
              secondary: "rgb(5 7 52)",
            },
          },
        },
      }}
    >
      <Font
        fontFamily="Roboto"
        fallbackFontFamily="Verdana"
        webFont={{
          format: "woff2",
          url: "https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2",
        }}
        fontWeight={400}
        fontStyle="normal"
      />
      <Preview>{`[DNA Script] Reset password`}</Preview>
      <Container className="p-4">
        <Section className="bg-secondary flex flex-row justify-between p-4">
          <Img
            src={`${rootUrl}/logo_red.png`}
            alt="Logo"
            width="311"
            height="31"
          />
          <Heading
            className="text-white"
            as="h2"
          >{`Reset your DNA Script password`}</Heading>
        </Section>
        <Section>
          <Text>{`Dear ${firstName} ${lastName}`},</Text>
        </Section>
        <Section>
          <Text>
            {`There was a request to change the password on your account. If you requested this password change, please click the link `}
            <Link href={`${rootUrl}${urlPath}`}>link</Link>
            {` to set a new password within 24 hours. `}
            {`If you did not initiate this request or if you don't want to change your password, just ignore this message. `}
          </Text>
        </Section>
        <Signature />
      </Container>
    </Tailwind>
  </Html>
);

export default ForgotPasswordEmail;
