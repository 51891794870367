import { MixerHorizontalIcon } from "@radix-ui/react-icons";
import type { Column, Table } from "@tanstack/react-table";
import { XCircle } from "lucide-react";
import { useEffect, useMemo, useState } from "react";

import Combobox from "../combobox";
import { Input } from "../input";
import { Switch } from "../switch";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

const useGetFacetedUniqueValues = (
  firstValue: unknown,
  column: Column<any, unknown>,
): string[] => {
  return useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [column.getFacetedUniqueValues()],
  );
};

export function Filter({
  column,
  table,
}: {
  column: Column<any, unknown>;
  table: Table<any>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = useGetFacetedUniqueValues(firstValue, column);

  const allValues = table
    .getPreFilteredRowModel()
    .flatRows.map((r) => r.getValue(column.id));

  const [minValue, maxValue] = column.getFacetedMinMaxValues() || ["", ""];
  const isNumber = typeof firstValue === "number";
  const isList = Array.isArray(firstValue);

  if (column.columnDef.meta?.booleanFilter) {
    return <SwitchFilterOptions column={column} table={table} />;
  }

  if (column.columnDef.meta?.uniqueFilter) {
    return <ColumnFilterOptions column={column} table={table} />;
  }

  return (
    <div className="flex flex-row items-center space-x-1">
      {isNumber ? (
        <div>
          <div className="flex space-x-2">
            <DebouncedInput
              className="w-28 rounded border shadow"
              max={Number(maxValue ?? "")}
              min={Number(minValue ?? "")}
              onChange={(value) =>
                column.setFilterValue((old: [number, number]) => [
                  value,
                  old?.[1],
                ])
              }
              placeholder={`Min ${minValue ? `(${minValue})` : ""}`}
              type="number"
              value={(columnFilterValue as [number, number])?.[0] ?? ""}
            />
            <DebouncedInput
              className="w-28 rounded border shadow"
              max={Number(maxValue ?? "")}
              min={Number(minValue ?? "")}
              onChange={(value) =>
                column.setFilterValue((old: [number, number]) => [
                  old?.[0],
                  value,
                ])
              }
              placeholder={`Max ${maxValue ? `(${maxValue})` : ""}`}
              type="number"
              value={(columnFilterValue as [number, number])?.[1] ?? ""}
            />
          </div>
          <div className="h-1" />
        </div>
      ) : isList ? (
        <Combobox
          create={false}
          options={[...new Set((allValues as string[][]).flat())]}
          setOptions={() => {}}
          setValues={(values) => {
            column.setFilterValue(values);
          }}
          values={(columnFilterValue || []) as string[]}
        />
      ) : (
        <>
          <datalist id={column.id + "list"}>
            {sortedUniqueValues.slice(0, 5000).map((value: any) => (
              <option key={value} value={value} />
            ))}
          </datalist>
          <DebouncedInput
            className="w-40 rounded border shadow"
            list={column.id + "list"}
            onChange={(value) => column.setFilterValue(value)}
            placeholder={`Search... (${sortedUniqueValues.length})`}
            type="text"
            value={(columnFilterValue ?? "") as string}
          />
          <div className="h-1" />
        </>
      )}
      <RemoveFilter column={column} table={table} />
    </div>
  );
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  debounce?: number;
  onChange: (value: string | number) => void;
  value: string | number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Input
      {...props}
      onChange={(e) => setValue(e.target.value)}
      value={value}
    />
  );
}

function SwitchFilterOptions<TData, TValue>({
  table,
  column,
}: {
  column: Column<TData, TValue>;
  table: Table<TData>;
}) {
  const on = column.getFilterValue() as boolean;
  return (
    <div className="flex flex-row items-center space-x-1">
      <Switch
        checked={on}
        className="data-[state=checked]:bg-secondary"
        onCheckedChange={(checked) => column.setFilterValue(checked)}
      />
      <RemoveFilter column={column} table={table} />
    </div>
  );
}

function ColumnFilterOptions<TData, TValue>({
  table,
  column,
}: {
  column: Column<TData, TValue>;
  table: Table<TData>;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);
  const sortedUniqueValues = useGetFacetedUniqueValues(firstValue, column);
  const filterValue = column.getFilterValue() as string[] | undefined | null;
  const isCheckedValues = sortedUniqueValues.map(
    (v) => !filterValue || filterValue?.includes(v),
  );
  const checkedValuesCount = isCheckedValues.filter((v) => v).length;

  if (filterValue && !Array.isArray(filterValue)) {
    return null;
  }

  return (
    <div className="flex flex-row items-center space-x-1">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="ml-auto hidden h-8 lg:flex"
            size="sm"
            variant="outline"
          >
            <MixerHorizontalIcon className="mr-2 h-4 w-4" />
            {`Select (${checkedValuesCount}/${sortedUniqueValues.length})`}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[150px]">
          <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {sortedUniqueValues.map((value, index) => {
            return (
              <DropdownMenuCheckboxItem
                checked={isCheckedValues[index]}
                className="capitalize"
                key={value}
                onCheckedChange={() => {
                  column.setFilterValue(
                    filterValue === undefined
                      ? sortedUniqueValues.filter((v) => v !== value)
                      : filterValue?.includes(value)
                        ? filterValue?.filter((v) => v !== value)
                        : [...(filterValue ?? []), value],
                  );
                }}
              >
                {value}
              </DropdownMenuCheckboxItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
      <RemoveFilter column={column} table={table} />
    </div>
  );
}

function RemoveFilter<TData, TValue>({
  table,
  column,
}: {
  column: Column<TData, TValue>;
  table: Table<TData>;
}) {
  return (
    <Button
      onClick={() => {
        table.options.meta?.toggleFilterColumn?.(column.id);
        column.setFilterValue(undefined);
      }}
      size={"sm"}
      variant={"ghost"}
    >
      <XCircle size={20} />
    </Button>
  );
}
