import type { PlateSize } from "../../../../../config/enums";

export enum DragObjects {
  Oligo = "Oligo",
  Well = "Well",
}

export type Oligo = {
  geneId?: string;
  id: string;
  name: string;
  order: number;
  platePositioningExpected: string;
  sequence: string;
  synthesized: boolean;
};
export type Well = {
  column: number;
  index: string;
  oligoId?: string;
  row: string;
};
export type Plate = {
  id: string;
  kit: string;
  locked: boolean;
  name: string;
  size: PlateSize;
  wells: Well[];
};
export type Workflow = {
  oligos: Oligo[];
  plates: Plate[];
};
