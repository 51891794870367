import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
  split,
} from "@apollo/client";
import type { Operation, FetchResult } from "@apollo/client/core";
import { Observable } from "@apollo/client/core";
import type { GraphQLErrors } from "@apollo/client/errors";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from "@apollo/client/utilities";
import type { GraphQLError } from "graphql";
import { print } from "graphql";
import type { Client, ClientOptions } from "graphql-sse";
import { createClient } from "graphql-sse";

import { loadAuthToken } from "./localStorage";

import { toast } from "../components/ui/use-toast";

class SSELink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
          next: sink.next.bind(sink),
        },
      );
    });
  }
}

export const sseLink = new SSELink({
  headers: () => {
    const token = loadAuthToken();
    return {
      Authorization: `Bearer ${token}`,
      "x-allow-arbitrary-operations": "true",
    };
  },
  url: "/graphql",
});

const httpLink = new HttpLink({
  uri: "/graphql",
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  sseLink,
  httpLink,
);

const processGqlErrors = (graphQLErrors: GraphQLErrors | undefined) => {
  if (!graphQLErrors) {
    return;
  }

  for (const graphQLError of graphQLErrors) {
    reloadOnUnauthorized(graphQLError);
    displayGenericToast(graphQLError);
  }
};

function displayGenericToast(graphQLError: GraphQLError) {
  if (isGraphQLErrorUnauthauthed(graphQLError)) {
    return;
  }
  toast({
    description: graphQLError.message,
    title: "GraphQL Error",
    variant: "destructive",
  });
}

function isGraphQLErrorUnauthauthed(graphQLError: GraphQLError) {
  return graphQLError?.extensions?.code === "UNAUTHENTICATED";
}

const reloadOnUnauthorized = (graphQLError: GraphQLError) => {
  if (isGraphQLErrorUnauthauthed(graphQLError)) {
    window.dispatchEvent(new Event("logout"));
  }
};

const errorLink = onError(({ graphQLErrors }) => {
  processGqlErrors(graphQLErrors);
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = loadAuthToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: {
      Construct: ["OligoSetConstruct", "GeneConstruct"],
      WorkflowStep: ["SynthesisWorkflowStep", "HamiltonWorkflowStep"],
    },
  }),
  defaultOptions: {
    mutate: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
      fetchPolicy: "cache-first",
    },
    watchQuery: {
      errorPolicy: "all",
      fetchPolicy: "cache-first",
      nextFetchPolicy: "cache-first",
    },
  },
  link: from([authLink, errorLink, splitLink]),
  queryDeduplication: true,
  uri: "http://localhost:3000/graphql",
});
