import type { RouteObject } from "react-router-dom";
import { Outlet } from "react-router-dom";

import NewInstrument, { PickInstrumentType } from "./new-instrument";
import { OrganizationRoutes } from "./organization-routes";
import Assay from "./pages/assay/assay";
import Assays from "./pages/assay/assays";
import NewAssay from "./pages/assay/new-assay";
import QuickPlateUpload from "./pages/assay/quick-plate-upload";
import WorkflowStep from "./pages/build/workflow-step";
import Construct from "./pages/construct/construct";
import Library from "./pages/construct/library";
import NewConstruct from "./pages/construct/new-construct";
import Home from "./pages/home";

import HamiltonInstrument from "../instrument/hamilton-instrument";
import HamiltonInstrumentEdit from "../instrument/hamilton-instrument-edit";
import Instrument from "../instrument/instrument";
import InstrumentEdit from "../instrument/instrument-edit";
import InstrumentTroubleshoot from "../instrument/instrument-troubleshoot";
import InstrumentHome from "../instrument/instruments";
import NewHamiltonInstrument from "../instrument/new-hamilton-instrument";
import NewSyntaxInstrument from "../instrument/new-syntax-instrument";
import OrganizationHome from "../organization-home";
import OrganizationSettingsRouter from "../settings/organization-settings-router";

const OrganizationRouter: RouteObject = {
  children: [
    {
      element: <Home />,
      index: true,
    },
    {
      children: [
        {
          element: <Assays />,
          index: true,
        },
        {
          children: [
            {
              element: <Assay />,
              index: true,
            },
            {
              element: <WorkflowStep />,
              handle: {
                crumb: (params: { stepId: string }) => `Step ${params.stepId}`,
              },
              path: OrganizationRoutes.ASSAY_WORKFLOW_STEP,
            },
          ],
          element: <Outlet />,
          handle: {
            crumb: (params: { assayId: string }) => `Assay ${params.assayId}`,
          },
          path: OrganizationRoutes.ASSAY,
        },
      ],
      element: <Outlet />,
      handle: {
        crumb: "Assays",
      },
      path: OrganizationRoutes.ASSAYS,
    },
    {
      element: <Library />,
      handle: {
        crumb: "Library",
      },
      path: OrganizationRoutes.LIBRARY,
    },
    {
      element: <Construct />,
      handle: {
        crumb: (params: { constructId: string }) =>
          `Construct ${params.constructId}`,
      },
      path: OrganizationRoutes.CONSTRUCT,
    },
    {
      element: <NewConstruct />,
      handle: {
        crumb: "New construct",
      },
      path: OrganizationRoutes.CONSTRUCT_NEW,
    },
    {
      element: <QuickPlateUpload />,
      handle: {
        crumb: "Quick plate",
      },
      path: OrganizationRoutes.QUICK_PLATE,
    },
    {
      element: <NewAssay />,
      handle: {
        crumb: "New assay",
      },
      path: OrganizationRoutes.ASSAY_NEW,
    },
    {
      children: [
        {
          element: <InstrumentHome />,
          index: true,
        },
        {
          children: [
            {
              element: <Instrument />,
              index: true,
            },
            {
              element: <InstrumentTroubleshoot />,
              handle: {
                crumb: "Troubleshooting",
              },
              path: OrganizationRoutes.INSTRUMENT_TROUBLESHOOTING,
            },
          ],
          element: <Outlet />,
          handle: {
            crumb: (params: { instrumentId: string }) =>
              `Instrument ${params.instrumentId}`,
          },
          path: OrganizationRoutes.INSTRUMENT,
        },
        {
          element: <InstrumentEdit />,
          path: OrganizationRoutes.INSTRUMENT_EDIT,
        },
      ],
      element: <Outlet />,
      handle: {
        crumb: "Instruments",
      },
      path: OrganizationRoutes.INSTRUMENTS,
    },
    {
      children: [
        {
          element: <HamiltonInstrumentEdit />,
          handle: {
            crumb: "Edit",
          },
          path: OrganizationRoutes.INSTRUMENT_HAMILTON_EDIT,
        },
        {
          element: <HamiltonInstrument />,
          index: true,
        },
      ],
      element: <Outlet />,
      handle: {
        crumb: (params: { instrumentId: string }) =>
          `Hamilton ${params.instrumentId}`,
      },
      path: OrganizationRoutes.INSTRUMENT_HAMILTON,
    },
    {
      children: [
        {
          element: <NewSyntaxInstrument />,
          handle: {
            crumb: "Syntax",
          },
          path: OrganizationRoutes.INSTRUMENT_SYNTAX_NEW,
        },
        {
          element: <NewHamiltonInstrument />,
          handle: {
            crumb: "Hamilton",
          },
          path: OrganizationRoutes.HAMILTON_INSTRUMENT_NEW,
        },
        {
          element: <PickInstrumentType />,
          index: true,
        },
      ],
      element: <NewInstrument />,
      handle: {
        crumb: "New instrument",
      },
      path: OrganizationRoutes.INSTRUMENT_NEW,
    },
    OrganizationSettingsRouter,
  ],
  element: <OrganizationHome />,
  path: "organization",
};

export default OrganizationRouter;
