import { Slot } from "@radix-ui/react-slot";
import { ChevronDownIcon, ChevronRight, MoreHorizontal } from "lucide-react";
import * as React from "react";
import { useMemo } from "react";
import { matchRoutes, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./dropdown-menu";

import { rootRoutes } from "../../routes/router";
import { RootRoutes } from "../../routes/routes";
import { OrganizationSettingsRoutes } from "../../routes/settings/organization-settings/organization-settings-routes";

import { cn } from "@/lib/utils";

const Breadcrumb = React.forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<"nav"> & {
    separator?: React.ReactNode;
  }
>(({ ...props }, ref) => <nav aria-label="breadcrumb" ref={ref} {...props} />);
Breadcrumb.displayName = "Breadcrumb";

const BreadcrumbList = React.forwardRef<
  HTMLOListElement,
  React.ComponentPropsWithoutRef<"ol">
>(({ className, ...props }, ref) => (
  <ol
    className={cn(
      "text-muted-foreground flex flex-wrap items-center gap-1.5 break-words text-sm sm:gap-2.5",
      className,
    )}
    ref={ref}
    {...props}
  />
));
BreadcrumbList.displayName = "BreadcrumbList";

const BreadcrumbItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentPropsWithoutRef<"li">
>(({ className, ...props }, ref) => (
  <li
    className={cn("inline-flex items-center gap-1.5", className)}
    ref={ref}
    {...props}
  />
));
BreadcrumbItem.displayName = "BreadcrumbItem";

const BreadcrumbLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<"a"> & {
    asChild?: boolean;
  }
>(({ asChild, className, ...props }, ref) => {
  const Comp = asChild ? Slot : "a";

  return (
    <Comp
      className={cn("hover:text-foreground transition-colors", className)}
      ref={ref}
      {...props}
    />
  );
});
BreadcrumbLink.displayName = "BreadcrumbLink";

const BreadcrumbPage = React.forwardRef<
  HTMLSpanElement,
  React.ComponentPropsWithoutRef<"span">
>(({ className, ...props }, ref) => (
  <span
    aria-current="page"
    aria-disabled="true"
    className={cn("text-foreground font-normal", className)}
    ref={ref}
    role="link"
    {...props}
  />
));
BreadcrumbPage.displayName = "BreadcrumbPage";

const BreadcrumbSeparator = ({
  children,
  className,
  ...props
}: React.ComponentProps<"li">) => (
  <li
    aria-hidden="true"
    className={cn("[&>svg]:size-3.5", className)}
    role="presentation"
    {...props}
  >
    {children ?? <ChevronRight />}
  </li>
);
BreadcrumbSeparator.displayName = "BreadcrumbSeparator";

const BreadcrumbEllipsis = ({
  className,
  ...props
}: React.ComponentProps<"span">) => (
  <span
    aria-hidden="true"
    className={cn("flex h-9 w-9 items-center justify-center", className)}
    role="presentation"
    {...props}
  >
    <MoreHorizontal className="h-4 w-4" />
    <span className="sr-only">More</span>
  </span>
);
BreadcrumbEllipsis.displayName = "BreadcrumbElipssis";

type DNAScriptBreadCrumbs = {
  label: string;
  path: string;
  siblings: { label: string; path: string }[];
}[];

const getSiblings = (
  previousValue: NonNullable<ReturnType<typeof matchRoutes>>[0],
): DNAScriptBreadCrumbs[0]["siblings"] => {
  if (!previousValue) {
    return [];
  }
  if (
    previousValue.pathname === RootRoutes.ADMIN ||
    previousValue.pathname === OrganizationSettingsRoutes.HOME
  ) {
    return (previousValue.route.children ?? []).reduce(
      (acc, childValue) => {
        if ("index" in childValue && childValue.index) {
          return acc;
        }
        const childLabel = "handle" in childValue && childValue?.handle?.crumb;
        if (!childLabel || !childValue.path) {
          return acc;
        }
        return acc.concat([{ label: childLabel, path: childValue.path ?? "" }]);
      },
      [] as DNAScriptBreadCrumbs[0]["siblings"],
    );
  }
  return [];
};

const BreadCrumbPage = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const breadcrumbs: DNAScriptBreadCrumbs = useMemo(() => {
    const matches = matchRoutes(rootRoutes, {
      pathname,
    });
    return (matches ?? []).reduce((acc, value, index, matchesArray) => {
      const route = value.route;
      let label = route.handle?.crumb;
      if (typeof label === "function") {
        label = label(value.params);
      }

      const previousValue = matchesArray?.[index - 1];
      const siblings = getSiblings(previousValue);

      const path = Object.entries(value.params ?? {}).reduce((path, value) => {
        if (!value[1]) {
          return path;
        }
        return path.replace(`:${value[0]}`, value[1]);
      }, route.path ?? "");
      if (!label || !path) {
        return acc;
      }
      return acc.concat([{ label: label ?? "", path, siblings: siblings }]);
    }, [] as DNAScriptBreadCrumbs);
  }, [pathname]);

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {breadcrumbs.map(({ path, label, siblings }, index) => (
          <React.Fragment key={path}>
            <BreadcrumbItem>
              {siblings.length > 0 ? (
                <DropdownMenu>
                  <DropdownMenuTrigger className="flex items-center gap-1">
                    <BreadcrumbLink asChild className="hover:underline">
                      <Link to={path}>{label}</Link>
                    </BreadcrumbLink>
                    <ChevronDownIcon size={12} />
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="start">
                    {siblings.map(({ label, path }) => (
                      <DropdownMenuItem
                        asChild
                        className="cursor-pointer"
                        key={path}
                      >
                        <Link to={path}>{label}</Link>
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              ) : (
                <BreadcrumbLink asChild className="hover:underline">
                  <Link to={path}>{label}</Link>
                </BreadcrumbLink>
              )}
            </BreadcrumbItem>
            {index < breadcrumbs.length - 1 && <BreadcrumbSeparator />}
          </React.Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export {
  Breadcrumb,
  BreadCrumbPage,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator,
  BreadcrumbEllipsis,
};
