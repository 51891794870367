import { getQueryKey } from "@trpc/react-query";
import { useNavigate } from "react-router-dom";

import type { EditUserFormType } from "./components/edit-user-form";
import EditUserForm from "./components/edit-user-form";

import { useToast } from "../../../components/ui/use-toast";
import { queryClient, trpc } from "../../../config/trpc";
import { AdminRoutes } from "../adminRoutes";

export default function NewUser() {
  const navigate = useNavigate();
  const { toast } = useToast();

  const { mutate: createUser } = trpc.account.user.create.useMutation({
    onError(error) {
      toast({
        description: error.message,
        title: "Error creating user",
        variant: "destructive",
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(trpc.account.user.list),
      });
      navigate(AdminRoutes.USERS);
      toast({
        description: "The user has been created successfully",
        title: "User created",
        variant: "success",
      });
    },
  });

  function onSubmit(values: EditUserFormType) {
    createUser({
      email: values.email,
      firstname: values.firstname,
      isAdmin: values.isAdmin,
      isSupport: values.isSupport,
      lastname: values.lastname,
      password: values.password ?? null,
    });
  }

  return (
    <div>
      <h3 className="font-bold">New user</h3>
      <EditUserForm
        onCancel={() => navigate(AdminRoutes.USERS)}
        onSubmit={onSubmit}
      />
    </div>
  );
}
