import { Navigate, createBrowserRouter } from "react-router-dom";

import { AdminRouter } from "./admin/adminRoutes";
import ChooseOrganization from "./choose-organization";
import Email, { Emails } from "./emails";
import ErrorPage from "./error-page";
import ForgotPassword from "./forgot-password";
import Icons from "./icons";
import Login from "./login";
import MFA from "./mfa";
import MfaRecovery from "./mfa-recovery";
import NotFound from "./not-found";
import OrganizationRouter from "./organization/organization-router";
import ProtectedRoute from "./protected-route";
import Root from "./root";
import { RootRoutes } from "./routes";
import Screening from "./screening";
import SetNewPassword from "./set-new-password";
import SetPassword from "./set-password";
import { SettingRoutes } from "./settings/setting-routes";
import UserEditProfile from "./settings/user-edit-profile";
import UserNotFound from "./settings/user-not-found";
import UserProfile from "./settings/user-profile";

const authenticationRoutes = [
  {
    element: <Login />,
    path: RootRoutes.LOGIN,
  },
  {
    element: <ForgotPassword />,
    path: RootRoutes.FORGOT_PASSWORD,
  },
  {
    element: <SetNewPassword />,
    path: RootRoutes.SET_NEW_PASSWORD,
  },
  {
    element: <MFA />,
    path: RootRoutes.MFA,
  },
  {
    element: <MfaRecovery />,
    path: RootRoutes.MFA_RECOVERY,
  },
];

export const rootRoutes = [
  {
    children: [
      {
        element: <Navigate replace to={RootRoutes.ORGANIZATION} />,
        index: true,
      },
      {
        element: <ChooseOrganization />,
        handle: {
          crumb: "Pick organization",
        },
        path: RootRoutes.PICK_ORGANIZATION,
      },
      {
        element: <SetPassword />,
        handle: {
          crumb: "Set password",
        },
        path: RootRoutes.SET_PASSWORD_SHOULD_RESET,
      },
      OrganizationRouter,
      {
        element: <UserProfile />,
        errorElement: <UserNotFound />,
        handle: {
          crumb: "Profile",
        },
        path: SettingRoutes.PROFILE,
      },
      {
        element: <UserEditProfile />,
        errorElement: <UserNotFound />,
        handle: {
          crumb: "Edit profile",
        },
        path: SettingRoutes.PROFILE_EDIT,
      },
      AdminRouter,
      {
        element: <NotFound />,
        handle: {
          crumb: "404",
        },
        path: "*",
      },
    ],
    element: (
      <ProtectedRoute>
        <Root />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: "Home" as string | ((payload: any) => string),
    },
    path: RootRoutes.ROOT,
  },
  ...authenticationRoutes,
  {
    element: <Emails />,
    path: RootRoutes.EMAILS,
  },
  {
    element: <Email />,
    path: RootRoutes.EMAIL,
  },
  {
    element: <Icons />,
    path: RootRoutes.ICONS,
  },
  {
    element: <Screening />,
    path: RootRoutes.SCREENING,
  },
  {
    element: <NotFound />,
    path: "*",
  },
];

const router = createBrowserRouter(rootRoutes);

export default router;
