import { useMutation } from "@apollo/client";

import { AccountStatus } from "../../../../config/enums";
import {
  ACTIVATE_ORGANIZATION_MEMBER,
  DEACTIVATE_ORGANIZATION_MEMBER,
} from "../../../../gql/organization-member";

export const useActivateOrDeactivateOrgMember = (
  id: string,
  activate: boolean,
) => {
  const [activateMember] = useMutation(ACTIVATE_ORGANIZATION_MEMBER, {
    update: (cache) => {
      cache.modify({
        fields: {
          status: () => AccountStatus.ACTIVE,
        },
        id: cache.identify({
          __typename: "OrganizationMember",
          id,
        }),
      });
    },
  });

  const [deactivateMember] = useMutation(DEACTIVATE_ORGANIZATION_MEMBER, {
    update: (cache) => {
      cache.modify({
        fields: {
          status: () => AccountStatus.INACTIVE,
        },
        id: cache.identify({
          __typename: "OrganizationMember",
          id,
        }),
      });
    },
  });

  if (activate) {
    return () => {
      activateMember({
        variables: {
          id,
        },
      });
    };
  }
  return () => {
    deactivateMember({
      variables: {
        id,
      },
    });
  };
};
