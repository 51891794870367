import { useMutation, useQuery } from "@apollo/client";
import { Heading } from "@radix-ui/themes";
import { useParams } from "react-router-dom";

import { AuthorizationsBlock } from "./components/authorizations-block";
import TeamMembershipsBlock from "./components/team-memberships-block";
import type { OrganizationMemberAuthorizations } from "./constants";

import { IsActive } from "../../../components/table/basic";
import CopyToClipboard from "../../../components/ui/clipboard";
import {
  GET_MEMBER,
  UPDATE_ORGANIZATION_MEMBER_PERMISSIONS,
} from "../../../gql/organization-member";
import { removeTypename } from "../../../utils/gql";
import { CheckIfTrueElseCross } from "../../admin/organizations/components/ui";

export default function OrganizationMember() {
  const { userId } = useParams();
  const { data } = useQuery(GET_MEMBER, {
    skip: !userId,
    variables: {
      id: userId ?? "",
    },
  });

  const [updatePermissions] = useMutation(
    UPDATE_ORGANIZATION_MEMBER_PERMISSIONS,
    {
      refetchQueries: ["Member"],
    },
  );
  const handleUpdatePermissions = (
    authorizations: OrganizationMemberAuthorizations,
  ) => {
    if (!userId) {
      return;
    }
    updatePermissions({
      variables: {
        id: userId,
        permissions: authorizations,
      },
    });
  };

  if (!data || !data.member) {
    return null;
  }

  const { user } = data.member;
  const { email, name, mfaActivated } = user;

  return (
    <div className="space-y-8">
      <Heading>{`User ${data?.member.user.name}`}</Heading>
      <div className="bg-card space-y-2 rounded-lg border p-4">
        <div className="flex flex-row items-center justify-between space-x-2">
          <h3 className="font-bold">Details</h3>
          <IsActive isActive={data?.member.status === "ACTIVE"} />
        </div>
        <div className="flex flex-row items-center space-x-2">
          <span>Email</span>
          <span>{email}</span>
          <CopyToClipboard value={email} />
        </div>
        <div className="flex flex-row items-center space-x-2">
          <span>Name</span>
          <span>{name}</span>
        </div>
        <div className="flex flex-row items-center space-x-2">
          <span>MFA</span>
          <CheckIfTrueElseCross value={mfaActivated} />
          <span>{mfaActivated ? "Activated" : "Not activated"}</span>
        </div>
      </div>
      <AuthorizationsBlock
        authorizations={removeTypename(data?.member.globalAuthorizations)}
        editable={false}
        title="Global authorizations"
      />
      <AuthorizationsBlock
        authorizations={removeTypename(data?.member.authorizations)}
        editable={true}
        handleChangeAuthorizations={handleUpdatePermissions}
      />
      {userId && <TeamMembershipsBlock organizationUserId={userId} />}
    </div>
  );
}
