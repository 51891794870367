import { graphql } from "../__generated__/gql";

export const RUN_FIELDS = graphql(/* GraphQL */ `
  fragment ProgressFields on RunProgress {
    currentPhaseIndex
    phases {
      description
      estimatedRemainingTime {
        seconds
        lastUpdate
      }
      label
      startTime
    }
    startTime
  }

  fragment RunFields on Run {
    id
    canRun
    createdAt
    createdBy {
      name
    }
    instrument {
      id
      name
    }
    progress {
      ...ProgressFields
    }
    state
    washStep
    report {
      ...RunReportFields
    }
  }

  fragment RunReportFields on RunReport {
    metrics {
      measuredDuration
      concentrationError
      yieldError
      startTime
      endTime
      successRate
    }
    consumables {
      reagents {
        amountRemaining
        amountUsed
        capacity
        daysOnSystem
        displayName
        expirationDate
        group
        lotNumber
        name
        partNumber
        position
        slot
      }
      plates {
        designId
        expirationDate
        lotNumber
        manufacturingTime
        name
        partNumber
        size
        type
        uniqueNumber
      }
    }
  }
`);

export const RUN_UPDATE = graphql(/* GraphQL */ `
  subscription RunUpdate($id: Int!) {
    runUpdate(id: $id) {
      ...RunFields
    }
  }
`);

export const DOWNLOAD_REPORT = graphql(/* GraphQL */ `
  mutation DownloadReport($id: Int!, $format: RunReportFileFormat!) {
    downloadReport(id: $id, format: $format)
  }
`);

export const DOWNLOAD_PRINT_JOB = graphql(/* GraphQL */ `
  mutation DownloadPrintJob($id: Int!) {
    downloadPrintJob(id: $id)
  }
`);
