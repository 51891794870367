import { Label } from "../../../../../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../../../../components/ui/radio";
import { Normalization } from "../../../../../config/enums";
import { cn } from "../../../../../lib/utils";
import TargetConcentration from "../../build/components/plate/target-concentration";

export default function PickConcentration({
  normalization,
  handleApplyConcentrationChange,
  isEditable,
  localConcentration,
  kitConcentrationMax,
  kitConcentrationMin,
  setLocalConcentration,
}: {
  handleApplyConcentrationChange: (
    normalization: Normalization,
    concentration?: number,
  ) => void;
  isEditable: boolean;
  kitConcentrationMax: number;
  kitConcentrationMin: number;
  localConcentration: number[];
  normalization: Normalization;
  setLocalConcentration: (v: number[]) => void;
}) {
  const isTargetNormalization = normalization === Normalization.Target;
  return (
    <div className={cn("space-y-2", isTargetNormalization && "pb-4")}>
      <div className="flex flex-row items-center justify-between space-x-4">
        <p className="font-medium">Normalization</p>
        <div className="justify-end">
          <RadioGroup
            className="flex flex-row gap-2"
            disabled={!isEditable}
            onValueChange={(v) => {
              const newNormalization = v as Normalization;
              handleApplyConcentrationChange(
                newNormalization,
                newNormalization === Normalization.Target
                  ? kitConcentrationMin
                  : undefined,
              );
            }}
            value={normalization}
          >
            {Object.values(Normalization).map((n) => {
              return (
                <div className="flex items-center space-x-1" key={n}>
                  <RadioGroupItem id={n} value={n} />
                  <Label htmlFor={n}>{n}</Label>
                </div>
              );
            })}
          </RadioGroup>
        </div>
      </div>
      {isTargetNormalization && (
        <div className="space-y-2">
          <p className="font-medium">Concentration (Target)</p>
          <TargetConcentration
            handleApplyConcentrationChange={handleApplyConcentrationChange}
            isEditable={isEditable}
            localConcentration={localConcentration}
            maxConcentration={kitConcentrationMax}
            minConcentration={kitConcentrationMin}
            setLocalConcentration={setLocalConcentration}
          />
        </div>
      )}
    </div>
  );
}
