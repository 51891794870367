import {
  Text,
  Html,
  Font,
  Section,
  Preview,
  Link,
  Heading,
  Img,
  Container,
} from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";

import Signature from "./signature";

export interface NewUserEmailProps {
  adminEmail: string;
  adminFirstName: string;
  adminLastName: string;
  firstName: string;
  lastName: string;
  password: string | undefined;
  rootUrl: string;
  urlPath: string;
}

const NewUserEmail = ({
  adminEmail,
  adminFirstName,
  adminLastName,
  firstName,
  lastName,
  password,
  rootUrl,
  urlPath,
}: NewUserEmailProps) => (
  <Html>
    <Tailwind
      config={{
        theme: {
          extend: {
            colors: {
              primary: "rgb(255 88 67)",
              secondary: "rgb(5 7 52)",
            },
          },
        },
      }}
    >
      <Font
        fontFamily="Roboto"
        fallbackFontFamily="Verdana"
        webFont={{
          format: "woff2",
          url: "https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2",
        }}
        fontWeight={400}
        fontStyle="normal"
      />
      <Preview>{`Account created`}</Preview>
      <Container className="p-4">
        <Section className="bg-secondary flex flex-row justify-between p-4">
          <Img
            src={`${rootUrl}/logo_red.png`}
            alt="Logo"
            width="311"
            height="31"
          />
          <Heading
            className="text-white"
            as="h2"
          >{`DNA script account`}</Heading>
        </Section>
        <Section>
          <Text>{`Dear ${firstName} ${lastName}`},</Text>
        </Section>
        <Section>
          <Text>
            {`You have been added to DNA Script Console. Please click on this `}
            <Link href={`${rootUrl}${urlPath}`}>link</Link>
            {password
              ? ` and use your email and password: "${password}" to log into the system. `
              : ` to set up your password. `}
            {`If you have any questions or issues with the account setup, please contact ${adminFirstName} ${adminLastName} at ${adminEmail} directly.`}
          </Text>
        </Section>
        <Signature />
      </Container>
    </Tailwind>
  </Html>
);

export default NewUserEmail;
