import { Archive, ArchiveRestore } from "lucide-react";

import { AuthorizationSubject } from "../../__generated__/graphql";
import { trpc } from "../../config/trpc";
import { capitalize } from "../../utils/string";
import { AlertDialogWrapper } from "../ui/alert-dialog";
import { useToast } from "../ui/use-toast";

const SubjectToLabel: Record<AuthorizationSubject, string> = {
  [AuthorizationSubject.Construct]: "construct",
  [AuthorizationSubject.Assay]: "assay",
  [AuthorizationSubject.HamiltonInstrument]: "hamilton instrument",
  [AuthorizationSubject.Instrument]: "instrument",
};

export const ArchiveButton = ({
  subjectType,
  id,
}: {
  id: string;
  subjectType: AuthorizationSubject;
}) => {
  const { toast } = useToast();
  const subjectLabel = SubjectToLabel[subjectType];

  const { mutate: archiveObject } =
    trpc.account.authorization.archive.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess() {
        toast({
          title: `${capitalize(subjectLabel)} archived`,
          variant: "default",
        });
      },
    });

  const handleArchiveObject = () => {
    archiveObject({
      id,
      subjectType,
    });
  };

  return (
    <AlertDialogWrapper
      description={`Are you sure you want to archive this ${subjectLabel} ?`}
      onConfirm={handleArchiveObject}
      title={`Archive ${subjectLabel}`}
    >
      <div className="flex flex-row items-center space-x-1">
        <Archive />
        <span>Archive</span>
      </div>
    </AlertDialogWrapper>
  );
};

export const RestoreButton = ({
  subjectType,
  id,
}: {
  id: string;
  subjectType: AuthorizationSubject;
}) => {
  const { toast } = useToast();
  const subjectLabel = SubjectToLabel[subjectType];

  const { mutate: unarchiveObject } =
    trpc.account.authorization.unarchive.useMutation({
      onError(error) {
        toast({
          description: error.message,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess() {
        toast({
          title: `${capitalize(subjectLabel)} restored`,
          variant: "default",
        });
      },
    });

  const handleRestoreObject = () => {
    unarchiveObject({
      id,
      subjectType,
    });
  };

  return (
    <div
      className="flex cursor-pointer flex-row items-center space-x-1"
      onClick={handleRestoreObject}
    >
      <ArchiveRestore />
      <span>Restore</span>
    </div>
  );
};
