import { Heading } from "@radix-ui/themes";
import { AlertTriangle } from "lucide-react";
import { ReactFlowProvider } from "reactflow";

import WorkflowGraph from "./components/workflow/workflow-graph";
import useGetAssay from "./useGetAssay";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";
import WorkflowBuild from "../build/new-workflow/workflow-build";
import { WorkflowBuildProvider } from "../build/new-workflow/workflow-build-context";

export default function Workflow() {
  const { data: assay } = useGetAssay();

  if (!assay || !assay.workflow) {
    return null;
  }

  const workflow = assay.workflow;

  return (
    <div className="bg-card space-y-2 rounded-lg border p-4">
      <div className="flex flex-row justify-between">
        <Heading size="4">Workflow</Heading>
        <div className="flex flex-row items-center space-x-3">
          {workflow?.isAssayChanged && (
            <Tooltip>
              <TooltipTrigger>
                <p className="flex flex-row space-x-1 text-red-500">
                  <AlertTriangle />
                  <span>Assay has been changed</span>
                </p>
              </TooltipTrigger>
              <TooltipContent>
                The workflow was generated from a different set of constructs.
                <br />
                You need to generate the workflow again to proceed with the
                build.
              </TooltipContent>
            </Tooltip>
          )}
        </div>
      </div>
      <WorkflowBuildProvider assayId={assay.id}>
        <WorkflowBuild />
        {workflow && (
          <ReactFlowProvider>
            <WorkflowGraph workflow={workflow} />
          </ReactFlowProvider>
        )}
      </WorkflowBuildProvider>
    </div>
  );
}
