import { Text } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { useNavigate } from "react-router-dom";

import { useGetUsers } from "./hooks";

import { IsActive, IsAdmin } from "../../../components/table/basic";
import { DataTable } from "../../../components/ui/data-table/data-table";
import { TableActions } from "../../../components/ui/data-table/data-table-actions";
import { DataTableColumnHeader } from "../../../components/ui/data-table/data-table-column-header";
import EllipsedText from "../../../components/ui/tooltipText";
import { AccountStatus } from "../../../config/enums";
import type { AppRouter } from "../../../config/trpc";
import {
  DeactivateMFAButton,
  ForcePasswordChangeButton,
  ResetPasswordButton,
} from "../../settings/organization-settings/components/organization-settings-users-table";
import { AdminRoutes } from "../adminRoutes";
import { CheckIfTrueElseCross } from "../organizations/components/ui";

type UserDisplay = inferProcedureOutput<
  AppRouter["account"]["user"]["list"]
>[0];

function ActionsCell({ user }: { user: UserDisplay }) {
  return (
    <TableActions
      items={[
        {
          children: <DeactivateMFAButton userId={user.id} />,
          id: "Deactivate",
          skip: !user.mfaActivated,
        },
        {
          children: <ForcePasswordChangeButton userId={user.id} />,
          id: "Change password",
        },
        {
          children: <ResetPasswordButton email={user.email} />,
          id: "Reset password",
        },
      ]}
    />
  );
}

const columns: ColumnDef<UserDisplay>[] = [
  {
    accessorKey: "email",
    cell: ({ row }) => (
      <EllipsedText maxWidth="400px">{row.getValue("email")}</EllipsedText>
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Email",
    },
  },
  {
    accessorKey: "name",
    cell: ({ row }) => (
      <EllipsedText maxWidth="300px">{row.getValue("name")}</EllipsedText>
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Name",
    },
  },
  {
    accessorFn: (row) => row.status === AccountStatus.ACTIVE,
    cell: ({ row }) => (
      <IsActive isActive={row.original.status === AccountStatus.ACTIVE} />
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "isActive",
    meta: {
      booleanFilter: true,
      title: "Active ?",
    },
  },
  {
    cell: ({ row }) => (
      <CheckIfTrueElseCross value={row.original.mfaActivated} />
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "mfa",
    meta: {
      booleanFilter: true,
      title: "MFA",
    },
  },
  {
    accessorKey: "isAdmin",
    cell: ({ row }) => <IsAdmin isAdmin={row.getValue("isAdmin")} />,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      booleanFilter: true,
      title: "Admin ?",
    },
  },
  {
    accessorKey: "isSupport",
    cell: ({ row }) => <CheckIfTrueElseCross value={row.original.isSupport} />,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      booleanFilter: true,
      title: "Can support ?",
    },
  },
  {
    cell: ({ row }) => <ActionsCell user={row.original} />,
    enableHiding: false,
    id: "actions",
  },
];

export default function UserList() {
  const { data, isPending } = useGetUsers();

  const users = data ?? [];
  const navigate = useNavigate();

  if (isPending) {
    return <Text>Loading...</Text>;
  }

  return (
    <div className="space-y-2">
      <DataTable
        columns={columns}
        data={users}
        onRowClick={(row) =>
          navigate(AdminRoutes.USER.replace(":userId", row.original.id))
        }
      />
    </div>
  );
}
