import { useCallback, useEffect, useMemo, useState } from "react";

import type { WorkflowWell } from "./plate/types";
import { getRows } from "./useGetAllWellsById";

import type { PlateSize } from "../../../../../config/enums";

export const getRowColumnFromIndex = (index: string) => {
  const row = index.slice(0, 1);
  const column = index.slice(1);
  return { column, row };
};

const setNewIndex = (well: WorkflowWell, index: string): WorkflowWell => {
  const { row, column } = getRowColumnFromIndex(index);
  return {
    ...well,
    column,
    index,
    row,
  };
};

export const useEditWells = (initialWells: WorkflowWell[], size: PlateSize) => {
  const [index, setIndex] = useState(0);
  const [wellsHistory, setWellsHistory] = useState(() => [initialWells]);

  useEffect(() => {
    setWellsHistory([initialWells]);
    setIndex(0);
  }, [initialWells]);

  const currentWells = wellsHistory[index];
  const hasChanges =
    JSON.stringify(initialWells) !== JSON.stringify(currentWells);
  const wellsWithChanges = useMemo(
    () =>
      new Set(
        initialWells
          .filter(
            (w) =>
              JSON.stringify(w) !==
              JSON.stringify(currentWells.find((cw) => cw.index === w.index)),
          )
          .map((w) => w.index),
      ),
    [currentWells, initialWells],
  );
  const newWells = useMemo(
    () =>
      new Set(
        currentWells
          .filter((cw) => !initialWells.find((w) => w.index === cw.index))
          .map((w) => w.index),
      ),
    [currentWells, initialWells],
  );
  const deletedWells = useMemo(
    () =>
      new Set(
        initialWells
          .filter((w) => !currentWells.find((cw) => cw.index === w.index))
          .map((w) => w.index),
      ),
    [currentWells, initialWells],
  );

  const updateWellHistory = (newWells: WorkflowWell[]) => {
    setWellsHistory((old) => [...old.slice(0, index + 1), newWells]);
    setIndex(index + 1);
  };

  const handleChangeWell = (wellIndex: string, newWell: WorkflowWell) => {
    const newWells = changeWell(currentWells, wellIndex, newWell);
    updateWellHistory(newWells);
  };

  const changeWell = (
    currentWells: WorkflowWell[],
    wellIndex: string,
    newWell: WorkflowWell,
  ) => {
    const alreadyExists = currentWells.find((w) => w.index === wellIndex);
    return alreadyExists
      ? currentWells.map((well) => {
          if (well.index === wellIndex) {
            return newWell;
          }
          return well;
        })
      : [...currentWells, newWell];
  };

  const handleChangeWells = (updatedWells: WorkflowWell[]) => {
    const newWells = updatedWells.reduce(
      (currentNewWells, well) => changeWell(currentNewWells, well.index, well),
      currentWells,
    );
    updateWellHistory(newWells);
  };

  const handleDeleteWell = (wellIndex: string) => {
    const newWells = currentWells.filter((well) => well.index !== wellIndex);
    updateWellHistory(newWells);
  };

  const handleDeleteWells = (wellIndices: string[]) => {
    const newWells = currentWells.filter(
      (well) => !wellIndices.includes(well.index),
    );
    updateWellHistory(newWells);
  };

  const handleResetWell = (wellIndex: string) => {
    const filteredWells = currentWells.filter(
      (well) => well.index !== wellIndex,
    );
    const wellAlreadySaved = initialWells.find((w) => w.index === wellIndex);
    const newWells = wellAlreadySaved
      ? [...filteredWells, wellAlreadySaved]
      : filteredWells;
    updateWellHistory(newWells);
  };

  const moveWell = (
    currentWells: WorkflowWell[],
    originWellIndex: string,
    targetIndex: string,
  ) => {
    const filteredWells = currentWells.filter(
      (well) => well.index !== originWellIndex,
    );
    const wellToMove = currentWells.find((w) => w.index === originWellIndex);
    const targetWell = currentWells.find((w) => w.index === targetIndex);
    const movedWell = wellToMove ? setNewIndex(wellToMove, targetIndex) : null;
    const swappedWell = targetWell
      ? setNewIndex(targetWell, originWellIndex)
      : null;
    if (!swappedWell) {
      if (!movedWell) {
        return currentWells;
      }
      return [...filteredWells, movedWell];
    }
    const wellsWithoutOriginAndTarget = filteredWells.filter(
      (w) => w.index !== targetIndex,
    );
    if (!movedWell) {
      return [...wellsWithoutOriginAndTarget, swappedWell];
    }
    return [...wellsWithoutOriginAndTarget, swappedWell, movedWell];
  };

  const handleMoveWell = (originWellIndex: string, targetIndex: string) => {
    const newWells = moveWell(currentWells, originWellIndex, targetIndex);
    updateWellHistory(newWells);
  };

  const handleMoveColumn = (originColumn: string, targetColumn: string) => {
    const rows = getRows(size);
    const moves = rows.map((row) => {
      const originWellIndex = `${row}${originColumn}`;
      const targetIndex = `${row}${targetColumn}`;
      return { originWellIndex, targetIndex };
    });
    const newWells = moves.reduce((accNewWells, move) => {
      const newAccWells = moveWell(
        accNewWells,
        move.originWellIndex,
        move.targetIndex,
      );
      return newAccWells;
    }, currentWells);
    updateWellHistory(newWells);
  };

  const undo = useCallback(() => {
    if (index > 0) {
      setIndex((old) => old - 1);
    }
  }, [index]);

  const redo = useCallback(() => {
    if (index < wellsHistory.length - 1) {
      setIndex((old) => old + 1);
    }
  }, [index, wellsHistory.length]);

  useEffect(() => {
    const handleCmdZ = (e: KeyboardEvent) => {
      if (e.metaKey && e.key.toLowerCase() === "z") {
        if (e.shiftKey) {
          redo();
          return;
        }
        undo();
      }
    };

    document.addEventListener("keydown", handleCmdZ);
    return () => {
      document.removeEventListener("keydown", handleCmdZ);
    };
  }, [redo, undo]);

  const reset = () => {
    setWellsHistory([initialWells]);
    setIndex(0);
  };

  return {
    canRedo: index < wellsHistory.length - 1,
    canUndo: index > 0,
    deletedWells,
    handleChangeWell,
    handleChangeWells,
    handleDeleteWell,
    handleDeleteWells,
    handleMoveColumn,
    handleMoveWell,
    handleResetWell,
    hasChanges,
    newWells,
    redo,
    reset,
    undo,
    wells: currentWells,
    wellsWithChanges,
  };
};
