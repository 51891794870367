import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "../../../../components/ui/button";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
} from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import { PasswordInput } from "../../../../components/ui/password";
import { Switch } from "../../../../components/ui/switch";

const editUserFormSchema = z.object({
  email: z.string().trim().min(2).max(50).email(),
  firstname: z.string().trim().min(1).max(100),
  isActive: z.boolean(),
  isAdmin: z.boolean(),
  isSupport: z.boolean(),
  lastname: z.string().trim().min(1).max(100),
  password: z.string().trim().min(5).max(50).optional(),
});

export type EditUserFormType = z.infer<typeof editUserFormSchema>;

const DefaultFormValues = {
  email: "",
  firstname: "",
  isActive: true,
  isAdmin: false,
  isSupport: false,
  lastname: "",
};

export default function EditUserForm({
  onSubmit,
  onCancel,
  defaultValues,
  useActiveField = false,
}: {
  defaultValues?: Partial<EditUserFormType>;
  onCancel: () => void;
  onSubmit: (data: EditUserFormType) => void;
  useActiveField?: boolean;
}) {
  const [setPassword, setSetPassword] = useState(true);

  const form = useForm<EditUserFormType>({
    defaultValues: DefaultFormValues,
    resolver: zodResolver(editUserFormSchema),
  });

  useEffect(() => {
    form.reset({ ...DefaultFormValues, ...defaultValues });
  }, [defaultValues, form]);

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex flex-row justify-end space-x-2">
          <Button onClick={onCancel} variant={"secondary"}>
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </div>
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="john.smith@org.corp" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="firstname"
          render={({ field }) => (
            <FormItem>
              <FormLabel>First name</FormLabel>
              <FormControl>
                <Input placeholder="John" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lastname"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Last name</FormLabel>
              <FormControl>
                <Input placeholder="Smith" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="space-y-2">
          <FormItem className="flex flex-row items-center space-x-2">
            <Switch
              checked={setPassword}
              onCheckedChange={(checked) => {
                setSetPassword(checked);
                if (!checked) {
                  form.setValue("password", undefined);
                }
              }}
            />
            <div>
              <FormLabel>Set password</FormLabel>
              <FormDescription>
                Do you want to set a password, or should we send him a
                notification to register ? He will have to change his password
                on the first login.
              </FormDescription>
            </div>
          </FormItem>
          {setPassword && (
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <PasswordInput
                      placeholder="Password"
                      type="password"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
        {useActiveField && (
          <FormField
            control={form.control}
            name="isActive"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center space-x-2">
                <FormControl className="mt-0">
                  <Switch
                    checked={field.value}
                    className="mt-0"
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div>
                  <FormLabel>Active ?</FormLabel>
                  <FormDescription>
                    Activate or deactivate the user
                  </FormDescription>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        <FormField
          control={form.control}
          name="isAdmin"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center space-x-2">
              <FormControl className="mt-0">
                <Switch
                  checked={field.value}
                  className="mt-0"
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div>
                <FormLabel>Is admin</FormLabel>
                <FormDescription>
                  Makes the user an admin of the environment, i.e. gives him
                  authorization to add users, create organizations, etc...
                </FormDescription>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="isSupport"
          render={({ field }) => (
            <FormItem className="flex flex-row items-center space-x-2">
              <FormControl className="mt-0">
                <Switch
                  checked={field.value}
                  className="mt-0"
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <div>
                <FormLabel>Is support</FormLabel>
                <FormDescription>
                  Allows the user to be added in the support group of an
                  organization.
                </FormDescription>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
}
