import { Heading } from "@radix-ui/themes";
import { AlertTriangle, CopyIcon, EditIcon } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

import { AssayConstructs } from "./components/assay-constructs";
import AssayDetails from "./components/assay-details";
import AssayLoadConstruct from "./components/assay-load-construct";
import useGetAssay from "./useGetAssay";
import Workflow from "./workflow";

import { Button } from "../../../../components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";
import { AuthorizationSubject } from "../../../../config/enums";
import { trpc } from "../../../../config/trpc";
import { useGetUIAuthorizedCreations } from "../../../../containers/user/hooks";
import OwnersBlock from "../../../instrument/components/owners-block";
import { OrganizationRoutes } from "../../organization-routes";

export default function Assay() {
  const { assayId } = useParams();
  const navigate = useNavigate();
  const { data: assay, isPending: loading } = useGetAssay();
  const { assay: canCreateAssay } = useGetUIAuthorizedCreations();

  const handleAddConstruct = () => {
    navigate(OrganizationRoutes.CONSTRUCT_NEW, {
      state: { assayId },
    });
  };

  const { mutate: duplicateAssay } = trpc.assay.duplicate.useMutation({
    onSuccess(createdAssayId) {
      navigate(OrganizationRoutes.ASSAY.replace(":assayId", createdAssayId));
    },
  });

  const handleDuplicateAssay = () => {
    if (!assay?.id) {
      return;
    }
    duplicateAssay(assay.id);
  };

  const { data: owners } = trpc.account.authorization.owners.useQuery({
    id: assayId ?? "",
    subjectType: AuthorizationSubject.Assay,
  });

  if (loading) {
    return null;
  }

  if (!assay || !assayId) {
    return <Heading>Not found</Heading>;
  }

  const { name, archived, id } = assay;

  return (
    <div className="space-y-4">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row space-x-4">
          <Heading>{name}</Heading>
          {archived && (
            <p className="flex flex-row space-x-1 text-red-500">
              <AlertTriangle />
              <span>Assay is archived</span>
            </p>
          )}
        </div>

        <div className="flex flex-row space-x-2">
          <OwnersBlock
            objectId={id}
            owners={owners}
            subject={AuthorizationSubject.Assay}
          />
          <Button
            disabled={!canCreateAssay || archived}
            onClick={handleDuplicateAssay}
            variant={"outline"}
          >
            <div className="flex flex-row items-center space-x-1">
              <span>Duplicate</span>
              <CopyIcon />
            </div>
          </Button>
        </div>
      </div>
      <AssayDetails assay={assay} />
      <div className="bg-card space-y-2 rounded-lg border p-4">
        <div className="flex flex-row justify-between">
          <Heading size="4">Constructs</Heading>
          <div className="space-x-2">
            <AssayLoadConstruct assayId={id} />
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  aria-label="Create construct in assay"
                  className="h-11 w-11 p-0"
                  disabled={archived}
                  onClick={handleAddConstruct}
                  variant={"outline"}
                >
                  <EditIcon />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Create construct</TooltipContent>
            </Tooltip>
          </div>
        </div>
        <AssayConstructs assayId={assayId} />
      </div>
      <Workflow />
    </div>
  );
}
