import { Info } from "lucide-react";
import { useSearchParams } from "react-router-dom";

import type { Display } from "./plate/display";
import { DISPLAYS, DISPLAY_PROPERTIES } from "./plate/display";
import type { PlateKit } from "./plate/types";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";

export const useDisplaySelector = (
  hasResults: boolean,
  hasModifiers: boolean,
  kitProperties: PlateKit,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const display = (searchParams.get("display") || "length") as Display;

  function handleSelectDisplay(newDisplay: string) {
    setSearchParams({ display: newDisplay });
  }

  const displays = DISPLAYS.filter(
    (d) => hasResults || !DISPLAY_PROPERTIES[d].isResult,
  ).filter((d) => hasModifiers || d !== "modification");
  const plateDisplays = displays.filter(
    (d) => !DISPLAY_PROPERTIES[d].isResult,
  ) as Display[];
  const resultDisplays = displays.filter(
    (d) => DISPLAY_PROPERTIES[d].isResult,
  ) as Display[];

  const { Legend, unit } = DISPLAY_PROPERTIES[display];

  const node = (
    <div className="flex flex-row space-x-1">
      <Select onValueChange={handleSelectDisplay} value={display}>
        <SelectTrigger className="bg-card h-[32px] w-[150px]">
          <SelectValue placeholder="Theme" />
        </SelectTrigger>
        <SelectContent>
          {plateDisplays.map((d) => (
            <SelectItem className="cursor-pointer" key={d} value={d}>
              {DISPLAY_PROPERTIES[d].label}
            </SelectItem>
          ))}
          {hasResults && (
            <>
              <SelectSeparator />
              {resultDisplays.map((d) => (
                <SelectItem className="cursor-pointer" key={d} value={d}>
                  {DISPLAY_PROPERTIES[d].label}
                </SelectItem>
              ))}
            </>
          )}
        </SelectContent>
        <Tooltip delayDuration={0}>
          <TooltipTrigger className="flex flex-row space-x-1">
            <Info size={16} />
          </TooltipTrigger>
          <TooltipContent>
            <Legend kitProperties={kitProperties} />
          </TooltipContent>
        </Tooltip>
      </Select>
    </div>
  );

  const legend = (
    <div className="relative">
      <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
        {unit}
      </p>
    </div>
  );
  return { display, legend, node };
};
