import { useMutation } from "@apollo/client";
import { Download } from "lucide-react";
import { Link } from "react-router-dom";

import type { PlateFromWorkflow, PlateRun } from "./plate/types";

import { RunState } from "../../../../../__generated__/graphql";
import { KitLabel } from "../../../../../components/logic/kit-label";
import { Button } from "../../../../../components/ui/button";
import { Separator } from "../../../../../components/ui/separator";
import { DOWNLOAD_PRINT_JOB } from "../../../../../gql/run";
import { getSequenceLength } from "../../../../../utils/parser";
import { useDownloadFile } from "../../../../../utils/useDownload";
import { OrganizationRoutes } from "../../../organization-routes";

function getDurationStr(progress: NonNullable<PlateRun["progress"]>): string {
  const totalDurationSec = progress.phases
    .map((p) => p.estimatedRemainingTime.seconds)
    .reduce((partialSum, s) => partialSum + s, 0);
  const hours = Math.floor(totalDurationSec / 3600);
  const mins = Math.round((totalDurationSec - hours * 3600) / 60);
  return (hours > 0 ? `${hours}h ` : "") + (mins > 0 ? `${mins}m` : "");
}

function RunDescription({ run }: { run: PlateRun | null }) {
  const downloadFile = useDownloadFile();
  const [downloadPrintJob] = useMutation(DOWNLOAD_PRINT_JOB, {
    onCompleted: (data) => {
      downloadFile(data.downloadPrintJob, "print-job.json");
    },
  });

  if (!run) {
    return null;
  }

  const isQueuedOrLoaded = [RunState.Loaded, RunState.Queued].includes(
    run.state,
  );

  return (
    <>
      <Separator />
      <div className="flew-row flex items-center justify-between space-x-4">
        <h3 className="font-bold">Run</h3>
        <div>
          <p className="italic">Instrument</p>
          <Link
            className="text-blue-500 hover:underline"
            to={OrganizationRoutes.INSTRUMENT.replace(
              ":instrumentId",
              run.instrumentId,
            )}
          >
            Go to instrument
          </Link>
        </div>
        <div>
          <p className="italic">Creator</p>
          <p>{run.createdByName}</p>
        </div>
        <div>
          <p className="italic">Run id</p>
          <p>{run.id}</p>
        </div>
        <div>
          <p className="italic">Sent date</p>
          <p>{new Date(run.createdAt).toLocaleString()}</p>
        </div>
        {isQueuedOrLoaded && (
          <div>
            <p className="italic">Estimated total time</p>
            <p>{run.progress ? getDurationStr(run.progress) : "-"}</p>
          </div>
        )}
        <div>
          <p className="italic">Download print job</p>
          <Button
            onClick={() => {
              downloadPrintJob({ variables: { id: run.id } });
            }}
            variant={"outline"}
          >
            <Download />
          </Button>
        </div>
      </div>
    </>
  );
}

export default function PlateDescription({
  plate,
}: {
  plate: PlateFromWorkflow;
}) {
  const wells = plate.wells;
  const wellLengths = wells.map((well) => getSequenceLength(well.sequence));
  return (
    <div className="ml-4 space-y-2">
      <div className="flew-row flex items-center justify-between space-x-1">
        <h3 className="font-bold">Plate</h3>
        <KitLabel kitId={plate.kit} />
        <Separator className="h-6" orientation="vertical" />
        <div>
          <p className="italic">Valid / Filled wells</p>
          <p>
            <span>
              {plate.wells.filter((w) => w.errors.length === 0).length}
            </span>
            <span> / </span>
            <span>{plate.wells.length}</span>
          </p>
        </div>
        <Separator className="h-6" orientation="vertical" />
        <div>
          <p>Sizes</p>
          <p>{`${Math.min(...wellLengths)}-${Math.max(...wellLengths)}`}</p>
        </div>
        <Separator className="h-6" orientation="vertical" />
        <div>
          <p className="italic">Cycles</p>
          <p>{plate.cycles}</p>
        </div>
      </div>
      <RunDescription run={plate.run} />
    </div>
  );
}
