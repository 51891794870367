import type { ColumnDef } from "@tanstack/react-table";
import { getQueryKey } from "@trpc/react-query";
import type { inferProcedureOutput } from "@trpc/server";
import { Trash2 } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { CONSTRUCT_TYPE_TO_LABEL } from "./constants";

import { AuthorizationSubject } from "../../../../../__generated__/graphql";
import { TagsDisplay } from "../../../../../components/logic/tags";
import {
  ArchiveButton,
  RestoreButton,
} from "../../../../../components/table/archive";
import { AlertDialogWrapper } from "../../../../../components/ui/alert-dialog";
import { DataTable } from "../../../../../components/ui/data-table/data-table";
import { TableActions } from "../../../../../components/ui/data-table/data-table-actions";
import { DataTableColumnHeader } from "../../../../../components/ui/data-table/data-table-column-header";
import { arrIncludesSomeWithEmptyFn } from "../../../../../components/ui/data-table/filters";
import EllipsedText from "../../../../../components/ui/tooltipText";
import { useToast } from "../../../../../components/ui/use-toast";
import { ConstructType } from "../../../../../config/enums";
import type { AppRouter } from "../../../../../config/trpc";
import { queryClient, trpc } from "../../../../../config/trpc";
import { CheckIfTrueElseCross } from "../../../../admin/organizations/components/ui";
import { OrganizationRoutes } from "../../../organization-routes";

type ConstructFromQuery = inferProcedureOutput<
  AppRouter["construct"]["list"]
>[0];

const ConstructDetails = ({ row }: { row: ConstructFromQuery }) => {
  if (row.constructType === ConstructType.OligoSet) {
    return (
      <div>
        <div className="flex flex-row space-x-1">
          <p>Oligos:</p>
          <p>{row.oligoCount}</p>
        </div>
        <div className="flex flex-row space-x-1">
          <p>Size range:</p>
          <p>{`${row.minSize}-${row.maxSize}`}</p>
        </div>
      </div>
    );
  }
  if (row.constructType === ConstructType.Gene) {
    return (
      <div>
        <div className="flex flex-row space-x-1">
          <p>Length:</p>
          <p>{row.gene?.length}</p>
        </div>
      </div>
    );
  }
  return null;
};

const DeleteConstruct = ({ id }: { id: string }) => {
  const { toast } = useToast();
  const { mutate: deleteConstruct } = trpc.construct.delete.useMutation({
    onError(error) {
      toast({
        description: error.message,
        title: "Error",
        variant: "destructive",
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(trpc.construct.list),
      });
      toast({
        title: "Construct removed",
        variant: "default",
      });
    },
  });

  const handleDeleteConstruct = () => {
    deleteConstruct({
      constructId: id,
    });
  };

  return (
    <AlertDialogWrapper
      description="Are you sure you want to delete this construct ? This action cannot be undone."
      onConfirm={handleDeleteConstruct}
      title="Delete construct"
    >
      <div className="flex flex-row items-center space-x-1">
        <Trash2 />
        <span>Delete</span>
      </div>
    </AlertDialogWrapper>
  );
};

const columns: ColumnDef<ConstructFromQuery>[] = [
  {
    accessorKey: "name",
    cell: ({ row }) => (
      <EllipsedText maxWidth="200px">{row.original.name}</EllipsedText>
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Name",
    },
  },
  {
    accessorKey: "constructType",
    cell: ({ row }) => CONSTRUCT_TYPE_TO_LABEL[row.original.constructType],
    filterFn: arrIncludesSomeWithEmptyFn,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Type",
      uniqueFilter: true,
    },
  },
  {
    accessorKey: "updatedAt",
    cell: ({ row }) => new Date(row.original.updatedAt).toLocaleString(),
    enableColumnFilter: false,
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "updatedAt",
    meta: {
      title: "Last update",
    },
    sortingFn: "datetime",
  },
  {
    accessorKey: "creator.name",
    cell: (info) => info.getValue(),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    meta: {
      title: "Owner",
    },
  },
  {
    accessorFn: (row) => !row.isArchived,
    cell: ({ row }) => (
      <CheckIfTrueElseCross value={!row.original.isArchived} />
    ),
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "active",
    meta: {
      booleanFilter: true,
      defaultFilter: true,
      title: "Active",
    },
  },
  {
    cell: ({ row }) => <ConstructDetails row={row.original} />,
    enableColumnFilter: false,
    header: "Details",
    id: "details",
  },
  {
    accessorFn: (row) => row.tags,
    cell: ({ row }) => <TagsDisplay tags={row.original.tags} />,
    filterFn: "arrIncludesSome",
    header: ({ column, table }) => (
      <DataTableColumnHeader column={column} table={table} />
    ),
    id: "tags",
    meta: {
      title: "Tags",
    },
  },
  {
    cell: ({ row: { original } }) => {
      const actionsDisabled = !original.isEditable;
      const archived = original.isArchived;
      return (
        <TableActions
          items={[
            {
              children: (
                <RestoreButton
                  id={original.id}
                  subjectType={AuthorizationSubject.Construct}
                />
              ),
              disabled: actionsDisabled,
              id: "restore",
              skip: !archived,
            },
            {
              children: <DeleteConstruct id={original.id} />,
              disabled: actionsDisabled,
              id: "delete",
              onClick: (e) => e?.preventDefault(),
              skip: !archived,
            },
            {
              children: (
                <ArchiveButton
                  id={original.id}
                  subjectType={AuthorizationSubject.Construct}
                />
              ),
              disabled: actionsDisabled,
              id: "archive",
              onClick: (e) => e?.preventDefault(),
              skip: archived,
            },
          ]}
        />
      );
    },
    enableHiding: false,
    id: "actions",
  },
];

export default function Constructs() {
  const { data: constructs, isPending } = trpc.construct.list.useQuery();

  const navigate = useNavigate();

  return (
    <div className="space-y-2 ">
      <DataTable
        columns={columns}
        data={constructs ?? []}
        defaultSorting={[{ desc: true, id: "updatedAt" }]}
        loading={isPending}
        onRowClick={(row) =>
          navigate(
            OrganizationRoutes.CONSTRUCT.replace(
              ":constructId",
              row.original.id,
            ),
          )
        }
      />
    </div>
  );
}
