import { graphql } from "../__generated__/gql";

export const AUTHORIZATION_FIELDS = graphql(/* GraphQL */ `
  fragment AuthorizationFields on Authorization {
    instrument
    manager
    developer
    designer
    builder
  }

  fragment AuthorizedCreationFields on AuthorizedCreations {
    assay
    construct
    hamiltonInstrument
    instrument
    organization
    organizationMember
    plate
    team
    user
    workflow
  }
`);

export const MEMBERS_ORGANIZATION_FIELDS = graphql(/* GraphQL */ `
  fragment MemberFields on OrganizationMember {
    id
    authorizations {
      ...AuthorizationFields
    }
    globalAuthorizations {
      ...AuthorizationFields
    }
    authorizedCreations {
      ...AuthorizedCreationFields
    }
    apiKeys {
      id
    }
    createdAt
    status
    organizationId
    updatedAt
    isSupport
    userId
    user {
      firstname
      lastname
      name
      email
      mfaActivated
    }
    memberships {
      createdAt
      team {
        authorizations {
          ...AuthorizationFields
        }
        id
        name
      }
    }
  }
`);

export const ACTIVATE_ORGANIZATION_MEMBER = graphql(/* GraphQL */ `
  mutation ActivateOrganizationMember($id: ID!) {
    activateOrganizationMember(id: $id)
  }
`);

export const DEACTIVATE_ORGANIZATION_MEMBER = graphql(/* GraphQL */ `
  mutation DeactivateOrganizationMember($id: ID!) {
    deactivateOrganizationMember(id: $id)
  }
`);

export const INVITE_ORGANIZATION_MEMBER = graphql(/* GraphQL */ `
  mutation InviteOrganizationMember(
    $payload: InviteOrganizationMemberPayload!
  ) {
    inviteOrganizationMember(payload: $payload)
  }
`);

export const UPDATE_ORGANIZATION_MEMBER_PERMISSIONS = graphql(/* GraphQL */ `
  mutation UpdateOrganizationMemberPermissions(
    $id: ID!
    $permissions: AuthorizationInput!
  ) {
    updateOrganizationMemberPermissions(id: $id, permissions: $permissions)
  }
`);

export const GET_MEMBER = graphql(/* GraphQL */ `
  query Member($id: ID!) {
    member(id: $id) {
      ...MemberFields
    }
  }
`);

export const ACTIVATE_SUPPORT_MEMBER = graphql(/* GraphQL */ `
  mutation ActivateSupportMember($userId: ID!) {
    activateSupportMember(userId: $userId)
  }
`);
