import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Text, Heading } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { DEFAULT_AUTHORISATIONS, authorizationForm } from "./constants";
import { useCanInviteUser } from "./hooks";
import { OrganizationSettingsRoutes } from "./organization-settings-routes";

import type { AuthorizationValue } from "../../../__generated__/graphql";
import { AuthorizationsEditWithChange } from "../../../components/logic/authorizations";
import { Button } from "../../../components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { PasswordInput } from "../../../components/ui/password";
import { Switch } from "../../../components/ui/switch";
import { useToast } from "../../../components/ui/use-toast";
import { INVITE_ORGANIZATION_MEMBER } from "../../../gql/organization-member";

const newOrganizationMemberForm = z.object({
  email: z.string().trim().email(),
  firstname: z.string().trim().min(1).max(100),
  lastname: z.string().trim().min(1).max(100),
  password: z.string().trim().min(8).max(50).optional(),
  permissions: authorizationForm,
});

type NewOrganizationMemberForm = z.infer<typeof newOrganizationMemberForm>;

export default function NewOrganizationMember() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [setPassword, setSetPassword] = useState(true);
  const canInviteUser = useCanInviteUser();
  const form = useForm<NewOrganizationMemberForm>({
    defaultValues: {
      email: "",
      firstname: "",
      lastname: "",
      permissions: DEFAULT_AUTHORISATIONS,
    },
    resolver: zodResolver(newOrganizationMemberForm),
  });

  useEffect(() => {
    if (!canInviteUser) {
      toast({
        description: "You are not allowed to invite a new user",
        title: "Error",
        variant: "destructive",
      });
      navigate(OrganizationSettingsRoutes.USERS);
    }
  }, [canInviteUser, navigate, toast]);

  const [inviteOrganizationMember] = useMutation(INVITE_ORGANIZATION_MEMBER, {
    onCompleted: () => {
      navigate(OrganizationSettingsRoutes.USERS);
    },
    onError: (error) => {
      toast({
        description: error.message,
        title: "Error",
        variant: "destructive",
      });
    },
    refetchQueries: ["Organization"],
  });

  function onSubmit(values: NewOrganizationMemberForm) {
    inviteOrganizationMember({
      variables: {
        payload: {
          email: values.email,
          firstname: values.firstname,
          lastname: values.lastname,
          password: values.password,
          permissions: values.permissions,
        },
      },
    });
  }

  const currentAuthorizations = form.watch("permissions");
  const handleChangeAuthorizations = (values: {
    builder: AuthorizationValue;
    designer: AuthorizationValue;
    developer: boolean;
    instrument: AuthorizationValue;
    manager: AuthorizationValue;
  }) => {
    if (JSON.stringify(currentAuthorizations) === JSON.stringify(values)) {
      return;
    }
    form.setValue("permissions", values);
  };

  return (
    <div className="space-y-4">
      <Heading>New user</Heading>
      <Form {...form}>
        <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="bg-card rounded-lg border p-4">
            <Text size="4">Details</Text>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="john.smith@corp.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="firstname"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Firstname</FormLabel>
                  <FormControl>
                    <Input placeholder="John" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastname"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Lastname</FormLabel>
                  <FormControl>
                    <Input placeholder="Smith" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="space-y-2">
              <FormItem className="flex flex-row items-center space-x-2">
                <Switch
                  checked={setPassword}
                  onCheckedChange={(checked) => {
                    setSetPassword(checked);
                    if (!checked) {
                      form.setValue("password", undefined);
                    }
                  }}
                />
                <div>
                  <FormLabel>Set password</FormLabel>
                  <FormDescription>
                    Do you want to set a password, or should we send him a
                    notification to register ? He will have to change his
                    password on the first login.
                  </FormDescription>
                </div>
              </FormItem>
              {setPassword && (
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Password</FormLabel>
                      <FormControl>
                        <PasswordInput
                          placeholder="Password"
                          type="password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
          </div>
          <div className="bg-card space-y-2 rounded-lg border p-4">
            <Text size="4">Permissions</Text>
            <AuthorizationsEditWithChange
              defaultAuthorizations={currentAuthorizations}
              handleChange={handleChangeAuthorizations}
            />
          </div>
          <div className="flex flex-row space-x-2">
            <Button type="submit">Submit</Button>
            <Button
              onClick={() => navigate(OrganizationSettingsRoutes.USERS)}
              variant={"secondary"}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
