import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Heading } from "@radix-ui/themes";
import { SaveIcon, UndoIcon } from "lucide-react";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { useGetInstrument } from "./useGetInstrument";

import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Switch } from "../../components/ui/switch";
import { useToast } from "../../components/ui/use-toast";
import { GET_INSTRUMENT, UPDATE_INSTRUMENT } from "../../gql/instrument";
import { OrganizationRoutes } from "../organization/organization-routes";
import { useGetMyOrganization } from "../settings/organization-settings/hooks";

const editInstrumentForm = z.object({
  canBeTriggeredExternally: z.boolean(),
  name: z.string().trim().min(1),
  serialNumber: z.string().trim().min(1),
});

type EditInstrumentForm = z.infer<typeof editInstrumentForm>;

export default function InstrumentEdit() {
  const navigate = useNavigate();
  const { data, loading } = useGetInstrument();
  const { toast } = useToast();
  const [updateInstrument] = useMutation(UPDATE_INSTRUMENT, {
    onCompleted: () => {
      navigate(
        OrganizationRoutes.INSTRUMENT.replace(
          ":instrumentId",
          data?.getInstrument.id ?? "",
        ),
      );
    },
    onError: (error) => {
      toast({
        description: error.message,
        title: "Error",
        variant: "destructive",
      });
    },
    refetchQueries: [
      {
        query: GET_INSTRUMENT,
        variables: {
          id: data?.getInstrument.id ?? "",
        },
      },
    ],
  });

  const organizationData = useGetMyOrganization();
  const hasTriggerFromOutsideOption = Boolean(
    organizationData?.configuration.remoteRunTriggerEnabled,
  );

  const defaultValues = useMemo(
    () =>
      ({
        canBeTriggeredExternally:
          data?.getInstrument.canBeTriggeredExternally ?? false,
        name: data?.getInstrument.name ?? "",
        serialNumber: data?.getInstrument.serialNumber ?? "",
      }) as EditInstrumentForm,
    [data],
  );
  const form = useForm<EditInstrumentForm>({
    defaultValues: {},
    resolver: zodResolver(editInstrumentForm),
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  if (loading) return <Heading>Loading...</Heading>;

  if (!data) {
    return <Heading>Instrument not found</Heading>;
  }
  const instrument = data.getInstrument;

  const onSubmit = (data: EditInstrumentForm) => {
    updateInstrument({
      variables: {
        id: instrument.id,
        payload: {
          canBeTriggeredExternally: data.canBeTriggeredExternally,
          name: data.name,
          serialNumber: data.serialNumber,
        },
      },
    });
  };

  return (
    <Form {...form}>
      <form
        className="flex flex-col space-y-8"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="flex flex-row justify-between">
          <Heading className="space-x-2">
            <span>Instrument</span>
            <span className="italic">{instrument.name}</span>
          </Heading>
          <div className="space-x-2">
            <Button
              onClick={() => {
                navigate(
                  OrganizationRoutes.INSTRUMENT.replace(
                    ":instrumentId",
                    instrument.id,
                  ),
                );
              }}
              variant={"outline"}
            >
              <UndoIcon />
            </Button>
            <Button type="submit">
              <SaveIcon />
            </Button>
          </div>
        </div>
        <FormField
          control={form.control}
          name={"name"}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl className="mt-0">
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name={"serialNumber"}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Serial number</FormLabel>
              <FormControl className="mt-0">
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {hasTriggerFromOutsideOption && (
          <FormField
            control={form.control}
            name="canBeTriggeredExternally"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center space-x-2">
                <FormControl className="mt-0">
                  <Switch
                    checked={field.value}
                    className="mt-0"
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div>
                  <FormLabel>Trigger from outside</FormLabel>
                  <FormDescription>
                    Instrument runs can be triggered from console or the API
                  </FormDescription>
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
      </form>
    </Form>
  );
}
