import type { RowData } from "@tanstack/react-table";
import {
  AlertCircle,
  Download,
  PlusSquareIcon,
  Trash2Icon,
  Upload,
} from "lucide-react";
import { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { fromZodError } from "zod-validation-error";

import type { Oligo } from "./types";
import { getOligoColumns } from "./useGetOligoColumns";
import { useScrollRowAndFocusName } from "./useScrollRowAndFocusName";

import { Badge } from "../../../../../components/ui/badge";
import { Button } from "../../../../../components/ui/button";
import { DataTable } from "../../../../../components/ui/data-table/data-table";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import { useToast } from "../../../../../components/ui/use-toast";
import { parseSequencesFile } from "../../../../../utils/parser";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    deleteData?: (rowIndex: number) => void;
    placementToRows?: Map<string, number[]>;
    updateData?: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

type OligoSetProps = {
  data: Oligo[];
  isLocked?: boolean;
  setData: React.Dispatch<React.SetStateAction<Oligo[]>>;
};

export default function OligoSet({
  isLocked = false,
  data,
  setData,
}: OligoSetProps) {
  const { toast } = useToast();

  useScrollRowAndFocusName(data);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const parseFiles = async (acceptedFiles: File[]) => {
        for (const file of acceptedFiles) {
          try {
            const parsedFile = await parseSequencesFile(file);
            if (!parsedFile.success || parsedFile.data.length === 0) {
              toast({
                description: parsedFile.data
                  ? "Could not read any rows"
                  : fromZodError(parsedFile.error).message,
                title: "Error parsing file",
                variant: "destructive",
              });
            } else {
              setData((old) => [
                ...old,
                ...parsedFile.data.map((row) => {
                  return {
                    locked: false,
                    name: row.Name,
                    sequence: row.Sequence,
                    wellHint: row.Well || null,
                  };
                }),
              ]);
            }
          } catch {
            toast({
              description: "Error parsing file",
              title: "Error",
              variant: "destructive",
            });
          }
        }
      };
      parseFiles(acceptedFiles);
    },
    [setData, toast],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "text/csv": [".csv"],
      "text/plain": [".txt"],
      "text/x-fasta": [".fasta"],
    },
    noDrag: false,
    onDrop,
    useFsAccessApi: false,
  });

  const addRow = () => {
    setData((old) => [
      ...old,
      { locked: false, name: "", sequence: "", wellHint: null },
    ]);
  };

  const deleteAll = () => {
    setData([]);
  };

  const containsInitiatorDNA = useMemo(
    () => data.some((oligo) => oligo.sequence.split("+").length > 1),
    [data],
  );
  const columns = useMemo(
    () => getOligoColumns(containsInitiatorDNA),
    [containsInitiatorDNA],
  );

  const placementToRows = useMemo(() => {
    const mapOfPlacements = new Map<string, number[]>();
    data.forEach((oligo, oligoIndex) => {
      const oligoHint = oligo.wellHint;
      if (!oligoHint) {
        return;
      }
      mapOfPlacements.set(
        oligoHint,
        (mapOfPlacements.get(oligoHint) || [])?.concat([oligoIndex]),
      );
    });
    return mapOfPlacements;
  }, [data]);

  const notAllOligosDispatched = false; // TODO: CON-96

  return (
    <div className="space-y-4 [&_td]:p-1">
      <div className="flex flex-row items-center justify-end space-x-4">
        {notAllOligosDispatched && (
          <div>
            <Tooltip>
              <TooltipTrigger>
                <Badge
                  className="hover:bg-destructive/80"
                  variant={"destructive"}
                >
                  <AlertCircle size={20} />
                </Badge>
              </TooltipTrigger>
              <TooltipContent>
                Some oligos need to be assigned to a plate
              </TooltipContent>
            </Tooltip>
          </div>
        )}
        <div {...getRootProps()}>
          <input {...getInputProps()} data-testid={"drop-oligos"} />
          <Button
            className="space-x-2"
            disabled={isLocked}
            variant={"secondary"}
          >
            <span>Upload file(s)</span>
            <Upload />
          </Button>
        </div>
        <div className="bg-card flex flex-row items-center space-x-2 rounded-lg border px-2">
          <p className="text-sm">Samples</p>
          <a
            className="cursor-pointer"
            download="template.fasta"
            href={"/fastaTemplate.fasta"}
            rel="noreferrer"
            target="_blank"
          >
            <Button className="space-x-1" variant={"ghost"}>
              <span>Fasta</span>
              <Download />
            </Button>
          </a>
          <a
            className="cursor-pointer"
            download="template.csv"
            href={"/csvTemplate.csv"}
            rel="noreferrer"
            target="_blank"
          >
            <Button className="space-x-1" variant={"ghost"}>
              <span>CSV</span>
              <Download />
            </Button>
          </a>
          <a
            className="cursor-pointer"
            download="template.xlsx"
            href={"/excelTemplate.xlsx"}
            rel="noreferrer"
            target="_blank"
          >
            <Button className="space-x-1" variant={"ghost"}>
              <span>XLS</span>
              <Download />
            </Button>
          </a>
        </div>
      </div>
      <DataTable
        columns={columns}
        data={data}
        meta={{
          deleteData: (rowIndex: number) => {
            // Skip page index reset until after next rerender
            setData((old) => old.filter((_, index) => index !== rowIndex));
          },
          isOligoSet: true,
          placementToRows,
          updateData: (rowIndex: number, columnId: string, value: any) => {
            // Skip page index reset until after next rerender
            setData((old) =>
              old.map((row, index) => {
                if (index === rowIndex) {
                  return {
                    ...old[rowIndex]!,
                    [columnId]: value,
                  };
                }
                return row;
              }),
            );
          },
        }}
      />
      <div className="flex flex-row items-center space-x-2">
        <Button
          className="space-x-2"
          disabled={isLocked}
          onClick={addRow}
          type="button"
          variant={"outline"}
        >
          <span>New oligo</span>
          <PlusSquareIcon />
        </Button>
        <Button
          className="space-x-2"
          disabled={isLocked}
          onClick={deleteAll}
          type="button"
          variant={"outline"}
        >
          <span>Delete all</span>
          <Trash2Icon />
        </Button>
      </div>
    </div>
  );
}
