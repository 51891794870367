import { useMutation } from "@apollo/client";
import { Download } from "lucide-react";

import { useGetMyOrganization } from "./hooks";

import DateRangePicker from "../../../components/logic/date-range-picker";
import { Button } from "../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { useToast } from "../../../components/ui/use-toast";
import { AUDIT_EVENTS } from "../../../gql/audit";
import { useDownloadFile } from "../../../utils/useDownload";

export default function OrganizationAdmin() {
  const organization = useGetMyOrganization();
  const downloadFile = useDownloadFile();
  const { toast } = useToast();

  const [auditEvents, { loading }] = useMutation(AUDIT_EVENTS, {
    onCompleted: async (data) => {
      const url = data.auditEvents;
      try {
        await downloadFile(url, "audit.csv");
      } catch (error) {
        const message =
          "message" in (error as any) ? (error as any).message : "";
        toast({
          description: message,
          title: "Error when downloading audit logs",
          variant: "destructive",
        });
      }
    },
    onError: (error) => {
      toast({
        description: error.message,
        title: "Error",
        variant: "destructive",
      });
    },
  });

  const handleAuditEvents = (data: { range: { end?: Date; start?: Date } }) => {
    if (!organization) {
      return;
    }
    const tzOffset = new Date().getTimezoneOffset() * 60000;
    const startDate = data.range.start
      ? new Date(data.range.start?.getTime() - tzOffset).toISOString()
      : undefined;
    const endDate = data.range.end
      ? new Date(data.range.end?.getTime() - tzOffset).toISOString()
      : undefined;

    auditEvents({
      variables: {
        endDate,
        organizationId: organization?.id,
        startDate,
      },
    });
  };

  return (
    <Card className="col-span-1">
      <CardHeader>
        <CardTitle>Admin</CardTitle>
      </CardHeader>
      <CardContent>
        <DateRangePicker onSubmit={handleAuditEvents}>
          <Button
            className="flex flex-row items-center space-x-1"
            disabled={loading}
            type="submit"
            variant={"secondary"}
          >
            <span>Download events</span>
            <Download />
          </Button>
        </DateRangePicker>
      </CardContent>
    </Card>
  );
}
