import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import { GET_HAMILTON_INSTRUMENT, GET_INSTRUMENT } from "../../gql/instrument";

export const useGetInstrument = () => {
  const { instrumentId } = useParams();

  return useQuery(GET_INSTRUMENT, {
    skip: !instrumentId,
    variables: {
      id: instrumentId ?? "",
    },
  });
};

export const useGetHamiltonInstrument = () => {
  const { instrumentId } = useParams();

  return useQuery(GET_HAMILTON_INSTRUMENT, {
    fetchPolicy: "cache-and-network",
    skip: !instrumentId,
    variables: {
      id: instrumentId ?? "",
    },
  });
};
