import { Pencil, SaveIcon, Undo } from "lucide-react";
import { useEffect } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";

import type { AuthorizationInput } from "../../__generated__/graphql";
import { AuthorizationValue } from "../../__generated__/graphql";
import type {
  OrganizationMemberAuthorizations,
  Description,
} from "../../routes/settings/organization-settings/constants";
import { AuthorizationToDescriptions } from "../../routes/settings/organization-settings/constants";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { RadioGroup, RadioGroupItem } from "../ui/radio";
import { Switch } from "../ui/switch";

const AuthorizationValues = [
  AuthorizationValue.Admin,
  AuthorizationValue.Creator,
  AuthorizationValue.Reader,
  AuthorizationValue.None,
];

export const AuthorizationList = ({
  authorizations,
}: {
  authorizations: AuthorizationInput;
}) => {
  const descriptions: Description[] = [];
  if (authorizations.developer) {
    descriptions.push(AuthorizationToDescriptions.developer);
  }
  if (authorizations.builder !== AuthorizationValue.None) {
    descriptions.push(
      AuthorizationToDescriptions.builder[authorizations.builder],
    );
  }
  if (authorizations.designer !== AuthorizationValue.None) {
    descriptions.push(
      AuthorizationToDescriptions.designer[authorizations.designer],
    );
  }
  if (authorizations.manager !== AuthorizationValue.None) {
    descriptions.push(
      AuthorizationToDescriptions.manager[authorizations.manager],
    );
  }
  if (authorizations.instrument !== AuthorizationValue.None) {
    descriptions.push(
      AuthorizationToDescriptions.instrument[authorizations.instrument],
    );
  }

  if (descriptions.length === 0) {
    return (
      <div className="relative">
        <Badge variant={"outline"}>No authorizations</Badge>
      </div>
    );
  }
  return (
    <div className="relative space-x-1 space-y-1">
      {descriptions.map((description) => {
        return (
          <Badge
            className="text-xs"
            key={description.label}
            variant={"outline"}
          >
            {description.badgeLabel}
          </Badge>
        );
      })}
    </div>
  );
};

const AuthorizationTitle = ({
  title = "Authorizations",
}: {
  title?: string;
}) => {
  return <h3 className="font-bold">{title}</h3>;
};

export default function Authorizations({
  authorizations,
  handleEdit,
  editable,
  title,
}: {
  authorizations?: OrganizationMemberAuthorizations;
  editable: boolean;
  handleEdit?: () => void;
  title?: string;
}) {
  if (!authorizations) {
    return null;
  }

  return (
    <div>
      <div className="flex justify-between">
        <AuthorizationTitle title={title} />
        {editable && (
          <Button onClick={handleEdit} variant={"secondary"}>
            <Pencil />
          </Button>
        )}
      </div>
      <AuthorizationList authorizations={authorizations} />
    </div>
  );
}

const AuthorizationEditFromForm = ({
  form,
}: {
  form: UseFormReturn<OrganizationMemberAuthorizations>;
}) => {
  return (
    <div className="flex flex-col space-y-2">
      {(
        [
          {
            key: "builder",
            title: "Builder",
          },
          {
            key: "designer",
            title: "Designer",
          },
          {
            key: "manager",
            title: "User management",
          },
          {
            key: "instrument",
            title: "Instrument",
          },
        ] as const
      ).map(({ key, title }) => (
        <FormField
          control={form.control}
          key={key}
          name={key}
          render={({ field }) => (
            <FormItem className="flex flex-row items-center space-x-2">
              <FormLabel className="w-24">{title}</FormLabel>
              <FormControl className="mt-0">
                <RadioGroup
                  defaultValue={field.value}
                  onValueChange={field.onChange}
                >
                  {AuthorizationValues.map((value) => {
                    if (
                      key === "manager" &&
                      value === AuthorizationValue.Creator
                    ) {
                      return null;
                    }
                    const authorizationValue = `${value}-${key}`;
                    return (
                      <FormItem
                        className="flex items-center space-x-3 space-y-0"
                        key={authorizationValue}
                      >
                        <FormControl>
                          <RadioGroupItem
                            id={authorizationValue}
                            value={value}
                          />
                        </FormControl>
                        <FormLabel htmlFor={authorizationValue}>
                          {value !== AuthorizationValue.None ? value : "None"}
                        </FormLabel>
                        <FormDescription>
                          {value !== AuthorizationValue.None
                            ? AuthorizationToDescriptions[key][value].details
                            : "None"}
                        </FormDescription>
                      </FormItem>
                    );
                  })}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      ))}
      <FormField
        control={form.control}
        name={"developer"}
        render={({ field }) => (
          <FormItem className="flex flex-row items-center space-x-2">
            <FormControl className="mt-0">
              <Switch
                checked={field.value}
                className="mt-0"
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <div>
              <FormLabel>
                {AuthorizationToDescriptions.developer.label}
              </FormLabel>
              <FormDescription>
                {AuthorizationToDescriptions.developer.details}
              </FormDescription>
            </div>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};

export const AuthorizationsEdit = ({
  defaultAuthorizations,
  handleSubmit,
  handleCancel,
  title,
}: {
  defaultAuthorizations?: OrganizationMemberAuthorizations;
  handleCancel: () => void;
  handleSubmit: (values: OrganizationMemberAuthorizations) => void;
  title?: string;
}) => {
  const form = useForm<OrganizationMemberAuthorizations>({
    defaultValues: defaultAuthorizations,
  });

  useEffect(() => {
    form.reset(defaultAuthorizations);
  }, [defaultAuthorizations, form]);

  const onSubmit = (values: OrganizationMemberAuthorizations) => {
    handleSubmit(values);
  };

  return (
    <div>
      <Form {...form}>
        <form className="space-y-2" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex justify-between">
            <AuthorizationTitle title={title} />
            <div className="flex space-x-2">
              <Button onClick={handleCancel} variant={"secondary"}>
                <Undo />
              </Button>
              <Button type="submit">
                <SaveIcon />
              </Button>
            </div>
          </div>
          <AuthorizationEditFromForm form={form} />
        </form>
      </Form>
    </div>
  );
};

export const AuthorizationsEditWithChange = ({
  defaultAuthorizations,
  handleChange,
  title,
}: {
  defaultAuthorizations?: OrganizationMemberAuthorizations;
  handleChange: (values: OrganizationMemberAuthorizations) => void;
  title?: string;
}) => {
  const form = useForm<OrganizationMemberAuthorizations>({
    defaultValues: defaultAuthorizations,
  });

  useEffect(() => {
    form.reset(defaultAuthorizations);
  }, [defaultAuthorizations, form]);

  const values = form.watch();
  const valuesString = JSON.stringify(values);

  useEffect(() => {
    handleChange?.(values);
  }, [handleChange, values, valuesString]);

  return (
    <div>
      <Form {...form}>
        <form className="space-y-2">
          <div className="flex justify-between">
            <AuthorizationTitle title={title} />
            <div className="flex space-x-2">
              <AuthorizationEditFromForm form={form} />
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};
