import type { FC, MouseEvent, PropsWithChildren } from "react";

import { RowActions } from "./data-table-row-actions";

import { AlertDialogWrapper } from "../alert-dialog";
import { DropdownMenuItem } from "../dropdown-menu";

export const TableActions: FC<{
  items: ({
    alertDialog?: {
      message: string;
      title: string;
    };
    disabled?: boolean;
    id: string;
    onClick?: (event?: MouseEvent<HTMLElement, globalThis.MouseEvent>) => void;
    skip?: boolean;
  } & PropsWithChildren)[];
}> = ({ items }) => {
  return (
    <div className="flex justify-end">
      <RowActions closeOnClick>
        {items.map((item) => {
          return item.skip ? (
            <></>
          ) : (
            <DropdownMenuItem
              disabled={item.disabled}
              key={item.id}
              onClick={
                item.alertDialog ? (e) => e.preventDefault() : item.onClick
              }
            >
              {item.alertDialog ? (
                <AlertDialogWrapper
                  description={item.alertDialog.message}
                  onConfirm={item.onClick}
                  title={item.alertDialog.title}
                >
                  {item.children}
                </AlertDialogWrapper>
              ) : (
                <>{item.children}</>
              )}
            </DropdownMenuItem>
          );
        })}
      </RowActions>
    </div>
  );
};
