import { useEffect } from "react";
import type { SubmitHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import PageWithWallPaper from "./components/page-with-wallpaper";
import { RootRoutes } from "./routes";

import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { PasswordInput } from "../components/ui/password";
import { useToast } from "../components/ui/use-toast";
import { IS_DEVELOPMENT } from "../config";
import { trpc } from "../config/trpc";
import { useAuth } from "../containers/hooks";
import { getRouteToGoToAfterLogin, parseJwt } from "../utils/auth";

type Inputs = {
  email: string;
  password: string;
};

export default function Login() {
  const { setToken } = useAuth();
  const { toast } = useToast();
  const form = useForm<Inputs>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const { mutate: login, isPending } = trpc.account.login.useMutation({
    onError(e) {
      toast({
        description: `Invalid credentials. Message: ${e.message}`,
        title: "Authentication error",
        variant: "destructive",
      });
    },
    onSuccess(data) {
      const token = data.token;
      if (!token) {
        throw new Error("Authentication error");
      }
      setToken(token);
      navigate(getRouteToGoToAfterLogin(parseJwt(token)));
    },
  });

  const navigate = useNavigate();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    login({ email: data.email, password: data.password });
  };

  useEffect(() => {
    if (!IS_DEVELOPMENT) {
      return;
    }
    const id = 0;
    const { email, password } = [
      {
        email: "admin@sample.com",
        password: "admin",
      },
      {
        email: "client@sample.com",
        password: "client_password",
      },
    ][id];
    form.setValue("email", email);
    form.setValue("password", password);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <PageWithWallPaper>
        <Card>
          <CardHeader>
            <CardTitle>Login</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col space-y-4">
            <div className="space-y-4">
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  placeholder="john@company.com"
                  type="text"
                  {...form.register("email", { required: true })}
                  className="w-[500px]"
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="password">Password</Label>
                <PasswordInput
                  id="password"
                  placeholder="Password"
                  type="password"
                  {...form.register("password", { required: true })}
                />
              </div>
            </div>
            <Button isLoading={isPending} size="lg" type="submit">
              Login
            </Button>
            <Button
              onClick={() => {
                navigate(RootRoutes.FORGOT_PASSWORD);
              }}
              variant="ghost"
            >
              Forgot password ? Reset
            </Button>
          </CardContent>
        </Card>
      </PageWithWallPaper>
    </form>
  );
}
