import { Heading, Text } from "@radix-ui/themes";
import { Edit2Icon } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { useGetMyOrganization } from "./hooks";

import { Button } from "../../../components/ui/button";
import OrganizationFlagConfiguration from "../../admin/organizations/organization/components/organization-flag-configuration";
import OrganizationKitConfiguration from "../../admin/organizations/organization/components/organization-kit-configuration";

export default function OrganizationSettingsDetails() {
  const data = useGetMyOrganization();
  const navigate = useNavigate();

  if (!data) {
    return <Heading>Organization not found</Heading>;
  }

  const configuration = data.configuration;

  return (
    <div className="space-y-4">
      <div className="bg-card space-y-2 rounded-lg border p-4">
        <div className="flex justify-between">
          <Heading>Details</Heading>
          <Button onClick={() => navigate("edit")} variant={"secondary"}>
            <Edit2Icon />
          </Button>
        </div>
        <div className="space-y-2">
          <div className="space-x-2">
            <span>Creation date:</span>
            <span className="font-bold">
              {new Date(data?.createdAt).toLocaleDateString()}
            </span>
          </div>
          <div className="space-x-2">
            <span>Last update:</span>
            <span className="font-bold">
              {new Date(data?.updatedAt).toLocaleString()}
            </span>
          </div>
        </div>

        <div>
          <Text size={"4"}>Configuration</Text>
          <div className="ml-4 mt-2">
            <OrganizationFlagConfiguration
              bioSecurityEnabled={configuration.bioSecurityEnabled}
              clicksEnabled={configuration.clicksEnabled}
              customProcessEnabled={configuration.customProcessEnabled}
              degenerateBasesEnabled={configuration.degenerateBasesEnabled}
              geneEnabled={configuration.geneEnabled}
              isActive={data.isActive}
              mfaMandatory={configuration.mfaMandatory}
              modsEnabled={configuration.modsEnabled}
              notificationsEnabled={configuration.notificationsEnabled}
              remoteRunTriggerEnabled={configuration.remoteRunTriggerEnabled}
            />
          </div>
        </div>
        <OrganizationKitConfiguration kits={configuration.kits} />
      </div>
    </div>
  );
}
