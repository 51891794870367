import {
  ArrowDownIcon,
  ArrowUpIcon,
  CaretSortIcon,
  EyeNoneIcon,
} from "@radix-ui/react-icons";
import type { Column, Table } from "@tanstack/react-table";
import { FilterIcon } from "lucide-react";
import { useEffect } from "react";

import { Filter } from "./data-table-column-filter";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  table: Table<TData>;
}

const useApplyDefaultFilter = (
  table: Table<any>,
  column: Column<any, unknown>,
) => {
  const defaultFilter = column.columnDef.meta?.defaultFilter;
  const columnFilterValue = column.getFilterValue();

  useEffect(() => {
    if (defaultFilter !== undefined && !columnFilterValue) {
      table.options.meta?.toggleFilterColumn?.(column.id);
      column.setFilterValue(defaultFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export function DataTableColumnHeader<TData, TValue>({
  column,
  table,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  const title = column.columnDef.meta?.title || column.id;

  useApplyDefaultFilter(table, column);

  const canFilter = column.getCanFilter();

  if (!column.getCanSort()) {
    return <div className={cn(className)}>{title}</div>;
  }

  const isFiltered = table.options.meta?.columnsFiltered?.includes(column.id);

  return (
    <div className={cn("flex items-center space-x-2", className)}>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="data-[state=open]:bg-accent -ml-3 h-8"
            size="sm"
            variant="ghost"
          >
            <span>{title}</span>
            {column.getIsSorted() === "desc" ? (
              <ArrowDownIcon className="ml-2 h-4 w-4" />
            ) : column.getIsSorted() === "asc" ? (
              <ArrowUpIcon className="ml-2 h-4 w-4" />
            ) : (
              <CaretSortIcon className="ml-2 h-4 w-4" />
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          {canFilter && (
            <DropdownMenuItem
              onClick={() => {
                table.options.meta?.toggleFilterColumn?.(column.id);
              }}
            >
              <FilterIcon className="text-muted-foreground/70 mr-2 h-3.5 w-3.5" />
              Filter
            </DropdownMenuItem>
          )}
          <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
            <ArrowUpIcon className="text-muted-foreground/70 mr-2 h-3.5 w-3.5" />
            Asc
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
            <ArrowDownIcon className="text-muted-foreground/70 mr-2 h-3.5 w-3.5" />
            Desc
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => column.toggleVisibility(false)}>
            <EyeNoneIcon className="text-muted-foreground/70 mr-2 h-3.5 w-3.5" />
            Hide
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {isFiltered && <Filter column={column} table={table} />}
    </div>
  );
}
