import { useMutation } from "@apollo/client";

import { START_RUN } from "../../../../gql/instrument";

export const useStartRun = (runId: number | undefined) => {
  const [startRunMutation, { loading }] = useMutation(START_RUN);

  const startRun = () => {
    if (!runId) {
      return;
    }
    startRunMutation({ variables: { runId } });
  };

  return { loading, startRun };
};
