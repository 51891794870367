import { useMutation } from "@apollo/client";
import { IterationCcw } from "lucide-react";

import { AlertDialogWrapper } from "../../../components/ui/alert-dialog";
import { Button } from "../../../components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../components/ui/tooltip";
import { useToast } from "../../../components/ui/use-toast";
import { UPDATE_INSTRUMENT_PASSWORD } from "../../../gql/instrument";

export const ResetInstrumentPassword = ({
  instrumentId,
}: {
  instrumentId: string;
}) => {
  const { toast } = useToast();

  const [updateInstrumentPassword] = useMutation(UPDATE_INSTRUMENT_PASSWORD, {
    onCompleted: () => {
      toast({
        description:
          "The password of the instrument has been reset. Change it in the instrument settings.",
        title: "Password reset",
        variant: "success",
      });
    },
    refetchQueries: ["GetInstrument"],
  });

  const resetInstrumentPassword = () => {
    updateInstrumentPassword({
      variables: {
        id: instrumentId,
      },
    });
  };

  return (
    <AlertDialogWrapper
      description={
        "Are you sure you want to reset the instrument's connection password ?"
      }
      onConfirm={resetInstrumentPassword}
      title={"Reset password"}
    >
      <Tooltip>
        <TooltipTrigger asChild>
          <Button size={"icon"} variant={"ghost"}>
            <IterationCcw />
          </Button>
        </TooltipTrigger>
        <TooltipContent>Reset mqtt password</TooltipContent>
      </Tooltip>
    </AlertDialogWrapper>
  );
};
