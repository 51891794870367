import { useApolloClient } from "@apollo/client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../containers/hooks";
import { RootRoutes } from "../../routes";

export const useResetApolloCache = () => {
  const client = useApolloClient();

  const resetApolloCache = () => {
    client.clearStore();
  };

  return resetApolloCache;
};

export const useLogout = () => {
  const { setToken } = useAuth();
  const resetApolloCache = useResetApolloCache();
  const navigate = useNavigate();

  const logout = () => {
    navigate(RootRoutes.LOGIN);
    setToken(null);
    resetApolloCache();
  };

  return logout;
};

export const useLogoutOnEvent = () => {
  const logout = useLogout();

  useEffect(() => {
    const handleLogout = () => {
      logout();
    };
    window.addEventListener("logout", handleLogout);

    return () => {
      window.removeEventListener("logout", handleLogout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
