import { Trash2 } from "lucide-react";

import { AlertDialogWrapper } from "../../../../../components/ui/alert-dialog";
import { useToast } from "../../../../../components/ui/use-toast";
import { trpc } from "../../../../../config/trpc";

export const DeleteAssay = ({ id }: { id: string }) => {
  const { toast } = useToast();
  const { mutate: deleteAssay } = trpc.assay.delete.useMutation({
    onSuccess() {
      toast({
        description: "The assay was successfully deleted",
        title: "Assay deleted",
        variant: "success",
      });
    },
  });

  const handleClickOnDelete = () => {
    deleteAssay(id);
  };

  return (
    <AlertDialogWrapper
      description="Are you sure you want to delete this assay ? This action cannot be undone."
      onConfirm={handleClickOnDelete}
      title="Delete assay"
    >
      <div className="flex flex-row items-center space-x-1">
        <Trash2 />
        <span>Delete</span>
      </div>
    </AlertDialogWrapper>
  );
};
