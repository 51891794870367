import { Heading } from "@radix-ui/themes";
import {
  ArrowRightFromLine,
  ListChecksIcon,
  PlayCircle,
  Trash2Icon,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

import ChooseInstrument from "./components/choose-instrument";
import { GenerateInstructionButton } from "./components/gene/generate-instructions";
import Plate from "./components/plate";
import type { WorkflowStepTRPC } from "./components/plate/types";
import PlateDescription from "./components/plate-description";
import WorkflowStepName from "./components/workflow-step-name";
import RunReagents from "./run-reagents";
import { useCheckRun } from "./useCheckRun";
import { useDeleteRun } from "./useDeleteRun";
import { useGetWorkflowStep } from "./useGetWorkflowStep";
import { useStartRun } from "./useStartJob";
import WorkflowProgress from "./workflow-progress";

import { RunState } from "../../../../__generated__/graphql";
import { Button } from "../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { LoadingSpinner } from "../../../../components/ui/spinner";
import { StepType } from "../../../../config/enums";
import { trpc } from "../../../../config/trpc";
import { OrganizationRoutes } from "../../organization-routes";

export default function WorkflowStepWrapper() {
  const { data: workflowStep, isPending, error } = useGetWorkflowStep();

  if (isPending) {
    return null;
  }

  if (error) {
    return (
      <div>
        <Heading>Error retrieving workflow</Heading>
      </div>
    );
  }

  return <WorkflowStep workflowStep={workflowStep} />;
}

function WorkflowStep({ workflowStep }: { workflowStep: WorkflowStepTRPC }) {
  const navigate = useNavigate();
  const { id, name, stepType } = workflowStep;
  const plate =
    stepType === StepType.OligoSynthesis ? workflowStep.plate : null;
  const isPlate = stepType === StepType.OligoSynthesis;
  const isGene = stepType === StepType.HamiltonAssembly;
  const isRunCreated = Boolean(plate?.run);
  const hasAssignedHamiltonInstrument =
    "gene" in workflowStep && Boolean(workflowStep.gene.instrument);
  const isEditable =
    !isRunCreated &&
    workflowStep.stepType === StepType.OligoSynthesis &&
    !workflowStep.locked;
  const isPlateChecked = Boolean(plate?.run?.canRun);
  const isQueued = isRunCreated && plate?.run?.state === RunState.Queued;
  const isLoaded = isRunCreated && plate?.run?.state === RunState.Loaded;
  const runId = plate?.run?.id;

  const { startRun, loading: loadingStart } = useStartRun(runId);
  const { checkRun, loading: loadingCheck } = useCheckRun(runId);
  const { deleteRun } = useDeleteRun(runId);

  const utils = trpc.useUtils();

  trpc.assay.steps.updateStream.useSubscription(
    {
      stepId: workflowStep.id,
    },
    {
      onData() {
        utils.assay.steps.get.invalidate(workflowStep.id);
      },
    },
  );
  trpc.assay.steps.plate.runProgress.useSubscription(
    {
      runId: runId ?? 0,
    },
    {
      enabled: Boolean(
        runId && workflowStep.stepType === StepType.OligoSynthesis,
      ),
      onData() {
        utils.assay.steps.get.invalidate(workflowStep.id);
      },
    },
  );

  return (
    <div className="space-y-4">
      <div className="flex flex-row justify-between">
        <WorkflowStepName currentName={name} workflowStepId={id} />
        <div className="flex flex-row items-center space-x-3">
          <Button
            className="flex flex-row items-center space-x-1"
            onClick={() => {
              navigate(
                OrganizationRoutes.ASSAY.replace(
                  ":assayId",
                  workflowStep.workflow.assayId,
                ),
              );
            }}
            variant={"outline"}
          >
            <span>Workflow</span>
            <ArrowRightFromLine />
          </Button>
          {!isRunCreated && !hasAssignedHamiltonInstrument && (
            <ChooseInstrument step={workflowStep} />
          )}
          {isGene && hasAssignedHamiltonInstrument && (
            <GenerateInstructionButton stepId={id} />
          )}
          {isPlate && (isQueued || isLoaded) && (
            <>
              {isPlateChecked ? (
                <Button
                  className="flex flex-row items-center space-x-1"
                  disabled={loadingStart}
                  onClick={startRun}
                >
                  <PlayCircle />
                  <span>Start run</span>
                  {loadingStart && <LoadingSpinner />}
                </Button>
              ) : (
                <Button
                  className="flex flex-row items-center space-x-1"
                  disabled={loadingCheck}
                  onClick={checkRun}
                >
                  <ListChecksIcon />
                  <span>Check run</span>
                  {loadingCheck && <LoadingSpinner />}
                </Button>
              )}
              <Button
                className="flex flex-row items-center space-x-2"
                disabled={isLoaded}
                onClick={deleteRun}
                variant={"outline"}
              >
                <Trash2Icon />
                <span>Remove from queue</span>
              </Button>
            </>
          )}
        </div>
      </div>
      {isGene ? (
        <WorkflowProgress step={workflowStep} />
      ) : (
        <div className="grid grid-cols-2 gap-2">
          <Card className="col-span-1">
            <CardHeader>
              <CardTitle>Details</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col space-y-4 overflow-x-scroll">
              {plate && <PlateDescription plate={plate} />}
            </CardContent>
          </Card>
          <WorkflowProgress step={workflowStep} />
        </div>
      )}
      {plate ? (
        <>
          <Plate isEditable={isEditable} plate={plate} />
          <RunReagents step={workflowStep} />
        </>
      ) : null}
    </div>
  );
}
