import { useMutation, useQuery } from "@apollo/client";
import { AccordionContent } from "@radix-ui/react-accordion";

import { useBooleanSearchParam } from "../../../components/search/useBooleanSearchParam";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";
import { Button } from "../../../components/ui/button";
import { Label } from "../../../components/ui/label";
import { Switch } from "../../../components/ui/switch";
import {
  GET_USER_NOTIFICATIONS,
  MARK_ALL_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_READ,
} from "../../../gql/notification";

const NOTIFICATIONS_PERIOD_IN_DAYS = 30;

export default function NotificationsList() {
  const { setSwitchValue: setShowUnreadOnly, isTrue: showUnreadOnly } =
    useBooleanSearchParam("unreadOnly");

  const { data } = useQuery(GET_USER_NOTIFICATIONS, {
    fetchPolicy: "cache-and-network",
    variables: {
      periodInDays: NOTIFICATIONS_PERIOD_IN_DAYS,
      unreadOnly: false,
    },
  });

  const [markAsRead] = useMutation(MARK_NOTIFICATION_AS_READ, {
    refetchQueries: [
      {
        query: GET_USER_NOTIFICATIONS,
        variables: {
          periodInDays: NOTIFICATIONS_PERIOD_IN_DAYS,
          unreadOnly: false,
        },
      },
    ],
  });
  const handleOpenNotification = (notificationId: string) => {
    markAsRead({
      variables: {
        id: notificationId,
      },
    });
  };

  const [markAllAsRead] = useMutation(MARK_ALL_NOTIFICATION_AS_READ, {
    refetchQueries: [
      {
        query: GET_USER_NOTIFICATIONS,
        variables: {
          periodInDays: NOTIFICATIONS_PERIOD_IN_DAYS,
          unreadOnly: false,
        },
      },
    ],
  });
  const handleMarkAllNotificationsAsRead = () => {
    markAllAsRead();
  };

  const notifications =
    data?.getNotifications?.filter(
      (notification) => !showUnreadOnly || notification.isRead === false,
    ) ?? [];

  return (
    <div>
      {notifications.length > 0 ? (
        <div className="scrollbar scrollbar-thumb-sky-700 scrollbar-track-sky-300 max-h-64 overflow-y-scroll">
          <Accordion type="multiple">
            {notifications.map((notification) => (
              <AccordionItem key={notification.id} value={notification.id}>
                <AccordionTrigger
                  className="w-full cursor-pointer p-2 text-center hover:bg-slate-100"
                  onClick={() => handleOpenNotification(notification.id)}
                >
                  <div className="flex items-center">
                    <span
                      className={`mr-2 h-2 w-2 rounded-full ${notification.isRead ? "white" : "bg-red-500"}`}
                    />
                    <p className="font-normal">{notification.subject}</p>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="p-4">
                    <p
                      dangerouslySetInnerHTML={{ __html: notification.content }}
                    />
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      ) : (
        `No notifications for last ${NOTIFICATIONS_PERIOD_IN_DAYS} days`
      )}
      <div className="flex items-center justify-between p-1">
        <div className="flex flex-row items-center space-x-2">
          <Switch
            checked={showUnreadOnly}
            id="show-unread-only"
            onCheckedChange={setShowUnreadOnly}
          />
          <Label>Show unread only</Label>
        </div>
        <Button
          disabled={notifications.length === 0}
          onClick={handleMarkAllNotificationsAsRead}
          variant="outline"
        >
          Mark all as read
        </Button>
      </div>
    </div>
  );
}
