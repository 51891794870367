import { Outlet } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import Footer from "./components/footer";
import Header from "./components/header/header";
import { useLogoutOnEvent } from "./components/header/useLogout";

import { BreadCrumbPage } from "../components/ui/breadcrumb";
import Tracker from "../containers/Tracker";
import UserProvider from "../containers/user/UserContainer";

export default function Root() {
  useLogoutOnEvent();

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <UserProvider>
        <Tracker>
          <Header />
          <div className="container my-2 flex-1 space-y-3" id="detail">
            <BreadCrumbPage />
            <Outlet />
          </div>
          <Footer />
        </Tracker>
      </UserProvider>
    </QueryParamProvider>
  );
}
