import { useQuery } from "@apollo/client";
import { BellRing } from "lucide-react";
import { useCallback, useEffect, useState } from "react";

import NotificationsList from "./notificationsList";

import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import {
  GET_NEW_NOTIFICATIONS_COUNT,
  SUBSCRIBE_NEW_NOTIFICATIONS_COUNT,
} from "../../../gql/notification";

export default function Notifications() {
  const [subscribed, setSubscribed] = useState(false);

  const { data: newNotificationsCount, subscribeToMore } = useQuery(
    GET_NEW_NOTIFICATIONS_COUNT,
  );

  const subscribeToNewNotifications = useCallback(() => {
    setSubscribed(true);
    return subscribeToMore({
      document: SUBSCRIBE_NEW_NOTIFICATIONS_COUNT,
      updateQuery: (previousData, { subscriptionData }) => {
        if (!subscriptionData.data) return previousData;
        const updatedNotificationsCount =
          subscriptionData.data.newNotificationsCount;
        return {
          ...previousData,
          getNewNotificationsCount: updatedNotificationsCount,
        };
      },
      variables: {},
    });
  }, [subscribeToMore]);

  useEffect(() => {
    if (subscribed) {
      return;
    }
    subscribeToNewNotifications();
  }, [newNotificationsCount, subscribed, subscribeToNewNotifications]);

  const unreadMessagesCount =
    newNotificationsCount?.getNewNotificationsCount ?? 0;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="relative">
          <Button variant="secondary">
            <BellRing fill="white" size={19} />
          </Button>
          {unreadMessagesCount > 0 && (
            <Badge className="absolute right-0 top-0 px-1.5">
              {unreadMessagesCount > 9 ? "9+" : unreadMessagesCount}
            </Badge>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-1 drop-shadow-2xl">
        <NotificationsList />
      </PopoverContent>
    </Popover>
  );
}
