import { useMutation } from "@apollo/client";

import { DELETE_RUN } from "../../../../gql/instrument";

export const useDeleteRun = (runId: number | undefined) => {
  const [deleteRunMutation, { loading }] = useMutation(DELETE_RUN);

  const deleteRun = () => {
    if (!runId) {
      return;
    }
    deleteRunMutation({ variables: { runId } });
  };

  return { deleteRun, loading };
};
