import "@radix-ui/themes/styles.css";
import { ApolloProvider } from "@apollo/client";
import { Theme } from "@radix-ui/themes";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import "@shared-ui/globals.css";
import { Toaster } from "./components/ui/toaster";
import { TooltipProvider } from "./components/ui/tooltip";
import ConfigInitializer from "./config/config";
import AuthProvider from "./containers/AuthContainer";
import router from "./routes/router";
import { client } from "./state/apolloClient";

// eslint-disable-next-line react-refresh/only-export-components
function App() {
  return (
    <Theme
      accentColor="bronze"
      className="flex min-h-screen flex-col"
      grayColor="gray"
      radius={"large"}
    >
      <React.StrictMode>
        <ConfigInitializer>
          <AuthProvider>
            <ApolloProvider client={client}>
              <TooltipProvider delayDuration={500}>
                <RouterProvider router={router} />
                <Toaster />
              </TooltipProvider>
            </ApolloProvider>
          </AuthProvider>
        </ConfigInitializer>
      </React.StrictMode>
    </Theme>
  );
}

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
