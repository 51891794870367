import { useMutation } from "@apollo/client";
import type { ColumnDef } from "@tanstack/react-table";
import { DoorOpenIcon, EyeOffIcon } from "lucide-react";

import { useActivateOrDeactivateOrgMember } from "./components/useActivateOrDeactivateOrgMember";
import { useGetMembersOfOrganization, useGetMyOrganization } from "./hooks";

import {
  CardContent,
  Card,
  CardHeader,
  CardTitle,
} from "../../../components/ui/card";
import { DataTable } from "../../../components/ui/data-table/data-table";
import { TableActions } from "../../../components/ui/data-table/data-table-actions";
import { DataTableColumnHeader } from "../../../components/ui/data-table/data-table-column-header";
import { ACTIVATE_SUPPORT_MEMBER } from "../../../gql/organization-member";
import { CheckIfTrueElseCross } from "../../admin/organizations/components/ui";
import { useGetSupportUsersOfOrganization } from "../../admin/organizations/hooks";
import type { SupportUser } from "../../admin/organizations/organization/organization-support";

const SupportMemberActions = ({
  row,
}: {
  row: OrganizationSettingsSupportUser;
}) => {
  const [activateSupportMember] = useMutation(ACTIVATE_SUPPORT_MEMBER, {
    refetchQueries: ["Organization"],
  });

  const deactivateMember = useActivateOrDeactivateOrgMember(
    row.memberShipId ?? "",
    false,
  );

  const handleActivateUser = (userId: string) => {
    activateSupportMember({
      variables: {
        userId,
      },
    });
  };

  const isActive = row.isActive;

  return (
    <TableActions
      items={[
        {
          children: (
            <div className="flex items-center gap-2">
              <DoorOpenIcon />
              <span>Activate</span>
            </div>
          ),
          id: "activate",
          onClick: () => handleActivateUser(row.id),
          skip: isActive && !!row.memberShipId,
        },
        {
          children: (
            <div className="flex items-center gap-2">
              <EyeOffIcon />
              <span>Deactivate</span>
            </div>
          ),
          id: "deactivate",
          onClick: deactivateMember,
          skip: !isActive && !row.memberShipId,
        },
      ]}
    />
  );
};

type OrganizationSettingsSupportUser = SupportUser & {
  isActive: boolean;
  memberShipId?: string;
};

export default function OrganizationSettings() {
  const data = useGetMyOrganization();
  const organizationId = data?.id ?? "";
  const members = useGetMembersOfOrganization(organizationId);

  const { data: supportUsers } =
    useGetSupportUsersOfOrganization(organizationId);

  const isUserActive = (userId: string) => {
    const member = members?.find((member) => member.userId === userId);
    if (!member) {
      return false;
    }
    return member.active && member.isSupport;
  };

  const supportUsersWithActiveStatus = (supportUsers ?? []).map((member) => ({
    ...member,
    isActive: isUserActive(member.id),
    memberShipId: members?.find((m) => m.userId === member.id)?.id,
  }));

  const columns: ColumnDef<OrganizationSettingsSupportUser>[] = [
    {
      accessorKey: "email",
      header: ({ column, table }) => (
        <DataTableColumnHeader column={column} table={table} />
      ),
      id: "email",
      meta: {
        title: "Email",
      },
    },
    {
      accessorFn: (row) => row.name,
      header: ({ column, table }) => (
        <DataTableColumnHeader column={column} table={table} />
      ),
      id: "name",
      meta: {
        title: "Name",
      },
    },
    {
      accessorFn: (row) => {
        return isUserActive(row.id);
      },
      cell: ({ row }) => {
        return <CheckIfTrueElseCross value={isUserActive(row.original.id)} />;
      },
      header: ({ column, table }) => (
        <DataTableColumnHeader column={column} table={table} />
      ),
      id: "active",
      meta: {
        title: "Active",
      },
    },
    {
      cell: ({ row }) => {
        return <SupportMemberActions row={row.original} />;
      },
      enableHiding: false,
      id: "actions",
    },
  ];

  return (
    <Card className="col-span-1">
      <CardHeader>
        <CardTitle>Support users</CardTitle>
      </CardHeader>
      <CardContent>
        <DataTable columns={columns} data={supportUsersWithActiveStatus} />
      </CardContent>
    </Card>
  );
}
