import { zodResolver } from "@hookform/resolvers/zod";
import { AlertTriangle, ArrowLeft } from "lucide-react";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Editor } from "./components/edit-gene/edit-gene";
import OligoSet from "./components/oligo-set";
import useLocationAssayId from "./hooks/useLocationAssayId";

import TagsSelection from "../../../../components/logic/tags";
import { Button } from "../../../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { Separator } from "../../../../components/ui/separator";
import { Switch } from "../../../../components/ui/switch";
import { Textarea, TextareaAutoGrow } from "../../../../components/ui/textarea";
import { ConstructType } from "../../../../config/enums";
import { useIsGeneEnabled } from "../../../settings/organization-settings/hooks";

const MIN_GENE_LENGTH = 250;

const geneSequenceSchema = z.object({
  root: z.object({
    children: z.array(
      z.object({
        children: z.array(
          z.object({
            style: z.string(),
            text: z.string(),
          }),
        ),
      }),
    ),
  }),
});

// eslint-disable-next-line react-refresh/only-export-components
export const oligosSchema = z
  .array(
    z.object({
      id: z.string().optional(),
      locked: z.boolean().default(false),
      name: z.string().trim().optional(),
      sequence: z.string().trim(),
      wellHint: z.string().nullable(),
    }),
  )
  .min(1, "Must have at least 1 oligo in an oligo set")
  .max(
    1000,
    "Cannot have more than 1920 oligos (4 High plex plates) in a single oligo set",
  )
  .refine(
    (data) => {
      if (data.length !== 1) {
        return true;
      }
      const oligo = data[0];
      if (!oligo.sequence && !oligo.name) {
        return false;
      }
      return true;
    },
    {
      message: "Construct cannot have one single empty oligo.",
    },
  );

const editConstructForm = z.object({
  assayId: z.string().optional(),
  description: z.string(),
  details: z
    .discriminatedUnion(
      "type",
      [
        z.object({
          oligos: oligosSchema,
          type: z.literal(ConstructType.OligoSet),
        }),
        z.object({
          oligos: oligosSchema,
          sequence: z.string(),
          type: z.literal(ConstructType.Gene),
          vector: z
            .object({
              insertPosition: z.number().min(0),
              sequence: z.string(),
            })
            .optional(),
        }),
      ],
      { message: "Please choose a construct type" },
    )
    .refine(
      (data) => {
        if (data.type !== ConstructType.Gene) {
          return true;
        }
        if (!data.vector) {
          return true;
        }
        const { insertPosition, sequence } = data.vector;
        return insertPosition < sequence.length;
      },
      {
        message:
          "Insert position of the gene must be within the length of the vector sequence",
        path: ["vector", "insertPosition"],
      },
    )
    .refine(
      (data) => {
        if (data.type !== ConstructType.Gene) {
          return true;
        }
        const { sequence } = data;
        const parsedState = geneSequenceSchema.parse(JSON.parse(sequence));
        const sequenceLength =
          parsedState.root.children[0].children[0].text.length;
        return sequenceLength >= MIN_GENE_LENGTH;
      },
      {
        message: `Gene must have a length bigger than ${MIN_GENE_LENGTH}`,
        path: ["sequence"],
      },
    ),
  name: z.string().trim().min(1),
  tags: z.array(z.string()),
});

export type EditConstructForm = z.infer<typeof editConstructForm>;

export default function EditConstruct({
  defaultValues,
  onSave,
  children,
  locked,
  onChange,
}: React.PropsWithChildren<{
  defaultValues?: EditConstructForm;
  locked?: boolean;
  onChange?: (change: boolean) => void;
  onSave: (data: EditConstructForm) => void;
}>) {
  const assayId = useLocationAssayId();
  const form = useForm<EditConstructForm>({
    defaultValues: defaultValues || {
      assayId,
      description: "",
      name: "",
      tags: [],
    },
    resolver: zodResolver(editConstructForm),
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const values = form.watch();
  const didChange = useMemo(() => {
    return JSON.stringify(defaultValues) !== JSON.stringify(values);
  }, [defaultValues, values]);

  useEffect(() => {
    onChange?.(didChange);
  }, [didChange, onChange]);

  const handleUpdateConstruct = (data: EditConstructForm) => {
    if (data.details.type === ConstructType.OligoSet) {
      data.details.oligos = data.details.oligos.filter(
        (o) => o.sequence || o.name,
      );
    }
    form.reset(data);
    onSave(data);
  };

  const { details } = values;
  const shouldPickConstructType = !details;
  const type = details?.type;
  const useVector = type === ConstructType.Gene && details.vector !== undefined;
  const isGeneEnabled = useIsGeneEnabled();

  return (
    <Form {...form}>
      <form
        className="space-y-4"
        onSubmit={form.handleSubmit(handleUpdateConstruct)}
      >
        {children}
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input className="w-1/2" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="tags"
          render={({ field }) => (
            <FormItem className="space-x-4">
              <FormLabel>Tags</FormLabel>
              <FormControl>
                <TagsSelection
                  setValues={(newTags: string[]) => {
                    form.setValue("tags", newTags);
                  }}
                  type="construct"
                  values={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Separator />
        {shouldPickConstructType ? (
          <div>
            <h3>Pick construct type</h3>
            <div className="flex w-full flex-col items-center justify-center space-y-8">
              <Button
                className="h-100 bg-card flex w-1/2 items-start justify-between space-x-4 rounded-lg border p-4"
                onClick={() => {
                  form.setValue("details", {
                    oligos: [],
                    type: ConstructType.OligoSet,
                  });
                }}
                variant={"ghost"}
              >
                <span className="text-xl font-bold uppercase">Oligo Set</span>
                <p className="max-w-[400px]">
                  A list of oligos to be printed. Can contain 1, 10, 1000
                  oligos. The system will offer to optimise placement on plates.
                </p>
              </Button>
              <Button
                className="h-100 bg-card flex w-1/2 items-start justify-between space-x-4 rounded-lg border p-4"
                disabled={!isGeneEnabled}
                onClick={() => {
                  form.setValue("details", {
                    oligos: [],
                    sequence: "",
                    type: ConstructType.Gene,
                  });
                }}
                variant={"ghost"}
              >
                <span className="text-xl font-bold uppercase">Gene</span>
                <p className="flex max-w-[400px] flex-col space-y-2">
                  <span>
                    A gene to be printed using our Syntax+Gene workflow.
                  </span>
                  {!isGeneEnabled && (
                    <p className="flex flex-row items-center space-x-1">
                      <AlertTriangle />
                      <span>Gene is not enabled for your organization</span>
                    </p>
                  )}
                </p>
              </Button>
            </div>
          </div>
        ) : (
          <Button
            className="flex flex-row items-center space-x-1"
            disabled={!!onChange}
            // @ts-expect-error - The details object doesn't accept undefined values
            onClick={() => form.setValue("details", undefined)}
            variant={"outline"}
          >
            <ArrowLeft />
            <span>Switch construct type</span>
          </Button>
        )}
        <FormField
          control={form.control}
          name="details"
          render={() => (
            <FormItem>
              <FormMessage />
            </FormItem>
          )}
        />
        {type === ConstructType.OligoSet && (
          <FormField
            control={form.control}
            name="details.oligos"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Oligos</FormLabel>
                <FormControl>
                  <OligoSet
                    data={field.value}
                    isLocked={Boolean(locked)}
                    setData={(oligos) => {
                      const newOligos =
                        typeof oligos === "function"
                          ? oligos(field.value)
                          : oligos;
                      field.onChange(newOligos);
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        {type === ConstructType.Gene && (
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="details.sequence"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Gene sequence</FormLabel>
                  <FormControl>
                    <Editor
                      initialValue={
                        defaultValues?.details.type === ConstructType.Gene
                          ? defaultValues.details.sequence
                          : ""
                      }
                      setValue={(newValue) => field.onChange(newValue)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-row items-center space-x-2">
              <Switch
                checked={form.watch("details.vector") !== undefined}
                id="vectorSwitch"
                onCheckedChange={(checked) => {
                  form.setValue(
                    "details.vector",
                    checked
                      ? {
                          insertPosition: 0,
                          sequence: "",
                        }
                      : undefined,
                  );
                }}
              />
              <Label htmlFor="vectorSwitch">Use backbone vector</Label>
            </div>
            {useVector && (
              <div className="ml-10">
                <FormField
                  control={form.control}
                  name="details.vector.sequence"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Vector sequence</FormLabel>
                      <FormControl>
                        <div className="relative space-x-2">
                          <TextareaAutoGrow
                            className="pr-10"
                            id="geneSequence"
                            onChange={(e) => field.onChange(e)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                e.currentTarget.blur();
                              }
                            }}
                            value={field.value}
                          />
                          <span className="absolute right-2 top-0">
                            {field.value.length}
                          </span>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="details.vector.insertPosition"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Gene insert position on vector</FormLabel>
                      <FormControl>
                        <div className="relative space-x-2">
                          <Input
                            className="h-100 pr-10"
                            min={0}
                            onChange={(e) =>
                              field.onChange(Number(e.target.value))
                            }
                            type="number"
                            value={field.value}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            )}
            <FormField
              control={form.control}
              name="details.oligos"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Oligos</FormLabel>
                  <FormControl>
                    <OligoSet
                      data={field.value}
                      isLocked={Boolean(locked)}
                      setData={(oligos) => {
                        const newOligos =
                          typeof oligos === "function"
                            ? oligos(field.value)
                            : oligos;
                        field.onChange(newOligos);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        )}
      </form>
    </Form>
  );
}
