import { zodResolver } from "@hookform/resolvers/zod";
import { Heading, Text } from "@radix-ui/themes";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { AddToAssayTable } from "./components/assay-load-construct";

import TagsSelection from "../../../../components/logic/tags";
import { Button } from "../../../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import { useToast } from "../../../../components/ui/use-toast";
import { trpc } from "../../../../config/trpc";
import { useGetUIAuthorizedCreations } from "../../../../containers/user/hooks";
import { OrganizationRoutes } from "../../organization-routes";

const createAssayForm = z.object({
  constructs: z.array(
    z.object({
      id: z.string(),
    }),
  ),
  name: z.string().trim().min(1),
  tags: z.array(z.string()),
});

type CreateAssay = z.infer<typeof createAssayForm>;

export default function NewAssay() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const form = useForm<CreateAssay>({
    defaultValues: {
      constructs: [],
      name: "",
      tags: [],
    },
    resolver: zodResolver(createAssayForm),
  });
  const { assay: canCreateAssay } = useGetUIAuthorizedCreations();

  const { mutate: createAssay } = trpc.assay.create.useMutation({
    onError(error) {
      toast({
        description: error.message,
        title: "Error",
        variant: "destructive",
      });
    },
    onSuccess(newAssayId) {
      toast({
        description: "Your assay was created successfully",
        title: "Assay created",
        variant: "default",
      });
      navigate(OrganizationRoutes.ASSAY.replace(":assayId", newAssayId));
    },
  });

  function onSubmit(values: CreateAssay) {
    createAssay({
      constructs: values.constructs.map((construct) => construct.id),
      name: values.name,
      tags: values.tags,
    });
  }

  if (!canCreateAssay) {
    navigate(OrganizationRoutes.ASSAYS);
  }

  return (
    <Form {...form}>
      <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex justify-between">
          <Heading>New Assay</Heading>
          <Button type="submit">Submit</Button>
        </div>
        <div className="bg-card rounded-lg border p-4">
          <div className="space-y-2">
            <FormLabel>Assay Name</FormLabel>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="w-1/2">
                  <FormControl>
                    <Input aria-label="assayName" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="tags"
              render={({ field }) => (
                <FormItem className="space-x-4">
                  <FormLabel>Tags</FormLabel>
                  <FormControl>
                    <TagsSelection
                      setValues={(newTags: string[]) =>
                        form.setValue("tags", newTags)
                      }
                      type="assay"
                      values={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="bg-card space-y-2 rounded-lg border p-4">
          <Text size="4">Construct(s) to import from library</Text>
          <AddToAssayTable
            onSelectionChange={(constructs) => {
              form.setValue("constructs", constructs);
            }}
          />
        </div>
      </form>
    </Form>
  );
}
