export enum AccountStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum NotificationChannel {
  EMAIL = "EMAIL",
  SMS = "SMS",
  TEAMS = "TEAMS",
}

export enum OrganizationStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum AuthorizationSubject {
  Assay = "Assay",
  Construct = "Construct",
  HamiltonInstrument = "HamiltonInstrument",
  Instrument = "Instrument",
}

export enum AuthorizationOwner {
  Member = "Member",
  Team = "Team",
}

export enum ConstructType {
  Gene = "Gene",
  OligoSet = "OligoSet",
}

export enum ConstructDownloadFormat {
  CSV = "CSV",
  FASTA = "FASTA",
}

export enum StepType {
  HamiltonAssembly = "HamiltonLigation",
  OligoSynthesis = "OligoSynthesis",
}

export enum PlateSize {
  S384 = "384",
  S96 = "96",
}

export enum Normalization {
  Lowest = "Lowest",
  None = "None",
  Target = "Target",
}

export enum Purity {
  Difficult = "Difficult",
  Extreme = "Extreme",
  Normal = "Normal",
  Unknown = "Unknown",
}

export enum WellErrorSeverity {
  Error = "error",
  Warning = "warning",
}

export enum RunState {
  Completed = "Completed",
  Failed = "Failed",
  Loaded = "Loaded",
  Printing = "Printing",
  Queued = "Queued",
  Washing = "Washing",
}

export enum PlateErrorType {
  Biosecurity = "Biosecurity",
  Cycles = "Cycles",
}

export enum PlateErrorSeverity {
  Error = "error",
  Warning = "warning",
}

export enum WellErrorType {
  Biosecurity = "Biosecurity",
  Click = "Click",
  Length = "Length",
  Purity = "Purity",
  UnknownNucleotide = "UnknownNucleotide",
}

export enum ModificationStatus {
  Acceptable = "Acceptable",
  Low = "Low",
  NonQuantifiable = "NonQuantifiable",
  None = "None",
  Successful = "Successful",
}
