import { graphql } from "../__generated__/gql";

export const GET_USER_NOTIFICATIONS = graphql(/* GraphQL */ `
  query GetNotifications($periodInDays: Int!, $unreadOnly: Boolean) {
    getNotifications(periodInDays: $periodInDays, unreadOnly: $unreadOnly) {
      id
      type
      subject
      content
      createdAt
      isRead
    }
  }
`);

export const GET_NEW_NOTIFICATIONS_COUNT = graphql(/* GraphQL */ `
  query GetNewNotificationsCount {
    getNewNotificationsCount
  }
`);

export const MARK_NOTIFICATION_AS_READ = graphql(/* GraphQL */ `
  mutation MarkNotificationAsRead($id: ID!) {
    markNotificationAsRead(id: $id)
  }
`);

export const MARK_ALL_NOTIFICATION_AS_READ = graphql(/* GraphQL */ `
  mutation MarkAllNotificationsAsRead {
    markAllNotificationsAsRead
  }
`);

export const SUBSCRIBE_NEW_NOTIFICATIONS_COUNT = graphql(/* GraphQL */ `
  subscription NewNotificationsCount {
    newNotificationsCount
  }
`);
