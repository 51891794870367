import { useEffect, useMemo } from "react";

import { trpc } from "../../config/trpc";
import { UserContext } from "../contexts";
import { useAuth } from "../hooks";

const UserProvider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  const { token, parsedToken } = useAuth();
  const {
    refetch: getMe,
    data,
    isPending,
    error,
  } = trpc.account.user.myself.useQuery();

  useEffect(() => {
    if (error?.data?.code === "UNAUTHORIZED") {
      window.dispatchEvent(new Event("logout"));
    }
  }, [error]);
  const { data: membershipData } = trpc.account.user.membership.useQuery();

  useEffect(() => {
    getMe();
  }, [getMe, token]);

  const membership = useMemo(() => {
    if (!data) {
      return null;
    }
    if (data.memberships.length === 0) {
      return null;
    }
    return (
      data.memberships.find(
        (m) => m.organizationId === parsedToken?.organizationId,
      ) ?? null
    );
  }, [data, parsedToken?.organizationId]);

  const containerValue = useMemo(() => {
    if (isPending || !data) {
      return null;
    }

    return { ...data, ...(membershipData ?? {}), membership };
  }, [data, membership, isPending, membershipData]);

  return (
    <UserContext.Provider value={containerValue}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
