import { Text } from "@radix-ui/themes";
import type { CellContext } from "@tanstack/react-table";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { getQueryKey } from "@trpc/react-query";
import type { inferProcedureOutput } from "@trpc/server";
import { useNavigate, useParams } from "react-router-dom";

import AddToTeam from "./components/add-to-team";
import { AuthorizationsBlock } from "./components/authorizations-block";
import { RemoveFromTeamButton } from "./components/team-member-components";
import TeamName from "./components/team-name";
import type { OrganizationMemberAuthorizations } from "./constants";
import { OrganizationSettingsRoutes } from "./organization-settings-routes";

import { IsActive } from "../../../components/table/basic";
import { SimpleTable } from "../../../components/ui/simple-table";
import EllipsedText from "../../../components/ui/tooltipText";
import type { AppRouter } from "../../../config/trpc";
import { queryClient, trpc } from "../../../config/trpc";
import { removeTypename } from "../../../utils/gql";

export type TeamQuery = inferProcedureOutput<
  AppRouter["account"]["team"]["get"]
>;

type TeamMember = TeamQuery["members"][0];

const ActionsCell = ({ info }: { info: CellContext<TeamMember, unknown> }) => {
  return <RemoveFromTeamButton id={info.row.original.id} />;
};

const columnHelper = createColumnHelper<TeamMember>();

const columns = [
  columnHelper.accessor("user.email", {
    cell: (info) => (
      <EllipsedText maxWidth="300px">{info.getValue()}</EllipsedText>
    ),
    header: "Email",
  }),
  columnHelper.accessor("user.name", {
    cell: (info) => (
      <EllipsedText maxWidth="200px">{info.getValue()}</EllipsedText>
    ),
    header: "Name",
  }),
  columnHelper.accessor("user.status", {
    cell: (info) => <IsActive isActive={info.getValue() === "ACTIVE"} />,
    header: "Active ?",
  }),
  columnHelper.display({
    cell: (info) => <ActionsCell info={info} />,
    id: "actions",
  }),
];

export default function Team() {
  const { teamId } = useParams();
  const navigate = useNavigate();
  const { data } = trpc.account.team.get.useQuery(
    { id: teamId ?? "" },
    {
      enabled: !!teamId,
    },
  );

  const table = useReactTable({
    columns,
    data: data?.members ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  const { mutate: updatePermissions } =
    trpc.account.team.updatePermissions.useMutation({
      onSuccess() {
        queryClient.invalidateQueries({
          queryKey: getQueryKey(trpc.account.team.get, { id: teamId }),
        });
      },
    });

  const handleUpdatePermissions = (
    authorizations: OrganizationMemberAuthorizations,
  ) => {
    if (!teamId) {
      return;
    }
    updatePermissions({
      authorizations,
      teamId,
    });
  };

  return (
    <div className="space-y-4">
      <TeamName team={data?.team} />
      <div className="bg-card space-y-2 rounded-lg border p-4">
        <div className="flex items-center justify-between">
          <Text>Users</Text>
          <AddToTeam
            members={(data?.usersNotInTeam ?? []).map((u) => ({
              active: u.status === "ACTIVE",
              email: u.email,
              id: u.membershipId,
              name: u.name,
            }))}
          />
        </div>
        <SimpleTable
          onRowClick={(row) =>
            navigate(
              OrganizationSettingsRoutes.USER.replace(
                ":userId",
                row.original.user.membershipId,
              ),
            )
          }
          table={table}
        />
      </div>
      <AuthorizationsBlock
        authorizations={removeTypename(data?.team.authorizations)}
        editable={true}
        handleChangeAuthorizations={handleUpdatePermissions}
      />
    </div>
  );
}
