export const removeTypename = (value: any): any => {
  if (Array.isArray(value)) {
    return value.map((item) => removeTypename(item));
  }

  if (typeof value === "object") {
    const newObj: Record<any, any> = {};
    Object.keys(value).forEach((key) => {
      if (key !== "__typename") {
        newObj[key] = removeTypename(value[key]);
      }
    });
    return newObj;
  }

  return value;
};
