import { useMutation } from "@apollo/client";

import { CHECK_RUN } from "../../../../gql/instrument";

export const useCheckRun = (runId: number | undefined) => {
  const [checkRunMutation, { loading }] = useMutation(CHECK_RUN);

  const checkRun = () => {
    if (!runId) {
      return;
    }
    checkRunMutation({ variables: { runId } });
  };

  return { checkRun, loading };
};
