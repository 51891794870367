import { graphql } from "../__generated__/gql";

export const AUDIT_EVENTS = graphql(/* GraphQL */ `
  mutation AuditEvents(
    $organizationId: ID!
    $startDate: DateTime
    $endDate: DateTime
  ) {
    auditEvents(
      organizationId: $organizationId
      startDate: $startDate
      endDate: $endDate
    )
  }
`);
