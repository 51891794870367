import type { Oligo, Plate, Well, Workflow } from "./types";
import {
  addEmptyWellsToPlate,
  availableWellIndexGenerator,
  availableWellIndexGeneratorFromPlates,
  createPlate,
} from "./utils";

import type { PlateSize } from "../../../../../config/enums";
import { PLATE_SIZES } from "../components/useGetAllWellsById";

export enum WorkflowActions {
  "ADD_PLATE" = "ADD_PLATE",
  "ASSIGN_OLIGOS_TO_WELL" = "ASSIGN_OLIGOS_TO_WELL",
  "AUTO_ASSIGN" = "AUTO_ASSIGN",
  "MOVE_WELL" = "MOVE_WELL",
  "REMOVE_PLATE" = "REMOVE_PLATE",
  "RENAME_PLATE" = "RENAME_PLATE",
  "RESET_OLIGOS" = "RESET_OLIGOS",
  "RESET_PLATES" = "RESET_PLATES",
  "RESET_WELL" = "RESET_WELL",
}
export type WorkflowReducerActions =
  | {
      payload: { oligoIds: string[]; plateIndex: number; wellIndex: string };
      type: WorkflowActions.ASSIGN_OLIGOS_TO_WELL;
    }
  | {
      payload: { kit: string; name: string; size: PlateSize };
      type: WorkflowActions.ADD_PLATE;
    }
  | {
      payload: { plateIndex: number };
      type: WorkflowActions.REMOVE_PLATE;
    }
  | {
      payload: { name: string; plateIndex: number };
      type: WorkflowActions.RENAME_PLATE;
    }
  | {
      payload: { defaultKit: string; defaultSize: PlateSize };
      type: WorkflowActions.AUTO_ASSIGN;
    }
  | {
      payload: { plateIndex: number; wellIndex: string };
      type: WorkflowActions.RESET_WELL;
    }
  | {
      payload: { oligos: Oligo[] };
      type: WorkflowActions.RESET_OLIGOS;
    }
  | {
      payload: { plates: Plate[] };
      type: WorkflowActions.RESET_PLATES;
    }
  | {
      payload: {
        from: {
          plateId: string;
          wellIndex: string;
        };
        to: {
          plateId: string;
          wellIndex: string;
        };
      };
      type: WorkflowActions.MOVE_WELL;
    };

export function workflowReducer(
  workflow: Workflow,
  action: WorkflowReducerActions,
) {
  switch (action.type) {
    case WorkflowActions.ASSIGN_OLIGOS_TO_WELL: {
      return {
        ...workflow,
        plates: workflow.plates.map((plate, plateIndex) => {
          if (plateIndex !== action.payload.plateIndex) {
            return plate;
          }
          const indexGenerator = availableWellIndexGenerator(
            plate,
            action.payload.wellIndex,
          );
          const mapOfIndexToOligoId = new Map<string, string>();

          for (const oligoId of action.payload.oligoIds) {
            const wellIndex = indexGenerator.next();
            if (wellIndex.done) {
              break;
            }
            mapOfIndexToOligoId.set(wellIndex.value, oligoId);
          }

          return {
            ...plate,
            wells: plate.wells.map((well) => {
              if (mapOfIndexToOligoId.has(well.index)) {
                return {
                  ...well,
                  oligoId: mapOfIndexToOligoId.get(well.index),
                };
              }
              return well;
            }),
          };
        }),
      };
    }
    case WorkflowActions.ADD_PLATE: {
      return {
        ...workflow,
        plates: [
          ...workflow.plates,
          createPlate(
            action.payload.kit,
            action.payload.size,
            action.payload.name,
          ),
        ],
      };
    }
    case WorkflowActions.REMOVE_PLATE: {
      return {
        ...workflow,
        plates: workflow.plates.filter(
          (_, i) => i !== action.payload.plateIndex,
        ),
      };
    }
    case WorkflowActions.RENAME_PLATE: {
      return {
        ...workflow,
        plates: workflow.plates.map((plate, i) =>
          i === action.payload.plateIndex
            ? { ...plate, name: action.payload.name }
            : plate,
        ),
      };
    }
    case WorkflowActions.MOVE_WELL: {
      const { from, to } = action.payload;
      const wellFrom = (
        workflow.plates.find((p) => p.id === from.plateId) ??
        ({
          wells: [],
        } as {
          wells: Well[];
        })
      ).wells.find((w) => w.index === from.wellIndex);
      const wellTo = (
        workflow.plates.find((p) => p.id === to.plateId) ??
        ({
          wells: [],
        } as {
          wells: Well[];
        })
      ).wells.find((w) => w.index === to.wellIndex);
      const wellTargetHasOligo = Boolean(wellTo?.oligoId);
      if (!wellFrom) {
        return workflow;
      }
      const onSamePlate = from.plateId === to.plateId;
      const newWorkflow: Workflow = {
        ...workflow,
        plates: workflow.plates.map((plate) => {
          if (onSamePlate) {
            if (plate.id === from.plateId) {
              return {
                ...plate,
                wells: plate.wells.map((w) => {
                  if (w.index === from.wellIndex) {
                    return {
                      ...w,
                      oligoId: wellTargetHasOligo ? wellTo?.oligoId : undefined,
                    };
                  }
                  if (w.index === to.wellIndex) {
                    return {
                      ...w,
                      oligoId: wellFrom.oligoId,
                    };
                  }
                  return w;
                }),
              };
            }
            return plate;
          }
          if (plate.id === from.plateId) {
            return {
              ...plate,
              wells: plate.wells.map((w) => {
                if (w.index === from.wellIndex) {
                  return {
                    ...w,
                    oligoId: wellTargetHasOligo ? wellTo?.oligoId : undefined,
                  };
                }
                return w;
              }),
            };
          }
          if (plate.id === to.plateId) {
            return {
              ...plate,
              wells: plate.wells.map((w) => {
                if (w.index === to.wellIndex) {
                  return {
                    ...w,
                    oligoId: wellFrom.oligoId,
                  };
                }
                return w;
              }),
            };
          }
          return plate;
        }),
      };
      return newWorkflow;
    }
    case WorkflowActions.AUTO_ASSIGN: {
      const { defaultKit, defaultSize } = action.payload;
      const assignedOligos = new Set();
      workflow.plates.forEach((p) => {
        p.wells.forEach((w) => {
          if (w.oligoId) {
            assignedOligos.add(w.oligoId);
          }
        });
      });
      const oligosToAssign = workflow.oligos.filter(
        (o) => !assignedOligos.has(o.id),
      );
      const availableWells = workflow.plates.flatMap((p) =>
        p.wells
          .filter((w) => !w.oligoId)
          .map((w) => ({
            index: w.index,
            plateId: p.id,
          })),
      );

      const oligoGroupsToAssign = new Map<string, Oligo[]>();
      const defaultOligoGroup = "default";
      for (const oligo of oligosToAssign) {
        const key = oligo.geneId ?? defaultOligoGroup;
        const currentGroupOligos = oligoGroupsToAssign.get(key);
        oligoGroupsToAssign.set(
          key,
          currentGroupOligos ? currentGroupOligos.concat([oligo]) : [oligo],
        );
      }
      const oligoGroupsToAssignSorted = new Map<string, Oligo[]>(
        [...oligoGroupsToAssign.entries()].sort((a, b) => {
          if (a[0] < b[0]) {
            return -1;
          }
          if (a[0] > b[0]) {
            return 1;
          }
          return 0;
        }),
      );

      const updatedPlates = [...workflow.plates];
      let geneIndex = 0;
      for (const [key, oligoGroup] of oligoGroupsToAssignSorted) {
        if (key === defaultOligoGroup) {
          const wellsToCreateCount = Math.max(
            0,
            oligoGroup.length - availableWells.length,
          );
          const platesToCreate = Math.ceil(
            wellsToCreateCount / PLATE_SIZES[defaultSize].total,
          );
          const newPlates = Array.from({ length: platesToCreate }, (_, i) =>
            createPlate(defaultKit, defaultSize, `Plate created ${i + 1}`),
          );
          updatedPlates.push(...newPlates);
          const availableWellGenerator =
            availableWellIndexGeneratorFromPlates(updatedPlates);
          for (const oligo of oligoGroup) {
            const wellWithOligo = availableWellGenerator.next();
            if (wellWithOligo.done) {
              break;
            }
            const { plateIndex, wellIndex } = wellWithOligo.value;
            const wellIndexInArray = updatedPlates[plateIndex].wells.findIndex(
              (w) => w.index === wellIndex,
            );
            updatedPlates[plateIndex].wells[wellIndexInArray].oligoId =
              oligo.id;
          }
          continue;
        }

        const wellsToCreateCount = oligoGroup.length;
        const plateSize = PLATE_SIZES[defaultSize].total;
        const platesToCreate = Math.ceil(wellsToCreateCount / plateSize);
        const newPlates = Array.from({ length: platesToCreate }, (_, i) =>
          createPlate(
            defaultKit,
            defaultSize,
            `Gene ${geneIndex + 1} - plate created ${i + 1}`,
          ),
        );
        const mapOfOligoIdToPlateIndex = new Map<string, number>();
        oligoGroup.forEach((oligo) => {
          const { id: oligoId, platePositioningExpected } = oligo;
          const plateIndex =
            mapOfOligoIdToPlateIndex.get(platePositioningExpected) ?? 0;
          mapOfOligoIdToPlateIndex.set(
            platePositioningExpected,
            plateIndex + 1,
          );
          const wellIndexInArray = newPlates[plateIndex].wells.findIndex(
            (w) => w.index === platePositioningExpected,
          );
          newPlates[plateIndex].wells[wellIndexInArray].oligoId = oligoId;
        });
        updatedPlates.push(...newPlates);

        geneIndex += 1;
      }

      return {
        ...workflow,
        plates: updatedPlates,
      };
    }
    case WorkflowActions.RESET_WELL: {
      const { wellIndex, plateIndex } = action.payload;
      return {
        ...workflow,
        plates: workflow.plates.map((p, pIndex) => {
          if (plateIndex !== pIndex) {
            return p;
          }
          return {
            ...p,
            wells: p.wells.map((w) => {
              if (w.index !== wellIndex) {
                return w;
              }
              return {
                ...w,
                oligoId: undefined,
              };
            }),
          };
        }),
      };
    }
    case WorkflowActions.RESET_OLIGOS: {
      return {
        oligos: action.payload.oligos,
        plates: workflow.plates,
      };
    }
    case WorkflowActions.RESET_PLATES: {
      const platesWithEmptyWells =
        action.payload.plates.map(addEmptyWellsToPlate);
      return {
        oligos: workflow.oligos,
        plates: platesWithEmptyWells,
      };
    }
    default:
      return workflow;
  }
}
