import { useInView } from "react-intersection-observer";

import { getBottleColors } from "./consumables-colors";

import type { Consumables as ConsumablesGQL } from "../../../__generated__/graphql";

const DynamicIndicator = ({
  percentage,
  color,
  bg,
}: {
  bg: string;
  color: string;
  percentage: number;
}) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <div className="relative grow" ref={ref}>
      <div className={`relative h-10 rounded-md ${bg}`}>
        <div
          className={`transition-width absolute inset-y-0 left-0 top-1 h-8 w-10 rounded-md ${color} duration-500 ease-out hover:w-48`}
          style={{
            width: inView ? (percentage < 1 ? "1px" : `${percentage}%`) : 0,
          }}
        ></div>
      </div>
    </div>
  );
};

const Bottles = ({ bottles }: { bottles: ConsumablesGQL["bottleGroups"] }) => {
  return (
    <ul className="space-y-3">
      {bottles.map((bottleGroup) => {
        const percentageFilled =
          (bottleGroup.remainingVolume / bottleGroup.capacity) * 100;
        const { bg, color } = getBottleColors(bottleGroup);
        return (
          <div
            className="flex flex-row items-center space-x-2"
            key={bottleGroup.group}
          >
            <span>{bottleGroup.group}</span>
            <DynamicIndicator
              bg={bg}
              color={color}
              percentage={percentageFilled}
            />
          </div>
        );
      })}
    </ul>
  );
};
export default function Consumables({
  consumables,
}: {
  consumables: ConsumablesGQL | null | undefined;
}) {
  if (!consumables) return null;

  return <Bottles bottles={consumables.bottleGroups} />;
}
