import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Separator } from "@radix-ui/react-dropdown-menu";
import { Heading } from "@radix-ui/themes";
import { SaveIcon, Undo } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { useGetHamiltonInstrument } from "./useGetInstrument";

import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { UPDATE_HAMILTON_INSTRUMENT } from "../../gql/instrument";
import { OrganizationRoutes } from "../organization/organization-routes";

const hamiltonForm = z.object({
  ipAddress: z.string(),
  name: z.string().trim().min(1),
  usbPort: z.string(),
});

export type HamiltonUpdateForm = z.infer<typeof hamiltonForm>;

export default function HamiltonInstrumentEdit() {
  const { data, loading } = useGetHamiltonInstrument();
  const navigate = useNavigate();

  const form = useForm<HamiltonUpdateForm>({
    defaultValues: {
      ipAddress: data?.getHamiltonInstrument.ipAddress,
      name: data?.getHamiltonInstrument.name,
      usbPort: data?.getHamiltonInstrument.usbPort,
    },
    resolver: zodResolver(hamiltonForm),
  });

  const [updateInstrument] = useMutation(UPDATE_HAMILTON_INSTRUMENT, {
    update: (cache, { data }) => {
      if (!data) {
        return;
      }
      cache.modify({
        fields: {
          ipAddress() {
            return data?.updateHamiltonInstrument.ipAddress;
          },
          name() {
            return data?.updateHamiltonInstrument.name;
          },
          usbPort() {
            return data?.updateHamiltonInstrument.usbPort;
          },
        },
        id: cache.identify({
          __typename: "HamiltonInstrument",
          id: data?.updateHamiltonInstrument.id,
        }),
      });
    },
  });

  useEffect(() => {
    form.reset({
      ipAddress: data?.getHamiltonInstrument.ipAddress,
      name: data?.getHamiltonInstrument.name,
      usbPort: data?.getHamiltonInstrument.usbPort,
    });
  }, [data, form]);

  if (loading) return <Heading>Loading...</Heading>;

  if (!data) {
    return <Heading>Instrument not found</Heading>;
  }

  const instrument = data.getHamiltonInstrument;
  const hamiltonId = instrument.id;

  const onSubmit = (data: HamiltonUpdateForm) => {
    updateInstrument({
      variables: {
        id: instrument.id,
        payload: {
          ipAddress: data.ipAddress,
          name: data.name,
          usbPort: data.usbPort,
        },
      },
    });
    navigate(
      OrganizationRoutes.INSTRUMENT_HAMILTON.replace(
        ":instrumentId",
        hamiltonId,
      ),
    );
  };

  return (
    <div className="flex flex-col space-y-8">
      <Form {...form}>
        <form className="space-y-2" onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-row justify-between">
            <Heading className="space-x-2">
              <span>Hamilton instrument</span>
              <span className="italic">{instrument.name}</span>
            </Heading>
            <div className="flex flex-row items-center space-x-2">
              <Button
                onClick={() => {
                  navigate(
                    OrganizationRoutes.INSTRUMENT_HAMILTON.replace(
                      ":instrumentId",
                      hamiltonId,
                    ),
                  );
                }}
                type="button"
                variant={"outline"}
              >
                <Undo />
              </Button>
              <Button type="submit" variant={"outline"}>
                <SaveIcon />
              </Button>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div className="bg-card col-span-1 space-y-2 rounded-lg border p-4">
              <Heading size={"4"}>Details</Heading>
              {instrument.createdAt && (
                <>
                  <div className="flex flex-row justify-between space-x-2">
                    <p>Created at</p>
                    <p>{new Date(instrument.createdAt).toLocaleString()}</p>
                  </div>
                  <Separator />
                </>
              )}
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Separator />
                <FormField
                  control={form.control}
                  name="ipAddress"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>IP Address</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="192.168.0.1" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Separator />
                <FormField
                  control={form.control}
                  name="usbPort"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>USB port</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="COM4" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="bg-card col-span-1 space-y-2 rounded-lg border p-4">
              <Heading size={"4"}>Teach points</Heading>
              <div className="flex flex-row justify-between space-x-2">
                <p>Coming soon...</p>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}
