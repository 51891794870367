import { useMutation } from "@apollo/client";
import { Download } from "lucide-react";
import { useState } from "react";

import { RunReportFileFormat } from "../../../../../__generated__/graphql";
import { Button } from "../../../../../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import { useToast } from "../../../../../components/ui/use-toast";
import { DOWNLOAD_REPORT } from "../../../../../gql/run";
import { useDownloadFile } from "../../../../../utils/useDownload";

export default function DownloadReport({ runId }: { runId: number }) {
  const [format, setFormat] = useState<RunReportFileFormat>(
    RunReportFileFormat.Pdf,
  );
  const { toast } = useToast();
  const downloadFile = useDownloadFile();

  const [downloadReport] = useMutation(DOWNLOAD_REPORT, {
    onCompleted: async (data) => {
      const url = data.downloadReport;
      try {
        await downloadFile(url, "report.pdf");
      } catch (error) {
        const message =
          "message" in (error as any) ? (error as any).message : "";
        toast({
          description: message,
          title: "Error when downloading report",
          variant: "destructive",
        });
      }
    },
    onError: (error) => {
      toast({
        description: error.message,
        title: "Error when downloading report",
        variant: "destructive",
      });
    },
  });

  const handleDownload = () => {
    downloadReport({
      variables: {
        format,
        id: runId,
      },
    });
  };

  return (
    <div className="flex flex-row items-center space-x-1 rounded-lg border px-2">
      <Button className="space-x-1" onClick={handleDownload} variant={"ghost"}>
        <span>Download</span>
        <Download />
      </Button>
      <Select
        onValueChange={(v) => setFormat(v as RunReportFileFormat)}
        value={format}
      >
        <SelectTrigger className="h-8 w-[70px]">
          <SelectValue />
        </SelectTrigger>
        <SelectContent side="top">
          <SelectItem value={RunReportFileFormat.Csv}>CSV</SelectItem>
          <SelectItem value={RunReportFileFormat.Pdf}>PDF</SelectItem>
          <SelectItem value={RunReportFileFormat.Json}>Json</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
}
