import { useMutation, useQuery } from "@apollo/client";
import { Heading } from "@radix-ui/themes";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { RefreshCcw, Trash } from "lucide-react";
import { Link } from "react-router-dom";

import type { EnvironmentInstrumentsQuery } from "../../../__generated__/graphql";
import { Button } from "../../../components/ui/button";
import { TableActions } from "../../../components/ui/data-table/data-table-actions";
import { SimpleTable } from "../../../components/ui/simple-table";
import {
  DELETE_INSTRUMENT_BY_SN,
  GET_ENVIRONMENT_INSTRUMENTS,
} from "../../../gql/instrument";
import { AdminRoutes } from "../adminRoutes";

const DeleteInstrument = ({ serialNumber }: { serialNumber: string }) => {
  const [deleteInstrument] = useMutation(DELETE_INSTRUMENT_BY_SN, {
    refetchQueries: [
      {
        query: GET_ENVIRONMENT_INSTRUMENTS,
      },
    ],
  });
  const handleDeleteInstrument = () => {
    deleteInstrument({
      variables: {
        serialNumber,
      },
    });
  };

  return (
    <TableActions
      items={[
        {
          alertDialog: {
            message:
              "Are you sure you want to delete this instrument ? This action cannot be undone.",
            title: "Delete instrument",
          },
          children: (
            <div className="flex items-center gap-2">
              <Trash /> Delete
            </div>
          ),
          id: "Delete instrument",
          onClick: handleDeleteInstrument,
        },
      ]}
    />
  );
};

type MqttInstrument = EnvironmentInstrumentsQuery["environmentInstruments"][0];

const columnHelper = createColumnHelper<MqttInstrument>();

const columns = [
  columnHelper.accessor("serialNumber", {
    cell: (info) => info.getValue(),
    header: "Serial number",
  }),
  columnHelper.accessor("organization", {
    cell: (info) => {
      const { name, id } = info.getValue();
      return (
        <Link
          className="text-blue-500 hover:underline"
          to={AdminRoutes.ORGANIZATION_SETTINGS.replace(":organizationId", id)}
        >
          {name}
        </Link>
      );
    },
    header: "Organization",
  }),
  columnHelper.accessor("softwareVersion", {
    cell: (info) => info.getValue(),
    header: "Software",
  }),
  columnHelper.display({
    cell: (info) => (
      <DeleteInstrument serialNumber={info.row.original.serialNumber} />
    ),
    id: "delete",
  }),
];

export default function AllInstruments() {
  const { data, refetch, loading } = useQuery(GET_ENVIRONMENT_INSTRUMENTS);
  const table = useReactTable({
    columns,
    data: data?.environmentInstruments ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  const handleRefetch = () => {
    refetch();
  };

  return (
    <div className="bg-card rounded-lg border p-4">
      <div className=" flex items-center justify-between">
        <Heading>Instruments dashboard</Heading>
        <Button
          className="space-x-1"
          disabled={loading}
          onClick={handleRefetch}
          type="button"
        >
          <RefreshCcw />
          <span>Refresh</span>
        </Button>
      </div>
      <SimpleTable table={table} />
    </div>
  );
}
