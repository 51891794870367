import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { v4 } from "uuid";

import type { WorkflowReducerActions } from "./reducer";
import { WorkflowActions, workflowReducer } from "./reducer";
import type { Oligo, Plate, Workflow } from "./types";
import { getRowColumnFromWellIndex } from "./utils";

import { trpc } from "../../../../../config/trpc";
import { useGetAvailableKits } from "../../../../settings/organization-settings/hooks";

type WorkflowContext = {
  assayId: string;
  dispatchWorkflowChange: React.Dispatch<WorkflowReducerActions>;
  handleClickOligo: (
    e: React.MouseEvent<HTMLTableRowElement>,
    oligoId: string,
  ) => void;
  kitToAdd: string;
  oligoToWellsMap: Map<
    string,
    {
      plateId: string;
      wellIndex: string;
    }[]
  >;
  plateIdToPlate: Map<string, Plate>;
  selectedOligoIds: string[];
  setKitToAdd: React.Dispatch<React.SetStateAction<string>>;
  workflow: Workflow;
};

const WorkflowBuildContext = createContext<WorkflowContext>({
  assayId: "",
  dispatchWorkflowChange: () => {},
  handleClickOligo: () => {},
  kitToAdd: "",
  oligoToWellsMap: new Map(),
  plateIdToPlate: new Map(),
  selectedOligoIds: [],
  setKitToAdd: () => {},
  workflow: {
    oligos: [],
    plates: [],
  },
});

export const WorkflowBuildProvider = ({
  children,
  assayId,
}: {
  assayId: string;
  children: React.ReactNode;
}) => {
  const [workflow, dispatchWorkflowChange] = useReducer(workflowReducer, {
    oligos: [],
    plates: [],
  });
  const [selectedOligoIds, setSelectedOligoIds] = useState<string[]>([]);
  const [lastSelectedOligoId, setLastSelectedOligoId] = useState<string | null>(
    null,
  );
  const handleClickOligo = useCallback(
    (e: React.MouseEvent<HTMLTableRowElement>, oligoId: string) => {
      if (e.shiftKey && lastSelectedOligoId) {
        const lastSelectedIndex = workflow.oligos.findIndex(
          (o) => o.id === lastSelectedOligoId,
        );
        const currentIndex = workflow.oligos.findIndex((o) => o.id === oligoId);
        const start = Math.min(lastSelectedIndex, currentIndex);
        const end = Math.max(lastSelectedIndex, currentIndex);
        setSelectedOligoIds(
          workflow.oligos.slice(start, end + 1).map((o) => o.id),
        );
        return;
      }
      setLastSelectedOligoId(oligoId);
      if (e.metaKey) {
        return setSelectedOligoIds((prev) => {
          if (prev.includes(oligoId)) {
            return prev.filter((id) => id !== oligoId);
          }
          return [...prev, oligoId];
        });
      }
      if (selectedOligoIds.length === 1 && selectedOligoIds[0] === oligoId) {
        setSelectedOligoIds([]);
        return;
      }
      setSelectedOligoIds([oligoId]);
    },
    [lastSelectedOligoId, selectedOligoIds, workflow.oligos],
  );

  const availableKits = useGetAvailableKits();

  const { data: dataOligos } = trpc.assay.oligos.useQuery(assayId);
  const { data: dataPlates } = trpc.assay.steps.plates.useQuery(assayId);

  useEffect(() => {
    const oligos =
      dataOligos?.filter((d) => d).filter((o): o is Oligo => !!o) ?? [];
    dispatchWorkflowChange({
      payload: { oligos },
      type: WorkflowActions.RESET_OLIGOS,
    });
  }, [dataOligos]);

  useEffect(() => {
    dispatchWorkflowChange({
      payload: {
        plates: (dataPlates ?? []).map((p) => ({
          ...p,
          id: p.id ?? v4(),
          wells: p.wells.map((w) => {
            const { row, column } = getRowColumnFromWellIndex(w.index);
            return {
              column,
              index: w.index,
              oligoId: w.oligoId,
              row,
            };
          }),
        })),
      },
      type: WorkflowActions.RESET_PLATES,
    });
  }, [dataPlates]);

  const [kitToAdd, setKitToAdd] = useState(availableKits.sort()[0]);

  const oligoToWellsMap = useMemo(() => {
    const oligoMap = new Map<
      string,
      { plateId: string; wellIndex: string }[]
    >();
    const wells = workflow.plates
      .map((p) =>
        p.wells
          .filter((w) => w.oligoId)
          .map((w) => ({
            oligoId: w.oligoId!,
            plateId: p.id,
            wellIndex: w.index,
          })),
      )
      .flat();
    workflow.oligos.forEach((o) => {
      const wellsWithOligo = wells.filter((w) => w.oligoId === o.id);
      if (wellsWithOligo.length === 0) {
        return;
      }
      oligoMap.set(
        o.id,
        wellsWithOligo.map((w) => ({
          plateId: w.plateId,
          wellIndex: w.wellIndex,
        })),
      );
    });
    return oligoMap;
  }, [workflow.oligos, workflow.plates]);

  const plateIdToPlate = useMemo(() => {
    const map = new Map<string, Plate>();
    workflow.plates.forEach((p) => {
      map.set(p.id, p);
    });
    return map;
  }, [workflow.plates]);

  return (
    <WorkflowBuildContext.Provider
      value={{
        assayId,
        dispatchWorkflowChange,
        handleClickOligo,
        kitToAdd,
        oligoToWellsMap,
        plateIdToPlate,
        selectedOligoIds,
        setKitToAdd,
        workflow,
      }}
    >
      {children}
    </WorkflowBuildContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useWorkflowBuildContext = () => {
  return useContext(WorkflowBuildContext);
};
