import { TooltipPortal } from "@radix-ui/react-tooltip";
import { AlertTriangle, Fuel } from "lucide-react";

import type { PlateFromWorkflow } from "./plate/types";

import type { AvailableInstrumentsQuery } from "../../../../../__generated__/graphql";
import { InstrumentStatus } from "../../../../../__generated__/graphql";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import { cn } from "../../../../../lib/utils";
import { useGetKitLabels } from "../../../../admin/organizations/organization/components/useGetEnvironmentKits";
import { getBottleColors } from "../../../../instrument/components/consumables-colors";
import { statusMapWithName } from "../../../../instrument/components/status-map";

type AvailableInstrument = AvailableInstrumentsQuery["availableInstruments"][0];

const SynthesisKit = ({
  bottleGroup,
}: {
  bottleGroup:
    | NonNullable<AvailableInstrument["consumables"]>["bottleGroups"][0]
    | undefined;
}) => {
  const synthesisColors = bottleGroup ? getBottleColors(bottleGroup) : null;
  if (!bottleGroup || !synthesisColors) return null;

  const percentage = (bottleGroup.remainingVolume / bottleGroup.capacity) * 100;

  return (
    <div className="flex flex-row items-center justify-between space-x-3">
      <span>Synthesis kit</span>
      <div
        className={cn("relative h-[16px] grow rounded-md", synthesisColors.bg)}
      >
        <div
          className={`absolute top-[2px] h-[12px] grow rounded-md ${synthesisColors.color} duration-500 ease-out hover:w-48`}
          style={{
            width: `${percentage}%`,
          }}
        />
        <span className="absolute right-0 top-[-2px] z-20">
          {percentage.toFixed(1)}%
        </span>
      </div>
    </div>
  );
};

export default function SyntaxInstrumentOption({
  instrument,
  plate,
}: {
  instrument: AvailableInstrument;
  plate: PlateFromWorkflow;
}) {
  const getKitLabel = useGetKitLabels();
  const { cycles: plateCycles, kit } = plate;
  const { consumables, statusDetails } = instrument;
  const currentLoadedKit = consumables?.kitId;
  const kitsMatch = currentLoadedKit === kit;
  const hasEnoughCycles =
    consumables && consumables?.remainingCycles >= plateCycles;
  const hasEnoughProperConsumables = hasEnoughCycles && kitsMatch;
  const synthesisGroupBottle = consumables?.bottleGroups.find(
    (bottleGroup) => bottleGroup.group === "SYN",
  );

  const fuelColor =
    instrument.status === InstrumentStatus.Available
      ? hasEnoughProperConsumables
        ? "text-green-500"
        : "text-orange-500"
      : "text-gray-500";

  return (
    <Tooltip delayDuration={100}>
      <TooltipTrigger asChild>
        <div className="grid w-full flex-grow grid-cols-5 flex-row items-center justify-between">
          <span className="col-span-3 text-left">{instrument.name}</span>
          <p
            className={cn(
              "col-span-1 flex flex-row items-center space-x-1",
              fuelColor,
            )}
          >
            <Fuel />
          </p>
          <span className="col-span-1">
            {statusMapWithName[instrument.status]}
          </span>
        </div>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent
          className="min-w-[270px] space-y-1"
          side="right"
          sideOffset={20}
        >
          <p className="flex flex-row justify-between">
            <span>Remaining cycles</span>
            <p className="flex flex-row items-center">
              <span>{consumables?.remainingCycles ?? "N/A"}</span>
              {kitsMatch && !hasEnoughCycles && (
                <AlertTriangle className="text-orange-500" />
              )}
            </p>
          </p>
          <SynthesisKit bottleGroup={synthesisGroupBottle} />
          {currentLoadedKit && (
            <p className="flex flex-row items-center justify-between">
              <span>Current kit loaded</span>
              <p className="flex flex-row items-center">
                <span>{getKitLabel(currentLoadedKit)}</span>
                {!kitsMatch && <AlertTriangle className="text-orange-500" />}
              </p>
            </p>
          )}
          <p className="flex flex-row justify-between">
            <span>Clean system ?</span>
            <span>{"N/A"}</span>
          </p>
          <p className="flex flex-row justify-between">
            <span>Waste level</span>
            <span>{statusDetails?.waste?.bottle?.level}</span>
          </p>
        </TooltipContent>
      </TooltipPortal>
    </Tooltip>
  );
}
