import { zodResolver } from "@hookform/resolvers/zod";
import { SaveIcon, UploadIcon } from "lucide-react";
import { useEffect, useState } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import PickConcentration from "./components/pick-concentration";
import PickKit from "./components/pick-kit";
import { useGetCurrentKitConcentrations } from "./hooks/useGetCurrentKitConcentrations";

import { Button } from "../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/ui/form";
import { Input } from "../../../../components/ui/input";
import { LoadingSpinner } from "../../../../components/ui/spinner";
import { Normalization } from "../../../../config/enums";
import { trpc } from "../../../../config/trpc";
import { cn } from "../../../../lib/utils";
import { useGetAvailableKits } from "../../../settings/organization-settings/hooks";
import { OrganizationRoutes } from "../../organization-routes";
import OligoSet from "../construct/components/oligo-set";
import { oligosSchema } from "../construct/edit-construct";

const quickPlateForm = z.object({
  kit: z.string(),
  name: z.string().trim().min(1),
  oligos: oligosSchema,
  quantification: z.object({
    defaultConcentration: z.number().optional(),
    defaultNormalization: z.nativeEnum(Normalization),
  }),
});

type QuickPlateForm = z.infer<typeof quickPlateForm>;

export const QuickPlateUploadButton = React.forwardRef(() => {
  const navigate = useNavigate();
  return (
    <Button
      className="space-x-1"
      onClick={() => navigate(OrganizationRoutes.QUICK_PLATE)}
      variant={"outline"}
    >
      <span>Quick plate</span>
      <UploadIcon />
    </Button>
  );
});
QuickPlateUploadButton.displayName = "QuickPlateUploadButton";

export default function QuickPlateUpload() {
  const navigate = useNavigate();
  const [localConcentration, setLocalConcentration] = useState<number[]>([0]);

  const form = useForm<QuickPlateForm>({
    defaultValues: {
      kit: "",
      name: "",
      oligos: [],
      quantification: {
        defaultNormalization: Normalization.None,
      },
    },
    resolver: zodResolver(quickPlateForm),
  });
  const availableKits = useGetAvailableKits();
  const currentKit = form.watch("kit");
  const { kitConcentrationMax, kitConcentrationMin } =
    useGetCurrentKitConcentrations(currentKit);

  useEffect(() => {
    form.setValue("kit", availableKits[0]);
  }, [availableKits, form]);

  const { mutate: createPlate, isPending } =
    trpc.assay.createQuickPlate.useMutation({
      onSuccess(url) {
        navigate(url);
      },
    });

  const handleSavePlate = async (data: QuickPlateForm) => {
    createPlate({
      concentration: data.quantification.defaultConcentration,
      kit: data.kit,
      name: data.name,
      normalization: data.quantification.defaultNormalization,
      oligos: data.oligos.map((o) => ({
        name: o.name,
        placement: o.wellHint ?? undefined,
        sequence: o.sequence,
      })),
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Quick plate upload</CardTitle>
        <CardDescription>Quickly get to a plate ready to print</CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form
            className={cn(
              "flex flex-col space-y-4",
              isPending ? "pointer-events-none opacity-70" : "",
            )}
            onSubmit={form.handleSubmit(handleSavePlate)}
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input className="w-1/2" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="oligos"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Oligos</FormLabel>
                  <FormControl>
                    <OligoSet
                      data={field.value}
                      isLocked={false}
                      setData={(oligos) => {
                        const newOligos =
                          typeof oligos === "function"
                            ? oligos(field.value)
                            : oligos;
                        field.onChange(newOligos);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="kit"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Kit</FormLabel>
                  <FormControl className="mt-0">
                    <PickKit
                      onChange={(value) => field.onChange(value)}
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="quantification"
              render={({ field }) => (
                <FormItem className="flex flex-col space-x-2">
                  <div>
                    <FormLabel>Default Normalization</FormLabel>
                    <FormDescription>
                      Choose a default normalization and potentially
                      concentration to use for all plates. You can change it for
                      each well later on.
                    </FormDescription>
                  </div>
                  <FormControl className="mt-0">
                    <div className="flex flex-row pb-6">
                      <PickConcentration
                        handleApplyConcentrationChange={(
                          normalization,
                          concentration,
                        ) => {
                          const concentrationToSet =
                            normalization === Normalization.Target &&
                            !concentration
                              ? kitConcentrationMin
                              : concentration;
                          if (concentrationToSet) {
                            setLocalConcentration([concentrationToSet]);
                          }
                          field.onChange({
                            defaultConcentration: concentrationToSet,
                            defaultNormalization: normalization,
                          });
                        }}
                        isEditable={true}
                        kitConcentrationMax={kitConcentrationMax}
                        kitConcentrationMin={kitConcentrationMin}
                        localConcentration={localConcentration}
                        normalization={field.value.defaultNormalization}
                        setLocalConcentration={setLocalConcentration}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              className="flex max-w-[50%] flex-row items-center space-x-1"
              type="submit"
            >
              <span>Submit</span>
              <SaveIcon />
            </Button>
            {isPending && (
              <div className="flex flex-row justify-center">
                <LoadingSpinner />
              </div>
            )}
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
