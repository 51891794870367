import { graphql } from "../__generated__/gql";

export const ADD_USERS_TO_ORGANIZATION = graphql(/* GraphQL */ `
  mutation AddUsersToOrganization(
    $organizationId: ID!
    $emails: [String!]!
    $permissions: AuthorizationInput!
  ) {
    addUsersToOrganization(
      organizationId: $organizationId
      emails: $emails
      permissions: $permissions
    )
  }
`);
