import type { inferProcedureOutput } from "@trpc/server";
import { createContext, useContext } from "react";

import type { AppRouter, ConfigTRPC } from "../config/trpc";

export type MyOrganization = inferProcedureOutput<
  AppRouter["account"]["organization"]["readMine"]
>;

export const OrganizationContext = createContext<MyOrganization | undefined>(
  undefined,
);

export type Myself = inferProcedureOutput<
  AppRouter["account"]["user"]["myself"]
>;
export type MembershipData = inferProcedureOutput<
  AppRouter["account"]["user"]["membership"]
>;

export type UserContext =
  | (Myself &
      Partial<MembershipData> & {
        membership: Myself["memberships"][0] | null;
      })
  | null;

export const UserContext = createContext<UserContext>(null);

export const EnvironmentContext = createContext<ConfigTRPC>({
  config: {
    docsUrl: "",
    isDatadogActivated: false,
  },
  environment: "Unknown",
  version: "Unknown",
});

export const useEnvironmentContext = () => {
  return useContext(EnvironmentContext);
};

export const useIsDevelopment = () => {
  return useEnvironmentContext().environment === "development";
};
