import { z } from "zod";

import type {
  AuthorizationInput,
  MemberQuery,
} from "../../../__generated__/graphql";
import { AuthorizationValue } from "../../../__generated__/graphql";

type RemoveTypename<T extends Record<string, any>> = {
  [K in keyof T as Exclude<K, "__typename">]: T[K];
};
export type OrganizationMemberAuthorizations = RemoveTypename<
  MemberQuery["member"]["authorizations"]
>;

export type Description = {
  badgeLabel: string;
  details: string;
  label: string;
};
type AuthorizationDescriptions = {
  [K in keyof OrganizationMemberAuthorizations]: OrganizationMemberAuthorizations[K] extends boolean
    ? Description
    : OrganizationMemberAuthorizations[K] extends AuthorizationValue
      ? {
          [AuthorizationValue.Admin]: Description;
          [AuthorizationValue.Reader]: Description;
          [AuthorizationValue.Creator]: Description;
        }
      : never;
};

export const AuthorizationToDescriptions = {
  builder: {
    [AuthorizationValue.Admin]: {
      badgeLabel: "Trigger runs and manage runs",
      details:
        "Allows the user to send plates to the instrument, manage runs and their result",
      label: "Builder",
    },
    [AuthorizationValue.Creator]: {
      badgeLabel: "Trigger, manage and read own runs",
      details:
        "Allows the user to send plates to the instrument, manage runs and their result, but restricts user from reading other users' runs",
      label: "Builder",
    },
    [AuthorizationValue.Reader]: {
      badgeLabel: "Read run reports",
      details: "Allows the user to read builds",
      label: "Builder",
    },
  },
  designer: {
    [AuthorizationValue.Admin]: {
      badgeLabel: "Manage designs",
      details:
        "Allows the user to create and edit designs: constructs, assays, workflows...",
      label: "Designer",
    },
    [AuthorizationValue.Creator]: {
      badgeLabel: "Manage own designs",
      details:
        "Allows the user to create and edit designs, but restricts user from reading other users' designs",
      label: "Designer",
    },
    [AuthorizationValue.Reader]: {
      badgeLabel: "Read designs",
      details: "Allows the user to read designs",
      label: "Designer",
    },
  },
  developer: {
    badgeLabel: "Use the API",
    details: "Allows the user to use api keys to access the API",
    label: "Developer",
  },
  instrument: {
    [AuthorizationValue.Admin]: {
      badgeLabel: "Manage instrument",
      details: "Allows the user to add instruments, manage them.",
      label: "Instrument admin",
    },
    [AuthorizationValue.Creator]: {
      badgeLabel: "Manage own instrument",
      details:
        "Allows the user to add and manage instruments, but restricts user from reading other users' instruments",
      label: "Instrument admin",
    },
    [AuthorizationValue.Reader]: {
      badgeLabel: "Read instrument",
      details: "Allow the user to see instruments",
      label: "Instrument reader",
    },
  },
  manager: {
    [AuthorizationValue.Admin]: {
      badgeLabel: "Manage organization",
      details:
        "Allows the user to edit the organization settings, add new members to the organization, edit members, create teams, edit support users etc...",
      label: "Manager",
    },
    [AuthorizationValue.Creator]: {
      badgeLabel: "Invalid option",
      details: "",
      label: "Invalid option",
    },
    [AuthorizationValue.Reader]: {
      badgeLabel: "Read organization",
      details:
        "Allows the user to read the organization users, teams, and settings.",
      label: "Manager",
    },
  },
} satisfies AuthorizationDescriptions;

export const DEFAULT_AUTHORISATIONS: AuthorizationInput = {
  builder: AuthorizationValue.None,
  designer: AuthorizationValue.None,
  developer: false,
  instrument: AuthorizationValue.None,
  manager: AuthorizationValue.None,
} satisfies AuthorizationInput;

export const DEFAULT_ADMIN_AUTHORISATIONS: AuthorizationInput = {
  builder: AuthorizationValue.Admin,
  designer: AuthorizationValue.Admin,
  developer: true,
  instrument: AuthorizationValue.Admin,
  manager: AuthorizationValue.Admin,
} satisfies AuthorizationInput;

export const authorizationForm = z.object({
  builder: z.nativeEnum(AuthorizationValue),
  designer: z.nativeEnum(AuthorizationValue),
  developer: z.boolean(),
  instrument: z.nativeEnum(AuthorizationValue),
  manager: z.nativeEnum(AuthorizationValue),
});
