import { useContext } from "react";

import type { AuthorizedCreations } from "../../__generated__/graphql";
import { UserContext } from "../contexts";

export const useGetUIAuthorizedCreations = (): AuthorizedCreations => {
  const userContext = useContext(UserContext);

  return {
    assay: false,
    construct: false,
    hamiltonInstrument: false,
    instrument: false,
    organization: false,
    organizationMember: false,
    plate: false,
    team: false,
    user: false,
    workflow: false,
    ...(userContext?.authorizedCreations ?? {}),
  };
};
