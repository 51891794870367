import { useQuery } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { Heading } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import { getQueryKey } from "@trpc/react-query";
import type { inferProcedureOutput } from "@trpc/server";
import { UserPlus } from "lucide-react";
import { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { z } from "zod";

import { SelectColumn } from "./select-column";

import type {
  AuthorizationSubject,
  MembersWithoutAccessToQuery,
} from "../../__generated__/graphql";
import { queryClient, trpc, type AppRouter } from "../../config/trpc";
import { GET_MEMBERS_WITH_NO_ACCESS } from "../../gql/instrument";
import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import { DataTable } from "../ui/data-table/data-table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Separator } from "../ui/separator";
import { Switch } from "../ui/switch";
import { useToast } from "../ui/use-toast";

type Member = MembersWithoutAccessToQuery["membersWithoutAccessTo"][0];
type Team = inferProcedureOutput<
  AppRouter["account"]["authorization"]["teamsWithoutAccessToObject"]
>[0];

const memberColumns: ColumnDef<Member>[] = [
  SelectColumn(({ row }) => (
    <Badge variant={"secondary"}>{row.original?.user?.name}</Badge>
  )),
  {
    accessorKey: "user.email",
    cell: (info) => info.getValue(),
    header: "Email",
    id: "email",
  },
  {
    accessorKey: "user.name",
    cell: (info) => info.getValue(),
    header: "Name",
    id: "name",
  },
];

const teamColumns: ColumnDef<Team>[] = [
  SelectColumn(({ row }) => (
    <Badge variant={"secondary"}>{row.original?.name}</Badge>
  )),
  {
    accessorKey: "name",
    cell: (info) => info.getValue(),
    header: "Name",
    id: "name",
  },
];

const addOwnersForm = z.object({
  asAdmin: z.boolean(),
  members: z.array(z.object({ id: z.string() })),
  teams: z.array(z.object({ id: z.string() })),
});

export type AddOwners = z.infer<typeof addOwnersForm>;

export default function AddOwner({
  objectName,
  objectId,
  subject,
}: {
  objectId: string;
  objectName: string;
  subject: AuthorizationSubject;
}) {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);

  const { data: membersData } = useQuery(GET_MEMBERS_WITH_NO_ACCESS, {
    variables: { id: objectId, subject },
  });

  const { data: teamsWithoutAccessTo } =
    trpc.account.authorization.teamsWithoutAccessToObject.useQuery(
      {
        id: objectId,
        subject,
      },
      {
        initialData: [],
      },
    );

  const membersWithoutAccessTo = membersData?.membersWithoutAccessTo ?? [];

  const { mutate: addOwners } =
    trpc.account.authorization.addOwners.useMutation({
      onError(err) {
        toast({
          description: err.message,
          title: "Error",
          variant: "destructive",
        });
        setOpen(false);
      },
      onSuccess() {
        queryClient.invalidateQueries({
          queryKey: getQueryKey(
            trpc.account.authorization.teamsWithoutAccessToObject,
          ),
        });
        setOpen(false);
      },
    });

  function onSubmit(values: AddOwners) {
    addOwners({
      asAdmin: values.asAdmin,
      id: objectId,
      members: values.members.map((m) => m.id),
      subjectType: subject,
      teams: values.teams.map((t) => t.id),
    });
  }

  const form = useForm<AddOwners>({
    defaultValues: {
      asAdmin: false,
      members: [],
      teams: [],
    },
    resolver: zodResolver(addOwnersForm),
  });

  const { fields: membersFields } = useFieldArray({
    control: form.control,
    name: "members",
  });
  const { fields: teamsFields } = useFieldArray({
    control: form.control,
    name: "teams",
  });
  const container = document.getElementById("detail");

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button
          aria-label="add-user-to-owners"
          className="flex items-center justify-center space-x-1"
          variant={"outline"}
        >
          <span>Add owner</span>
          <UserPlus />
        </Button>
      </DialogTrigger>
      <DialogContent
        className="min-w-[700px]"
        container={container || undefined}
      >
        <Form {...form}>
          <form
            className="flex max-h-[90vh] flex-col space-y-4"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <DialogHeader>
              <DialogTitle>{`Add owner to ${objectName}`}</DialogTitle>
            </DialogHeader>
            <div className="flex h-14 items-center justify-between">
              <FormField
                control={form.control}
                name="asAdmin"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                    <FormControl className="mt-0">
                      <Switch
                        checked={field.value}
                        className="mt-0"
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div>
                      <FormLabel>Admin ?</FormLabel>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex flex-col">
                <p className="flex space-x-1">
                  <span>{membersFields.length}</span>
                  <span>members selected</span>
                </p>
                <p className="flex space-x-1">
                  <span>{teamsFields.length}</span>
                  <span>teams selected</span>
                </p>
              </div>
              <Button
                disabled={!membersFields.length && !teamsFields.length}
                type="submit"
              >
                Submit
              </Button>
            </div>
            <div className="space-y-2 overflow-auto">
              <Heading size="4">Add user</Heading>
              <div className="px-2">
                <DataTable
                  columns={memberColumns}
                  data={membersWithoutAccessTo}
                  disableQueryParams
                  onRowClick={(row) => row.getToggleSelectedHandler()({})}
                  onSelectionChange={(members) => {
                    form.setValue(
                      "members",
                      members.map((member) => ({
                        id: membersWithoutAccessTo[parseInt(member.id)].id,
                      })),
                    );
                  }}
                />
              </div>
              <Separator orientation="horizontal" />
              <Heading size="4">Add team</Heading>
              <div className="px-2">
                <DataTable
                  columns={teamColumns}
                  data={teamsWithoutAccessTo}
                  disableQueryParams
                  onRowClick={(row) => row.getToggleSelectedHandler()({})}
                  onSelectionChange={(teams) => {
                    form.setValue(
                      "teams",
                      teams.map((team) => ({
                        id: teamsWithoutAccessTo[parseInt(team.id)].id,
                      })),
                    );
                  }}
                />
              </div>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
