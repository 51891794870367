import { getQueryKey } from "@trpc/react-query";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import type { EditUserFormType } from "./components/edit-user-form";
import EditUserForm from "./components/edit-user-form";
import type { UserDataTRPC } from "./hooks";
import { useUserData } from "./hooks";

import { useToast } from "../../../components/ui/use-toast";
import { AccountStatus } from "../../../config/enums";
import { queryClient, trpc } from "../../../config/trpc";
import { AdminRoutes } from "../adminRoutes";

const getDefaultValues = (data: UserDataTRPC | undefined) => ({
  email: data?.email ?? "",
  firstname: data?.firstname ?? "",
  isActive: data?.status === AccountStatus.ACTIVE,
  isAdmin: data?.isAdmin,
  isSupport: data?.isSupport,
  lastname: data?.lastname ?? "",
});

export default function UserUpdate() {
  const { userId } = useParams();
  const { data } = useUserData(userId);
  const navigate = useNavigate();
  const { toast } = useToast();

  const defaultValues = useMemo(() => getDefaultValues(data), [data]);

  const { mutate: updateUser } = trpc.account.user.update.useMutation({
    onError(error) {
      toast({
        description: `${error.message}`,
        title: "Error updating user",
        variant: "destructive",
      });
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [getQueryKey(trpc.account.user.get, { id: userId })],
      });
      navigate(AdminRoutes.USER.replace(":userId", userId ?? ""));
    },
  });

  function onSubmit(values: EditUserFormType) {
    updateUser({
      email: values.email ?? "",
      firstname: values.firstname ?? "",
      id: userId ?? "",
      isAdmin: values.isAdmin,
      isSupport: values.isSupport,
      lastname: values.lastname ?? "",
      setPasswordAtLogin: null,
      status: values.isActive ? AccountStatus.ACTIVE : AccountStatus.INACTIVE,
    });
  }

  if (!data) {
    return null;
  }

  return (
    <div>
      <h3 className="font-bold">{`Editing user ${data?.email}`}</h3>
      <EditUserForm
        defaultValues={defaultValues}
        onCancel={() =>
          navigate(AdminRoutes.USER.replace(":userId", userId ?? ""))
        }
        onSubmit={onSubmit}
        useActiveField
      />
    </div>
  );
}
