"use client";

import { ChevronsUpDown, XCircle } from "lucide-react";
import { useState } from "react";

import { Badge } from "./badge";

import { Button } from "@/components/ui/button";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

function stringToColor(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).slice(-2);
  }
  return color;
}

export const ComboboxOption = ({
  option,
  children,
  hasCustomColor,
}: React.PropsWithChildren<{ hasCustomColor: boolean; option: string }>) => {
  return (
    <Badge
      className="flex flex-row items-center space-x-1"
      {...(hasCustomColor
        ? { style: { backgroundColor: stringToColor(option) } }
        : { variant: "outline" })}
    >
      <span>{option}</span>
      {children}
    </Badge>
  );
};

export const ComboboxBadge = ({ label }: { label: string }) => {
  return (
    <Badge
      className="flex flex-row items-center space-x-1"
      style={{ backgroundColor: stringToColor(label) }}
    >
      <span>{label}</span>
    </Badge>
  );
};

export default function Combobox({
  options,
  setOptions,
  values,
  setValues,
  create = true,
  inputTitle = "Select tags...",
  hasCustomColor = true,
}: {
  create?: boolean;
  hasCustomColor?: boolean;
  inputTitle?: string;
  options: string[];
  setOptions: (newOptions: string[]) => void;
  setValues: (newValues: string[]) => void;
  values: string[];
}) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const addOptionToValues = (optionValue: string) => {
    const newValues = values
      .filter((v) => v !== optionValue)
      .concat(optionValue);
    setValues(newValues);
  };

  const addSearchToOptionsAndValues = (searchValue: string) => {
    setOptions([
      ...options.filter((option) => option !== searchValue),
      searchValue,
    ]);
    addOptionToValues(searchValue);
  };

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <PopoverTrigger asChild>
        <Button
          aria-expanded={open}
          className="h-100 w-full max-w-[500px] justify-between overflow-y-scroll p-1"
          role="combobox"
          variant="outline"
        >
          <div className="flex flex-wrap">
            {values.length > 0 ? (
              <>
                {values.map((value) => {
                  const option = options.find((o) => o === value);
                  if (!option) {
                    return null;
                  }
                  return (
                    <div className="m-1" key={option}>
                      <ComboboxOption
                        hasCustomColor={hasCustomColor}
                        option={option}
                      >
                        <XCircle
                          className="cursor-pointer hover:opacity-80"
                          onClick={(e) => {
                            e.stopPropagation();
                            setValues(values.filter((v) => v !== value));
                          }}
                        />
                      </ComboboxOption>
                    </div>
                  );
                })}
              </>
            ) : (
              <span>{inputTitle}</span>
            )}
          </div>

          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          <CommandInput
            onValueChange={setSearch}
            placeholder="Search..."
            value={search}
          />
          <CommandGroup className="max-h-[300px] overflow-y-scroll">
            {options.map((option) => (
              <CommandItem
                key={option}
                onSelect={() => {
                  addOptionToValues(option);
                  setOpen(false);
                }}
                value={option}
              >
                <ComboboxOption
                  hasCustomColor={hasCustomColor}
                  option={option}
                />
              </CommandItem>
            ))}
            {create && search && (
              <CommandItem
                className="flex flex-row space-x-1"
                onSelect={() => {
                  addSearchToOptionsAndValues(search);
                  setOpen(false);
                  setSearch("");
                }}
                value={search}
              >
                <span>Create</span>
                <ComboboxOption
                  hasCustomColor={hasCustomColor}
                  option={search}
                />
              </CommandItem>
            )}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
