import { useMutation } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { Button } from "../../components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/ui/form";
import { Input } from "../../components/ui/input";
import { Switch } from "../../components/ui/switch";
import { useToast } from "../../components/ui/use-toast";
import { useGetUIAuthorizedCreations } from "../../containers/user/hooks";
import { CREATE_INSTRUMENT, GET_INSTRUMENTS } from "../../gql/instrument";
import { OrganizationRoutes } from "../organization/organization-routes";
import {
  useGetMyOrganization,
  useIsRemoteRunEnabled,
} from "../settings/organization-settings/hooks";

const newInstrumentFormSchema = z.object({
  canBeTriggeredExternally: z.boolean(),
  name: z.string().trim().min(1),
  serialNumber: z.string().trim().min(1),
});
type NewInstrumentForm = z.infer<typeof newInstrumentFormSchema>;

export default function NewSyntaxInstrument() {
  const organizationData = useGetMyOrganization();
  const { instrument: canCreateInstrument } = useGetUIAuthorizedCreations();
  const hasTriggerFromOutsideOption = useIsRemoteRunEnabled();
  const navigate = useNavigate();
  const { toast } = useToast();
  const form = useForm<NewInstrumentForm>({
    defaultValues: {
      canBeTriggeredExternally: false,
      name: "",
      serialNumber: "",
    },
    resolver: zodResolver(newInstrumentFormSchema),
  });

  const [createInstrument] = useMutation(CREATE_INSTRUMENT);

  function onSubmit(values: NewInstrumentForm) {
    createInstrument({
      onCompleted: (data) => {
        toast({
          description: "Instrument created",
          title: "Success",
          variant: "success",
        });
        navigate(
          OrganizationRoutes.INSTRUMENT.replace(
            ":instrumentId",
            data.createInstrument.id,
          ),
        );
      },
      onError: (error) => {
        toast({
          description: error.message,
          title: "Failed to create instrument",
          variant: "destructive",
        });
      },
      refetchQueries: [
        {
          query: GET_INSTRUMENTS,
          variables: {
            organizationId: organizationData?.id ?? "",
          },
        },
      ],
      variables: { payload: values },
    });
  }

  if (!canCreateInstrument) {
    navigate(OrganizationRoutes.INSTRUMENTS);
  }

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="bg-card space-y-4 rounded-lg border p-4">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="serialNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Serial number</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {hasTriggerFromOutsideOption && (
            <FormField
              control={form.control}
              name="canBeTriggeredExternally"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2">
                  <FormControl className="mt-0">
                    <Switch
                      checked={field.value}
                      className="mt-0"
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <div>
                    <FormLabel>Trigger from outside</FormLabel>
                    <FormDescription>
                      Instrument runs can be triggered from console or the API
                    </FormDescription>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
        <div className="flex flex-row space-x-2">
          <Button type="submit">Submit</Button>
          <Button
            onClick={() => navigate(OrganizationRoutes.INSTRUMENTS)}
            variant={"secondary"}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Form>
  );
}
