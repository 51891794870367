import { useState } from "react";

import Authorizations, {
  AuthorizationsEdit,
} from "../../../../components/logic/authorizations";
import type { OrganizationMemberAuthorizations } from "../constants";

export const AuthorizationsBlock = ({
  authorizations,
  editable = false,
  handleChangeAuthorizations,
  title,
}: {
  authorizations?: OrganizationMemberAuthorizations;
  editable?: boolean;
  handleChangeAuthorizations?: (
    authorizations: OrganizationMemberAuthorizations,
  ) => void;
  title?: string;
}) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className="bg-card space-y-4 rounded-lg border p-4">
      {isEditing ? (
        <AuthorizationsEdit
          defaultAuthorizations={authorizations}
          handleCancel={() => setIsEditing(false)}
          handleSubmit={(values) => {
            handleChangeAuthorizations?.(values);
            setIsEditing(false);
          }}
          title={title}
        />
      ) : (
        <Authorizations
          authorizations={authorizations}
          editable={editable}
          handleEdit={() => setIsEditing(true)}
          title={title}
        />
      )}
    </div>
  );
};
