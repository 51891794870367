import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import type {
  PlateConsumables,
  WorkflowStepTRPC,
} from "./components/plate/types";
import RunReagentsRack from "./run-reagents-rack";

import { StepType } from "../../../../../../backend/src/config/db/types";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { SimpleTable } from "../../../../components/ui/simple-table";

const plateColumnHelper = createColumnHelper<PlateConsumables["plates"][0]>();

const plateColumns = [
  plateColumnHelper.accessor("type", {
    header: "Plate Type",
  }),
  plateColumnHelper.accessor("size", {
    header: "Size",
  }),
  plateColumnHelper.accessor("uniqueNumber", {
    header: "Unique #",
  }),
  plateColumnHelper.accessor("partNumber", {
    header: "Part #",
  }),
  plateColumnHelper.accessor("lotNumber", {
    header: "Lot #",
  }),
  plateColumnHelper.display({
    cell: ({ row: { original } }) => {
      if (original.type === "OLIGO") {
        return "N/A";
      }
      return new Date(original.expirationDate).toLocaleDateString();
    },
    header: "Expiration",
    id: "expirationDate",
  }),
];
const Plates = ({ plates }: { plates: PlateConsumables["plates"] }) => {
  const table = useReactTable({
    columns: plateColumns,
    data: plates,
    getCoreRowModel: getCoreRowModel(),
  });

  return <SimpleTable table={table} />;
};

export default function RunReagents({ step }: { step: WorkflowStepTRPC }) {
  if (step.stepType !== StepType.OligoSynthesis) {
    return null;
  }

  const consumables = step?.plate?.run?.report?.consumables;

  if (!consumables) {
    return null;
  }

  return (
    <Card id="run-reagents">
      <CardHeader>
        <CardTitle>
          <span>Consumables use</span>
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="rounded-lg border p-2">
          <h3 className="font-bold">Plates</h3>
          <Plates plates={consumables.plates} />
        </div>
      </CardContent>
      <RunReagentsRack reagents={consumables.reagents} />
    </Card>
  );
}
