import { useMutation, useSubscription } from "@apollo/client";
import { Heading } from "@radix-ui/themes";
import { Download } from "lucide-react";
import { useState } from "react";
import { v4 } from "uuid";
import { z } from "zod";

import { statusMap } from "./components/status-map";
import { useGetInstrument } from "./useGetInstrument";

import { InstrumentStatus } from "../../__generated__/graphql";
import DateRangePicker from "../../components/logic/date-range-picker";
import { Button } from "../../components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import { useToast } from "../../components/ui/use-toast";
import {
  DOWNLOADS_LOGS,
  SUBSCRIBE_INSTRUMENT_LOGS,
} from "../../gql/instrument";
import { useDownloadFile } from "../../utils/useDownload";

const logsRequestSchema = z.object({
  range: z
    .object({
      end: z.date().optional(),
      start: z.date().optional(),
    })
    .refine(
      (data) => (data.start && data.end ? data.start <= data.end : true),
      {
        message: "Start date must be before end date",
      },
    ),
});

type LogsRequest = z.infer<typeof logsRequestSchema>;

export default function InstrumentTroubleshoot() {
  const [requestId, setRequestId] = useState<string | null>(null);
  const { data: getInstrumentData } = useGetInstrument();
  const { toast } = useToast();
  const downloadFile = useDownloadFile();

  useSubscription(SUBSCRIBE_INSTRUMENT_LOGS, {
    onData: async (data) => {
      const url = data.data.data?.instrumentLogs.url;
      if (!url) {
        return;
      }
      try {
        await downloadFile(url, "logs.zip");
      } catch (error) {
        const message =
          "message" in (error as any) ? (error as any).message : "";
        toast({
          description: message,
          title: "Error when downloading logs",
          variant: "destructive",
        });
      }
    },
    skip: !requestId,
    variables: {
      requestId: requestId ?? "",
    },
  });

  const [downloadLogs, { loading }] = useMutation(DOWNLOADS_LOGS, {
    onError: (error) => {
      toast({
        description: error.message,
        title: "Error",
        variant: "destructive",
      });
    },
  });

  function onSubmit(data: LogsRequest) {
    const instrumentId = getInstrumentData?.getInstrument.id;
    if (!instrumentId) {
      return;
    }
    const tzOffset = new Date().getTimezoneOffset() * 60000;
    const startDate = data.range.start
      ? new Date(data.range.start?.getTime() - tzOffset).toISOString()
      : undefined;
    const endDate = data.range.end
      ? new Date(data.range.end?.getTime() - tzOffset).toISOString()
      : undefined;
    const uniqueRequestId = v4();
    setRequestId(uniqueRequestId);

    downloadLogs({
      variables: {
        endDate,
        id: instrumentId,
        requestId: uniqueRequestId,
        startDate,
      },
    });
  }

  if (!getInstrumentData) {
    return <Heading>Instrument not found</Heading>;
  }
  const instrument = getInstrumentData.getInstrument;
  const isDisconnected = instrument.status === InstrumentStatus.Unavailable;

  return (
    <div className="flex flex-col space-y-8">
      <div className="flex flex-row justify-between">
        <Heading className="space-x-2">
          <span>Instrument</span>
          <span className="italic">{instrument.name}</span>
          <span>troubleshooting</span>
        </Heading>
        <div className="flex flex-row items-center space-x-2">
          <p>{instrument.status}</p>
          {statusMap[instrument.status]}
        </div>
      </div>
      <div className="bg-card space-y-2 rounded-lg border p-4">
        <Heading>Download logs</Heading>
        <DateRangePicker onSubmit={onSubmit}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                aria-label="Download logs"
                disabled={isDisconnected || loading}
                id="download-logs"
                type="submit"
                variant={"secondary"}
              >
                <Download />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Download logs</TooltipContent>
          </Tooltip>
        </DateRangePicker>
      </div>
    </div>
  );
}
