import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { inferProcedureOutput } from "@trpc/server";
import { useNavigate } from "react-router-dom";

import { AuthorizationList } from "../../../../components/logic/authorizations";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { SimpleTable } from "../../../../components/ui/simple-table";
import EllipsedText from "../../../../components/ui/tooltipText";
import type { AppRouter } from "../../../../config/trpc";
import { trpc } from "../../../../config/trpc";
import { OrganizationSettingsRoutes } from "../organization-settings-routes";

type TeamMembership = inferProcedureOutput<
  AppRouter["account"]["organizationUser"]["teamMemberships"]
>[0];

const columnHelper = createColumnHelper<TeamMembership>();

const columns = [
  columnHelper.accessor("team.name", {
    cell: (info) => (
      <EllipsedText maxWidth="200px">{info.getValue()}</EllipsedText>
    ),
    header: "Name",
  }),
  columnHelper.accessor("createdAt", {
    cell: (info) => new Date(info.getValue()).toLocaleString(),
    header: "Date added to team",
  }),
  columnHelper.accessor("team.authorizations", {
    cell: (info) => <AuthorizationList authorizations={info.getValue()} />,
    header: "Authorizations",
  }),
];

export default function TeamMembershipsBlock({
  organizationUserId,
}: {
  organizationUserId: string;
}) {
  const navigate = useNavigate();

  const { data: memberships } =
    trpc.account.organizationUser.teamMemberships.useQuery({
      organizationUserId,
    });

  const table = useReactTable({
    columns,
    data: memberships ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Teams</CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        <SimpleTable
          onRowClick={(row) =>
            navigate(
              OrganizationSettingsRoutes.TEAM.replace(
                ":teamId",
                row.original.team.id,
              ),
            )
          }
          table={table}
        />
      </CardContent>
    </Card>
  );
}
