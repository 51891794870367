import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import PageWithWallPaper from "./components/page-with-wallpaper";
import { RootRoutes } from "./routes";

import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../components/ui/form";
import { Input } from "../components/ui/input";
import { useToast } from "../components/ui/use-toast";
import { trpc } from "../config/trpc";

const forgotPasswordForm = z.object({
  email: z.string().email(),
});

type ForgotPasswordForm = z.infer<typeof forgotPasswordForm>;

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const form = useForm<ForgotPasswordForm>({
    defaultValues: {
      email: "",
    },
    resolver: zodResolver(forgotPasswordForm),
  });

  const { mutate: sendForgotPassword } =
    trpc.account.user.forgotPassword.useMutation({
      onError(error) {
        toast({
          description: `An error occurred while sending the email: ${error.message}. Please try again later.`,
          title: "Error",
          variant: "destructive",
        });
      },
      onSuccess() {
        toast({
          description:
            "If your account exists, an email has been sent to you with instructions on how to reset your password. Don't forget to check your spam box !",
          title: "Email sent",
          variant: "success",
        });
      },
    });

  const handleSendForgotPassword = (values: ForgotPasswordForm) => {
    sendForgotPassword(values.email);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSendForgotPassword)}>
        <PageWithWallPaper>
          <Card>
            <CardHeader>
              <CardTitle>Forgot your password ?</CardTitle>
              <CardDescription>
                Enter your email address below to reset your password.
              </CardDescription>
            </CardHeader>
            <CardContent className="flex flex-col space-y-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="w-1/2">
                    <FormControl>
                      <Input {...field} className="w-[500px]" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit">Email me</Button>
              <Button
                onClick={() => {
                  navigate(RootRoutes.LOGIN);
                }}
                variant={"ghost"}
              >
                Back to login
              </Button>
            </CardContent>
          </Card>
        </PageWithWallPaper>
      </form>
    </Form>
  );
}
