export enum RootRoutes {
  ADMIN = "/admin",
  EMAIL = "/emails/:id",
  EMAILS = "/emails",
  FORGOT_PASSWORD = "/forgot-password",
  ICONS = "/icons",
  LOGIN = "/login",
  MFA = "/mfa-form",
  MFA_RECOVERY = "/mfa-recovery",
  ORGANIZATION = "/organization",
  PICK_ORGANIZATION = "/pick-organization",
  ROOT = "/",
  SCREENING = "/screening",
  SETTINGS = "/settings",
  SET_NEW_PASSWORD = "/set-new-password",
  SET_PASSWORD_SHOULD_RESET = "/set-password",
}
