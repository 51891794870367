import {
  Text,
  Html,
  Font,
  Section,
  Preview,
  Link,
  Heading,
  Img,
  Container,
} from "@react-email/components";
import { Tailwind } from "@react-email/tailwind";

import Signature from "./signature";

export interface InviteUserEmailProps {
  adminEmail: string;
  adminFirstName: string;
  adminLastName: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  rootUrl: string;
}

const InviteUserEmail = ({
  adminEmail,
  adminFirstName,
  adminLastName,
  firstName,
  lastName,
  organizationName,
  rootUrl,
}: InviteUserEmailProps) => (
  <Html>
    <Tailwind
      config={{
        theme: {
          extend: {
            colors: {
              primary: "rgb(255 88 67)",
              secondary: "rgb(5 7 52)",
            },
          },
        },
      }}
    >
      <Font
        fontFamily="Roboto"
        fallbackFontFamily="Verdana"
        webFont={{
          format: "woff2",
          url: "https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2",
        }}
        fontWeight={400}
        fontStyle="normal"
      />
      <Preview>{`Invitation to join ${organizationName}`}</Preview>
      <Container className="p-4">
        <Section className="bg-secondary flex flex-row justify-between p-4">
          <Img
            src={`${rootUrl}/logo_red.png`}
            alt="Logo"
            width="311"
            height="31"
          ></Img>
          <Heading
            className="text-white"
            as="h2"
          >{`Invitation to join ${organizationName} DNA script organization`}</Heading>
        </Section>
        <Section>
          <Text>{`Dear ${firstName} ${lastName}`},</Text>
        </Section>
        <Section>
          <Text>
            {`You have been invited by ${adminFirstName} ${adminLastName} to join
  ${organizationName}! Please click on this `}
            <Link href={rootUrl}>link</Link>
            {` to set up your own account and log into
  the system. If you have any questions or issues with the account setup, please
  contact ${adminFirstName} ${adminLastName} at ${adminEmail} directly.`}
          </Text>
        </Section>
        <Signature />
      </Container>
    </Tailwind>
  </Html>
);

export default InviteUserEmail;
