import { render } from "@react-email/render";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { RootRoutes } from "./routes";

import ForgotPasswordEmail from "../../../backend/src/notifications/emails/templates/forgot-password";
import InviteUserEmail from "../../../backend/src/notifications/emails/templates/invite-user";
import NewUserEmail from "../../../backend/src/notifications/emails/templates/new-user";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { useIsDevelopment } from "../containers/contexts";

const RENDERED_EMAILS = [
  {
    id: "new-account_without_password",
    name: "New account without password",
    page: render(
      <NewUserEmail
        adminEmail="john.smith@dnascript.com"
        adminFirstName="john"
        adminLastName="smith"
        firstName="thomas"
        lastName="dupont"
        password={undefined}
        rootUrl="http://localhost:5173"
        urlPath="/set-new-password?code=nzefofnzefi&userId=user-1"
      />,
      { pretty: true },
    ),
  },
  {
    id: "new-account_with_password",
    name: "New account with password",
    page: render(
      <NewUserEmail
        adminEmail="john.smith@dnascript.com"
        adminFirstName="john"
        adminLastName="smith"
        firstName="thomas"
        lastName="dupont"
        password="password"
        rootUrl="http://localhost:5173"
        urlPath="/"
      />,
      { pretty: true },
    ),
  },
  {
    id: "invitation",
    name: "Invitation to organization",
    page: render(
      <InviteUserEmail
        adminEmail="john.smith@dnascript.com"
        adminFirstName="john"
        adminLastName="smith"
        firstName="thomas"
        lastName="dupont"
        organizationName="Dnascript"
        rootUrl="http://localhost:5173"
      />,
      { pretty: true },
    ),
  },
  {
    id: "forgot-password",
    name: "Forgot password",
    page: render(
      <ForgotPasswordEmail
        firstName="thomas"
        lastName="dupont"
        rootUrl="http://localhost:5173"
        urlPath="/set-new-password?code=nzefofnzefi&userId=user-1"
      />,
      { pretty: true },
    ),
  },
  {
    id: "reset-password",
    name: "Reset password",
    page: "",
  },
] as const;

const DEFAULT_EMAIL_PATH = RootRoutes.EMAILS + "/" + RENDERED_EMAILS[0].id;

export const Emails = () => {
  return <Navigate to={DEFAULT_EMAIL_PATH} />;
};

export default function Email() {
  const { id } = useParams();
  const navigate = useNavigate();
  const isDevelopment = useIsDevelopment();
  if (!isDevelopment) {
    return <Navigate to={RootRoutes.ROOT} />;
  }
  if (!id) {
    navigate(DEFAULT_EMAIL_PATH);
  }

  return (
    <div>
      <Tabs
        className="flex"
        defaultValue="account"
        onValueChange={(newId) =>
          navigate(RootRoutes.EMAIL.replace(":id", newId))
        }
        orientation="vertical"
        value={id}
      >
        <TabsList
          className="grid h-fit grid-rows-2"
          defaultValue={RENDERED_EMAILS[0].id}
        >
          {RENDERED_EMAILS.map((email) => {
            return (
              <TabsTrigger key={email.name} value={email.id}>
                {email.name}
              </TabsTrigger>
            );
          })}
        </TabsList>
        {RENDERED_EMAILS.map((email) => {
          return (
            <TabsContent
              className="relative h-[calc(100vh_-_70px)] overflow-auto p-4"
              key={email.id}
              value={email.id}
            >
              <iframe className="h-[800px] w-[500px]" srcDoc={email.page} />
            </TabsContent>
          );
        })}
      </Tabs>
    </div>
  );
}
