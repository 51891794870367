import { zodResolver } from "@hookform/resolvers/zod";
import type { ColumnDef } from "@tanstack/react-table";
import { getQueryKey } from "@trpc/react-query";
import { UserPlus } from "lucide-react";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { SelectColumn } from "../../../../components/logic/select-column";
import { IsActive } from "../../../../components/table/basic";
import { Badge } from "../../../../components/ui/badge";
import { Button } from "../../../../components/ui/button";
import { DataTable } from "../../../../components/ui/data-table/data-table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/ui/dialog";
import { Form } from "../../../../components/ui/form";
import EllipsedText from "../../../../components/ui/tooltipText";
import { useToast } from "../../../../components/ui/use-toast";
import { queryClient, trpc } from "../../../../config/trpc";
import type { OrganizationMemberInOrganization } from "../hooks";

type AddToTeamMember = Pick<
  OrganizationMemberInOrganization,
  "active" | "email" | "id" | "name"
>;

// eslint-disable-next-line react-refresh/only-export-components
export const addToTeamColumns: ColumnDef<AddToTeamMember>[] = [
  SelectColumn(({ row }) => <Badge>{row.original?.email}</Badge>),
  {
    accessorFn: (row) => row.email,
    cell: ({ row }) => (
      <EllipsedText maxWidth="200px">{row.original.email}</EllipsedText>
    ),
    header: "Email",
    id: "email",
  },
  {
    accessorFn: (row) => row.name,
    cell: ({ row }) => (
      <EllipsedText maxWidth="200px">{row.original.name}</EllipsedText>
    ),
    header: "Name",
    id: "name",
  },
  {
    accessorFn: (row) => row.active,
    cell: ({ row }) => <IsActive isActive={row.original.active} />,
    header: "Active ?",
    id: "isActive",
  },
];

const addUserToTeamForm = z.object({
  users: z.array(
    z.object({
      memberId: z.string(),
    }),
  ),
});

type AddUserToTeamForm = z.infer<typeof addUserToTeamForm>;

export default function AddToTeam({ members }: { members: AddToTeamMember[] }) {
  const [open, setOpen] = useState(false);
  const { teamId } = useParams();
  const { toast } = useToast();

  const form = useForm<AddUserToTeamForm>({
    defaultValues: {
      users: [],
    },
    resolver: zodResolver(addUserToTeamForm),
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: "users",
  });

  const { mutate: addToTeam } = trpc.account.team.addToTeam.useMutation({
    onError(error) {
      toast({
        description: error.message,
        title: "Error",
        variant: "destructive",
      });
      setOpen(false);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(trpc.account.team.get, { id: teamId }),
      });
      setOpen(false);
    },
  });

  function onSubmit(values: AddUserToTeamForm) {
    if (!teamId) {
      return;
    }
    addToTeam({
      organizationMemberIds: values.users.map((x) => x.memberId),
      teamId,
    });
  }

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button>
          <UserPlus />
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[700px]">
        <DialogHeader className="space-y-4">
          <DialogTitle>Add users to team</DialogTitle>
          <DialogDescription>
            <Form {...form}>
              <form
                className="space-y-2"
                onSubmit={form.handleSubmit(onSubmit)}
              >
                <DataTable
                  columns={addToTeamColumns}
                  data={members}
                  onRowClick={(row) => row.getToggleSelectedHandler()({})}
                  onSelectionChange={(users) => {
                    form.setValue(
                      "users",
                      users.map((x) => ({
                        memberId: members[parseInt(x.id)].id,
                      })),
                    );
                  }}
                />
                <div className="flex justify-end">
                  <Button disabled={fields.length === 0} type="submit">
                    Submit
                  </Button>
                </div>
              </form>
            </Form>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
